import * as Highcharts from "highcharts";
import { DataPoint } from "../../../data-connectivity";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ITimeSeriesChartDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { LimitsDto } from "../../models/limits";
import { LimitMarkerHelper, LimitValues } from "../limit.helper";

const INFINITY_TIMESTAMP = 2551441564000;

interface LimitSeriesConfig {
  xValues: number[];
  lower: Maybe<number[]>;
  upper: Maybe<number[]>;
  color: Maybe<string>;
  isLow: boolean;
  name: string;
  showLimitsAsLines: boolean;
}

enum LimitSeriesType {
  Line = "line",
  Area = "area",
  AreaRange = "arearange"
}

export function createLimitSeries(
  connectorPoints: DataPoint[],
  viewConfig: ITimeSeriesChartDisplayConfig
): Highcharts.SeriesOptionsType[] {
  const limitArrays: LimitValues = getLimitArrays(connectorPoints, viewConfig);
  const xValues = connectorPoints.map((p) => new Date(p.x).getTime());
  return createAllLimitAreaOptions(
    limitArrays,
    xValues,
    viewConfig.limits,
    viewConfig.showLimitsAsLines
  );
}

function getLimitArrays(
  connectorPoints: DataPoint[],
  viewConfig: ITimeSeriesChartDisplayConfig
): LimitValues {
  return new LimitMarkerHelper(viewConfig.limits).getLimitArrays(connectorPoints);
}

function createAllLimitAreaOptions(
  limits: LimitValues,
  xValues: number[],
  colors: LimitsDto,
  showLimitsAsLines: boolean
): Highcharts.SeriesOptionsType[] {
  return [
    createLimitOption({
      xValues,
      lower: null,
      upper: limits.extremeLow,
      color: colors.extremeLowColor,
      isLow: true,
      name: "lololo",
      showLimitsAsLines
    }),
    createLimitOption({
      xValues,
      lower: limits.extremeLow,
      upper: limits.veryLow,
      color: colors.veryLowColor,
      isLow: true,
      name: "lolo",
      showLimitsAsLines
    }),
    createLimitOption({
      xValues,
      lower: limits.veryLow || limits.extremeLow,
      upper: limits.low,
      color: colors.lowColor,
      isLow: true,
      name: "lo",
      showLimitsAsLines
    }),

    createLimitOption({
      xValues,
      lower: limits.high,
      upper: limits.veryHigh || limits.extremeHigh,
      color: colors.highColor,
      isLow: false,
      name: "hi",
      showLimitsAsLines
    }),
    createLimitOption({
      xValues,
      lower: limits.veryHigh,
      upper: limits.extremeHigh,
      color: colors.veryHighColor,
      isLow: false,
      name: "hihi",
      showLimitsAsLines
    }),
    createLimitOption({
      xValues,
      lower: limits.extremeHigh,
      upper: null,
      color: colors.extremeHighColor,
      isLow: false,
      name: "hihihi",
      showLimitsAsLines
    }),
    createTargetOption(xValues, limits.target, colors.targetColor)
  ].filter(isDefined);
}

function createTargetOption(
  xValues: number[],
  targetValues: number[],
  color: Maybe<string>
): Maybe<Highcharts.SeriesOptionsType> {
  if (targetValues == null) {
    return null;
  }
  const data = xValues.map((t, index) => [t, targetValues[index]]);
  extendXToInfinity(data);
  const lineOptions: Highcharts.SeriesLineOptions = {
    type: LimitSeriesType.Line,
    name: "target",
    data: data,
    color: color ?? undefined,
    showInLegend: false,
    enableMouseTracking: false,
    lineWidth: 1,
    marker: { enabled: false },
    zIndex: -1
  };
  return lineOptions;
}

function createLimitOption(
  seriesLimitConfig: LimitSeriesConfig
): Maybe<Highcharts.SeriesOptionsType> {
  if (seriesLimitConfig.lower == null && seriesLimitConfig.upper == null) {
    return null;
  }
  makeLabelsVisible(seriesLimitConfig.xValues, seriesLimitConfig.lower, seriesLimitConfig.upper);
  if (seriesLimitConfig.lower != null && seriesLimitConfig.upper != null) {
    const data = seriesLimitConfig.xValues.map((t, index) => [
      t,
      seriesLimitConfig.lower[index],
      seriesLimitConfig.upper[index]
    ]);
    extendXToInfinity(data);
    return createLimitSeriesOption(
      seriesLimitConfig.showLimitsAsLines ? LimitSeriesType.Line : LimitSeriesType.AreaRange,
      seriesLimitConfig.name,
      data,
      seriesLimitConfig.color
    );
  }
  if (
    (seriesLimitConfig.isLow && seriesLimitConfig.upper != null) ||
    (!seriesLimitConfig.isLow && seriesLimitConfig.lower != null)
  ) {
    const yValues = (seriesLimitConfig.lower || seriesLimitConfig.upper) as number[];
    const data = seriesLimitConfig.xValues.map((t, index) => [t, yValues[index]]);
    extendXToInfinity(data);
    const areaOptions = createLimitSeriesOption(
      seriesLimitConfig.showLimitsAsLines ? LimitSeriesType.Line : LimitSeriesType.Area,
      seriesLimitConfig.name,
      data,
      seriesLimitConfig.color
    ) as Highcharts.SeriesAreaOptions;
    areaOptions.threshold = seriesLimitConfig.isLow ? -Infinity : Infinity;
    return areaOptions;
  }
  return null;
}

function makeLabelsVisible(
  xValues: number[],
  lower: Maybe<number[]>,
  upper: Maybe<number[]>
): void {
  if (xValues?.length === 1) {
    xValues.push(xValues[0] + 1);
  }
  if (lower?.length === 1) {
    lower.push(lower[0]);
  }
  if (upper?.length === 1) {
    upper.push(upper[0]);
  }
}

function extendXToInfinity(data: number[][]): void {
  data.unshift([0, ...data[0].slice(1)]);
  data.push([INFINITY_TIMESTAMP, ...data[data.length - 1].slice(1)]);
}

function createLimitSeriesOption(
  type: string,
  name: string,
  data: unknown[],
  color: Maybe<string>
): Highcharts.SeriesOptionsType {
  //Limit value is visible on primary y axis
  return {
    type: type,
    name: name,
    data: data,
    color: color,
    fillOpacity: 1,
    showInLegend: false,
    enableMouseTracking: false,
    lineWidth: type === LimitSeriesType.Line ? 2 : 0,
    marker: { enabled: false },
    zIndex: -1
  } as Highcharts.SeriesOptionsType;
}
