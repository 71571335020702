import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { v4 as uuid } from "uuid";
import { KeyValue } from "../../../../core/models/key-value";
import { ViewMode } from "../../../../core/models/view-mode";
import { getConnectorViewId } from "../../../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorDto } from "../../../../data-connectivity/models/data-connector";
import { Dispatcher } from "../../../../dispatcher";
import { DateFormatterService } from "../../../../environment/services/date-formatter.service";
import { EnvironmentSelector } from "../../../../environment/services/environment.selector";
import { createUpdatedComponentsInfo } from "../../../../meta/helpers/updated-entities-info.helper";
import { EntityId } from "../../../../meta/models/entity";
import { UndoRedoService } from "../../../../shared/services/undo-redo.service";
import { Dictionary, isEmpty, isEmptyOrNotDefined, toDictionary } from "../../../../ts-utils";
import { isDefined } from "../../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { getTextColorForNoDataStatus } from "../../../helpers/color.helper";
import {
  areConnectorsChanged,
  checkForDeletedDataConnector,
  checkForFooterUpdate,
  convertToFooterRowIds,
  convertToHeaderOrFooterRowId,
  createPseudoConnector,
  getUpdatedInlineColumns,
  isPseudoConnectorInitiallyCreated,
  isViewChanged,
  shouldRecreateRowsAndColumns
} from "../../../helpers/column.helper";
import {
  getMaxNumberOfRequestedDataPoints,
  getPseudoConnectorId,
  isPseudoConnector
} from "../../../helpers/connectors.helper";
import {
  FooterRow,
  HeaderRow,
  TableColumnConfig,
  TableFooterDescriptor,
  TableRow
} from "../../../models";
import { DataStatus } from "../../../models/data-status";
import { DataConnectorDescriptor } from "../../../models/store/data-connector-descriptor";
import { TableHeaderDescriptor } from "../../../models/table/table-header-descriptor";
import { DataConnectorViewSelector } from "../../../services/entity-selectors/data-connector-view.selector";
import { InlineEditService } from "../../../services/inline-edit.service";
import { PropertyInterpolationService } from "../../../services/property-interpolation.service";
import { DataConnectorViewActions } from "../../../store/data-connector-view/data-connector-view.actions";
import { DataConnectorActions } from "../../../store/data-connector/data-connector.actions";
import { COMMON_CHART_WIDTH } from "../../base/common-view-config-defaults";
import { TimeSeriesViewConfig } from "../../time-series/view-config";
import {
  areAllTimeSeries,
  ConnectorPerColumnStrategy,
  LABEL_COLUMN,
  X_COLUMN_ID
} from "./table-rendering-strategies/connector-per-column-strategy";
import { NullStrategy } from "./table-rendering-strategies/null-strategy";
import { TableRenderingStrategy } from "./table-rendering-strategies/table-rendering-strategy";
import { TimeTableStrategy } from "./table-rendering-strategies/time-table-strategy";

const DEFAULT_HEADER_COLOR = "#00000000";

@Component({
  selector: "table-for-connectors",
  templateUrl: "./table-for-connectors.component.html",
  styleUrls: ["./table-for-connectors.component.scss"],
  host: { class: "simple-component" },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableForConnectorsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() dataConnectors: Maybe<DataConnectorDescriptor[]> = null;
  @Input() viewConfig: Maybe<TimeSeriesViewConfig> = null;
  @Input() dataStatus: DataStatus = DataStatus.NoDataDefined;
  @Input() tableComponentId: EntityId;
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output() loadMoreData: EventEmitter<any> = new EventEmitter();

  public tableRows: MatTableDataSource<TableRow[]> = new MatTableDataSource<TableRow[]>([]);
  public tableColumns: TableColumnConfig[] = [];
  public tableColumnIds: string[] = [];
  public DataStatus = DataStatus;
  public viewMode: ViewMode = ViewMode.EditMode;
  public defaultHeaderColor: string = DEFAULT_HEADER_COLOR;
  public changedColumnsFromInlineOrPreviewMode: TableColumnConfig[] = [];
  private unsubscribeSubject$: Subject<void> = new Subject();
  public isPseudoConnAlreadyCreated: boolean = false;
  maxNumberOfRequestedDataPoints: number = 0;
  footerRowDict: Dictionary<FooterRow> = {};
  headerRowDict: Dictionary<HeaderRow> = {};

  constructor(
    private dateFormatter: DateFormatterService,
    public dispatcher: Dispatcher,
    private environmentSelector: EnvironmentSelector,
    private connectorViewSelector: DataConnectorViewSelector,
    private inlineEditService: InlineEditService,
    private undoRedoService: UndoRedoService,
    private cdr: ChangeDetectorRef,
    private propertyInterpolationService: PropertyInterpolationService
  ) {}

  ngOnInit(): void {
    this.subscribeToViewMode();
  }

  ngAfterViewInit(): void {
    if (isDefined(this.viewConfig) && isDefined(this.dataConnectors)) {
      this.isPseudoConnAlreadyCreated = isPseudoConnectorInitiallyCreated(
        this.connectorViewSelector,
        this.tableComponentId,
        this.dataConnectors,
        this.dispatcher
      );
      this.updateTable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isViewChanged(changes)) {
      if (checkForFooterUpdate(changes)) {
        this.setFooter();
      }
      this.updateTable();
    }

    if (areConnectorsChanged(changes) && isDefined(this.dataConnectors)) {
      this.checkForConnectorsChanges(
        changes.dataConnectors.currentValue,
        changes.dataConnectors.previousValue
      );
      this.applyPreviewChanges();
      this.maxNumberOfRequestedDataPoints = getMaxNumberOfRequestedDataPoints(this.dataConnectors);
    }
  }

  private subscribeToViewMode(): void {
    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        this.viewMode = viewMode;
        if (
          this.viewMode === ViewMode.EditMode &&
          !isEmpty(this.changedColumnsFromInlineOrPreviewMode)
        ) {
          this.updateTable();
          this.changedColumnsFromInlineOrPreviewMode = [];
        }
      });
  }

  private addOrRemoveExtraColumn(columns: TableColumnConfig[] = this.tableColumns): void {
    if (isDefined(this.viewConfig) && this.viewConfig.enableHeaderAndFooterLabels) {
      const extraColumn: TableColumnConfig = {
        id: LABEL_COLUMN,
        cellConfig: {}
      };
      columns.unshift(extraColumn);
    } else if (isDefined(columns.find((column) => column.id === LABEL_COLUMN))) {
      columns.shift();
    }
  }

  private setFooter(): void {
    const selectedFooters =
      this.viewConfig?.footerDescriptors.filter(
        (footerDescriptor) => footerDescriptor.isSelected
      ) ?? [];
    this.footerRowDict = toDictionary(
      selectedFooters,
      (footerDes) => footerDes.id,
      (footerDes) => this.createFooterRow(footerDes)
    );
  }

  private createFooterRow(footerDescriptor: TableFooterDescriptor): FooterRow {
    const key = uuid();
    return {
      function: footerDescriptor.function,
      label: footerDescriptor.label,
      columnIds: convertToFooterRowIds(this.tableColumnIds, key)
    };
  }

  private setHeader(cols: TableColumnConfig[]): void {
    const selectedHeaders =
      this.viewConfig?.headerDescriptors.filter(
        (headerDescriptor) => headerDescriptor.isSelected
      ) ?? [];
    this.headerRowDict = toDictionary(
      selectedHeaders,
      (header) => header.id,
      (header) => this.createHeaderRow(header, cols)
    );
  }

  private createHeaderRow(
    headerDescriptor: TableHeaderDescriptor,
    columns: TableColumnConfig[]
  ): HeaderRow {
    const key = uuid();
    const columnIdAndTitlePairs = columns.reduce(
      (acc: KeyValue<string, string>[], column: TableColumnConfig) => {
        let columnId = column.id;
        if (columnId === X_COLUMN_ID) {
          columnId = getConnectorViewId(getPseudoConnectorId(this.tableComponentId));
        }
        const connectorDesc = this.dataConnectors?.find(
          (connectorDescriptor) => connectorDescriptor.connectorView?.id === columnId
        );

        let columnIdWithTitle: Maybe<KeyValue<string, string>> = null;
        if (isDefined(connectorDesc)) {
          const evaluatedValue: string =
            this.propertyInterpolationService.prepareAndProcessInterpolation(
              headerDescriptor.template,
              this.tableComponentId,
              isDefined(connectorDesc) ? [connectorDesc] : []
            );

          columnIdWithTitle = {
            key: convertToHeaderOrFooterRowId(key, column.id),
            value: evaluatedValue
          };
        }
        if (columnId === LABEL_COLUMN) {
          columnIdWithTitle = {
            key: convertToHeaderOrFooterRowId(key, LABEL_COLUMN),
            value: headerDescriptor.label
          };
        }

        if (isDefined(columnIdWithTitle)) {
          acc.push(columnIdWithTitle);
        }
        return acc;
      },
      []
    );

    return {
      id: headerDescriptor.id,
      label: headerDescriptor.label,
      columnIdAndTitlePairs
    };
  }

  private updateTable(): void {
    if (shouldRecreateRowsAndColumns(this.dataConnectors, this.viewConfig)) {
      const strategy = this.getRenderingStrategy(this.dataConnectors);
      if (this.dataStatus !== this.DataStatus.NoDataDefined) {
        const cols = strategy.createCols(
          this.viewConfig,
          this.dataConnectors,
          this.tableComponentId,
          this.dispatcher
        );

        if (isDefined(cols)) {
          this.addOrRemoveExtraColumn(cols);
          this.updateTableColumns(cols);
          this.setHeader(cols);
        }
      }
      const rows = strategy.createRows(this.viewConfig, this.dataConnectors);
      this.updateTableRows(rows);
    }
  }

  private getRenderingStrategy(
    connectors: Maybe<DataConnectorDescriptor[]>
  ): TableRenderingStrategy {
    if (isDefined(connectors) && !isEmpty(connectors)) {
      if (connectors.every((connectorDesc) => connectorDesc.connector.isTimeSeries)) {
        return new TimeTableStrategy(this.dateFormatter);
      } else {
        return new ConnectorPerColumnStrategy(this.dateFormatter);
      }
    } else {
      return new NullStrategy();
    }
  }

  private updateTableColumns(newColumns: TableColumnConfig[]): void {
    this.tableColumns = [...newColumns];
    this.tableColumnIds = getColumnIds(newColumns);
    this.setFooter();
  }

  private updateTableRows(newRows: any[]): void {
    this.tableRows.data = newRows;
  }

  private checkForConnectorsChanges(
    newDataConnectorDescriptors: DataConnectorDescriptor[],
    oldDataConnectorDescriptors: Maybe<DataConnectorDescriptor[]>
  ): void {
    if (isDefined(oldDataConnectorDescriptors)) {
      if (this.shouldAddTimestampColumn(newDataConnectorDescriptors, oldDataConnectorDescriptors)) {
        createPseudoConnector(this.dispatcher, this.tableComponentId);
        this.isPseudoConnAlreadyCreated = true;
      } else if (
        checkForDeletedDataConnector(oldDataConnectorDescriptors, newDataConnectorDescriptors) &&
        this.shouldRemoveTimestampColumn()
      ) {
        const pseudoConnector: Maybe<DataConnectorDto> = oldDataConnectorDescriptors.find(
          (descriptor) => isPseudoConnector(descriptor?.connector.id)
        )?.connector;
        if (isDefined(pseudoConnector)) {
          this.dispatcher.dispatch(
            DataConnectorActions.deleteOne({
              componentId: this.tableComponentId,
              connector: pseudoConnector
            })
          );
          this.isPseudoConnAlreadyCreated = false;
        }
      }
      this.updateTable();
    }
    if (isEmptyOrNotDefined(this.dataConnectors) && this.isPseudoConnAlreadyCreated) {
      this.isPseudoConnAlreadyCreated = false;
    }
  }

  private shouldAddTimestampColumn(
    newDataConnectorDescriptors: DataConnectorDescriptor[],
    oldDataConnectorDescriptors: DataConnectorDescriptor[]
  ): boolean {
    const pseudoConnectorAlreadyExists: boolean = this.pseudoConnectorExists(
      newDataConnectorDescriptors.concat(oldDataConnectorDescriptors)
    );
    return (
      !this.isPseudoConnAlreadyCreated &&
      !pseudoConnectorAlreadyExists &&
      !isEmptyOrNotDefined(this.dataConnectors) &&
      areAllTimeSeries(this.dataConnectors)
    );
  }

  private pseudoConnectorExists(dataConnectorDescriptors: DataConnectorDescriptor[]): boolean {
    return dataConnectorDescriptors.some((connectorDescriptor: DataConnectorDescriptor) =>
      isPseudoConnector(connectorDescriptor.connector.id)
    );
  }

  private shouldRemoveTimestampColumn(): boolean {
    return (
      this.isPseudoConnAlreadyCreated &&
      isDefined(this.dataConnectors) &&
      (this.dataConnectors.length === 1 || !areAllTimeSeries(this.dataConnectors))
    );
  }

  public applyPreviewChanges(): void {
    if (
      this.viewMode === ViewMode.PreviewMode &&
      !isEmpty(this.changedColumnsFromInlineOrPreviewMode)
    ) {
      this.updateTableColumns(this.changedColumnsFromInlineOrPreviewMode);
      this.setHeader(this.changedColumnsFromInlineOrPreviewMode);
    }
  }

  public sortDirectionChange(): void {
    const sort: Maybe<MatSort> = this.tableRows.sort;
    if (isDefined(sort)) {
      const data: TableRow[][] = this.tableRows.sortData(this.tableRows.data, sort);
      this.updateTableRows(data);
    }
  }

  public storePreviewAndInlineChanges(columns: TableColumnConfig[]): void {
    this.changedColumnsFromInlineOrPreviewMode = columns;

    if (isDefined(this.inlineEditService.componentInlineInfo)) {
      this.undoRedoService.createSnapshot({
        updatedEntitiesInfo: createUpdatedComponentsInfo([
          this.inlineEditService.componentInlineInfo.id
        ])
      });
      this.applyInlineChangesToConnectors(columns);
      this.cdr.detectChanges();
    }
    this.setFooter();
    this.setHeader(columns);
  }

  public applyInlineChangesToConnectors(columns: TableColumnConfig[]): void {
    const connectorViewUpdates = getUpdatedInlineColumns(columns, this.tableComponentId);
    if (!isEmpty(connectorViewUpdates)) {
      this.dispatcher.dispatch(DataConnectorViewActions.updateMany({ connectorViewUpdates }));
    }
  }

  public shouldRenderTable(): boolean {
    return !(
      this.dataStatus === DataStatus.NoDataReceived ||
      this.dataStatus === DataStatus.NoDataDefined ||
      this.dataStatus === DataStatus.RequestFailed
    );
  }

  public get noDataColor(): string {
    return getTextColorForNoDataStatus(this.dataStatus, this.viewConfig?.foregroundColor);
  }
}

function getColumnIds(columns: TableColumnConfig[]): string[] {
  return columns.map((column: TableColumnConfig) => column.id);
}

export function calculateNumberOfPointsForTable(widthInPx: number): number {
  const DEFAULT_TABLE_WIDTH = COMMON_CHART_WIDTH;
  const DEFAULT_TABLE_NUM_OF_DATA_POINTS = 500;
  const dataPointsPerPixel: number = Number(
    (DEFAULT_TABLE_NUM_OF_DATA_POINTS / DEFAULT_TABLE_WIDTH).toFixed(2)
  );
  return Math.round(dataPointsPerPixel * widthInPx);
}
