import { EntityId } from "../../meta";
import { isDefined } from "../../ts-utils";
import { Maybe } from "../../ts-utils/models/maybe.type";

export class ContainerTimestampService {
  private childComponentsLatestTimestamps = new Map<EntityId, Date>();

  setTimestampForComponent(componentId: EntityId, newTimestamp?: Maybe<Date>) {
    if (isDefined(newTimestamp)) {
      this.addTimestampForComponent(componentId, newTimestamp);
    } else {
      this.removeTimestampForComponent(componentId);
    }
  }

  getLatestTimestamp(): Date | undefined {
    return this.findLatestDate(this.childComponentsLatestTimestamps);
  }

  getTimestampForComponent(componentId: EntityId): Date | undefined {
    return this.childComponentsLatestTimestamps.get(componentId);
  }

  removeTimestampForComponent(componentId: EntityId) {
    this.childComponentsLatestTimestamps.delete(componentId);
  }

  private addTimestampForComponent(componentId: EntityId, newTimestamp: Date) {
    const clonedTimestampForChild = new Date(newTimestamp);
    this.childComponentsLatestTimestamps.set(componentId, clonedTimestampForChild);
  }

  private findLatestDate(map: Map<EntityId, Date>): Date | undefined {
    let latestTimestamp: Date = undefined;
    map.forEach((componentTimestamp) => {
      if (componentTimestamp && (!latestTimestamp || componentTimestamp > latestTimestamp)) {
        latestTimestamp = componentTimestamp;
      }
    });
    return latestTimestamp;
  }
}
