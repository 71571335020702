import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { ColorAxisOptions, SeriesHeatmapOptions } from "highcharts";
import moment from "moment";
import { TimeRange } from "../../../core/models/time-range";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { IPTypeCheckService } from "../../../core/services/i-ptype-check.service";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { NumberOfDataPointsToRequest } from "../../../meta/decorators/number-of-data-points-to-request.decorator";
import { getEntityTitle } from "../../../meta/helpers/get-title.helper";
import { ComponentCategory } from "../../../meta/models/component-category";
import { isDate } from "../../../ts-utils";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { HEATMAP_CHART } from "../../models/help-constants";
import { LimitsDto } from "../../models/limits";
import { RuntimeSettingsSelector } from "../../services/entity-selectors/runtime-settings.selector";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID
} from "../../services/highcharts/base-highcharts-options.helper";
import { LimitMarkerHelper } from "../../services/limit.helper";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ChartComponent } from "../chart/chart.component";
import { Roles } from "./roles";
import { DisplayStrategies, HeatmapChartViewConfig } from "./view-config";

const SECOND_IN_MS = 1000;
const MS_IN_MINUTE = SECOND_IN_MS * 60;
const MS_IN_HOUR = MS_IN_MINUTE * 60;
const MS_IN_DAY = MS_IN_HOUR * 24;
const SHIFT_DURATION_HOURS = 8;

@Component({
  selector: "c-heatmap-chart",
  templateUrl: "./heatmap-chart.component.html",
  styleUrls: ["./heatmap-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: HeatmapChartComponent }]
})
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  "HeatmapChartComponent",
  "Matrix",
  "abb-icon ",
  LOCALIZATION_DICTIONARY.layoutEditor.HeatmapChart,
  null,
  HEATMAP_CHART
)
@ConnectorRoles(Roles)
@NumberOfDataPointsToRequest(() => 20000)
@MaxConnectors(20)
@EditableWidget({ fullName: "HeatmapChartComponent", title: "heatmap-chart" })
export class HeatmapChartComponent extends ChartComponent {
  interpolatedViewConfig!: HeatmapChartViewConfig;
  filterTimeRange: Maybe<TimeRange>;
  periodType: string = "";
  constructor(
    private filterFactory: FilterFactory,
    params: ComponentConstructorParams,
    hostElementRef: ElementRef<any>,
    protected cdr: ChangeDetectorRef,
    protected dateFormatter: DateFormatterService,
    public runtimeSettingsSelector: RuntimeSettingsSelector,
    private pTypeService: IPTypeCheckService,
    protected valueFormatter: ValueFormatterService
  ) {
    super(params, hostElementRef, cdr);
  }

  @View(HeatmapChartViewConfig)
  public get view(): HeatmapChartViewConfig {
    return this.currentState.view as HeatmapChartViewConfig;
  }

  private isLargeHeatMap(): boolean {
    return this.interpolatedViewConfig.displayStrategies === DisplayStrategies.LargeHeatMap;
  }

  protected updateChartData(): void {
    const evaluated = this.dynamicDefaultsEvaluator.collectAndEvaluate(
      this.view,
      this.dataAccessor
    );
    const interpolatedProperties =
      this.propertyInterpolationService.collectInterpolatedProperties<HeatmapChartViewConfig>(
        { ...this.currentState, view: evaluated.viewConfig },
        evaluated.connectorDescriptors
      );
    this.interpolatedViewConfig = interpolatedProperties.viewConfig;
    const dataConnectors: DataConnectorDto[] = interpolatedProperties.connectorDescriptors
      .map((descriptor) => descriptor.connector)
      .filter((connector) => isDefined(connector) && isDefined(connector.dataPoints));

    if (isNotDefined(this.filter)) {
      return;
    }
    this.filterTimeRange = this.filterFactory.createTimeRangeFromFilterConfig(this.filter);
    if (isDefined(this.filterTimeRange)) {
      this.resetChartData();
      this.periodType = this.resolvePeriodType();
      this.setAxisProperties(dataConnectors);
      this.addSeries(dataConnectors);
      this.addColorAxes(dataConnectors);
    }
  }

  private setAxisProperties(connectors: DataConnectorDto[]): void {
    const xAxis = this.chartObject?.xAxis[0];
    const yAxis = this.chartObject?.yAxis[0];
    if (isDefined(xAxis) && isDefined(yAxis)) {
      const { yAxisTitle, xAxisTitle, xAxisTickPositions, xAxisMinTickInterval } = getAxisConfig(
        this.periodType,
        this.pTypeService
      );
      const { rowSize, tableRowSize } = getSeriesConfig(this.periodType, this.pTypeService);
      if (this.isLargeHeatMap()) {
        xAxis.setTitle({
          text: !isEmpty(this.interpolatedViewConfig.xAxisTitle)
            ? this.interpolatedViewConfig.xAxisTitle
            : xAxisTitle
        });
        yAxis.setTitle({
          text: !isEmpty(this.interpolatedViewConfig.yAxisTitle)
            ? this.interpolatedViewConfig.yAxisTitle
            : yAxisTitle
        });
        xAxis.userOptions.minTickInterval = xAxisMinTickInterval;
        yAxis.userOptions.minTickInterval = rowSize;
        xAxis.userOptions.tickPositions = xAxisTickPositions;
        xAxis.update({ categories: undefined });
      } else {
        xAxis.setTitle({ text: this.interpolatedViewConfig.xAxisTitle });
        yAxis.setTitle({ text: this.interpolatedViewConfig.yAxisTitle });
        xAxis.userOptions.minTickInterval = undefined;
        yAxis.userOptions.minTickInterval = tableRowSize;
        xAxis.userOptions.tickPositions = undefined;
        xAxis.setCategories(getConnectorCategories(connectors));
      }
    }
  }

  private addSeries(dataConnectors: DataConnectorDto[]): void {
    const { columnSize, rowSize, tableRowSize } = getSeriesConfig(
      this.periodType,
      this.pTypeService
    );
    dataConnectors.forEach((connector, index) => {
      const dataPoints = this.getConnectorDataPoints(connector, index);
      const options: SeriesHeatmapOptions = {
        type: "heatmap",
        name: connector.title,
        colorAxis: dataConnectors.indexOf(connector),
        keys: ["x", "y", "value", "customValue"],
        data: dataPoints,
        colsize: this.isLargeHeatMap() ? columnSize : undefined,
        rowsize: this.isLargeHeatMap() ? rowSize : tableRowSize,
        borderWidth: 0
      };
      this.chartObject?.addSeries(options, false, false);
    });
  }

  private getConnectorDataPoints(connector: DataConnectorDto, connectorIndex: number): number[][] {
    const periodType = this.periodType;
    if (isNotDefined(connector.dataPoints)) {
      return [];
    }
    return connector.dataPoints.map((dataPoint) => {
      const timestamp = dataPoint.x as Date;
      if (!isDate(timestamp)) {
        return [];
      }
      let x: number = 0;
      let y: number = 0;
      if (this.pTypeService.isYear(periodType)) {
        x = timestamp.getFullYear();
        y = 0;
      } else if (this.pTypeService.isMonth(periodType)) {
        x = timestamp.getMonth() + 1;
        y = moment(timestamp).startOf("year").valueOf();
      } else if (this.pTypeService.isWeek(periodType)) {
        x = getWeekNumberOfMonth(timestamp);
        y = moment(timestamp).startOf("month").valueOf();
      } else if (this.pTypeService.isDay(periodType)) {
        x = timestamp.getDate();
        y = moment(timestamp).startOf("month").valueOf();
      } else if (this.pTypeService.isHour(periodType) || this.pTypeService.isShift(periodType)) {
        x = timestamp.getHours();
        y = moment(timestamp).startOf("day").valueOf();
      } else if (
        this.pTypeService.is30Minute(periodType) ||
        this.pTypeService.is15Minute(periodType)
      ) {
        x = timestamp.getHours() + timestamp.getMinutes() / 60;
        y = moment(timestamp).startOf("day").valueOf();
      } else if (this.pTypeService.isMinute(periodType)) {
        x = timestamp.getMinutes();
        y = moment(timestamp).startOf("hour").valueOf();
      } else if (this.pTypeService.isPri(periodType)) {
        x = timestamp.getSeconds();
        y = moment(timestamp).startOf("minute").valueOf();
      }
      const dataPointValue = dataPoint.evaluatedValue ?? dataPoint.y;
      if (this.isLargeHeatMap()) {
        return [x, y, dataPointValue, timestamp];
      }
      return [connectorIndex, moment(timestamp).valueOf(), dataPointValue, timestamp];
    });
  }

  private addColorAxes(dataConnectors: DataConnectorDto[]): void {
    dataConnectors.forEach((connector) => {
      const limitDto = this.extractLimits(connector, this.interpolatedViewConfig.limits);
      this.chartObject?.addColorAxis(this.generateColorAxisOptions(limitDto));
    });
  }

  private extractLimits(connector: DataConnectorDto, limits: LimitsDto): LimitsDto {
    const limitHelper = new LimitMarkerHelper(limits);
    const connectorLimits = limitHelper.getLimitArrays(connector.dataPoints);
    return new LimitsDto({
      ...limits,
      extremeLow: connectorLimits.extremeLow?.[0] ?? 0,
      veryLow: connectorLimits.veryLow?.[0] ?? 0,
      low: connectorLimits.low?.[0] ?? 0,
      high: connectorLimits.high?.[0] ?? 0,
      veryHigh: connectorLimits.veryHigh?.[0] ?? 0,
      extremeHigh: connectorLimits.extremeHigh?.[0] ?? 0
    });
  }

  private generateColorAxisOptions(limitsDto: LimitsDto): ColorAxisOptions {
    const stops = this.generateStops(limitsDto);
    const min = Math.min(
      limitsDto.extremeLow ?? 0,
      limitsDto.veryLow ?? 0,
      limitsDto.low ?? 0,
      limitsDto.high ?? 0,
      limitsDto.veryHigh ?? 0,
      limitsDto.extremeHigh ?? 0
    );
    const max = Math.max(
      limitsDto.extremeLow ?? 0,
      limitsDto.veryLow ?? 0,
      limitsDto.low ?? 0,
      limitsDto.high ?? 0,
      limitsDto.veryHigh ?? 0,
      limitsDto.extremeHigh ?? 0
    );

    return {
      min,
      max,
      stops,
      startOnTick: false,
      endOnTick: false,
      labels: {
        format: `{value}`
      },
      layout: "horizontal"
    };
  }

  private generateStops(limits: LimitsDto): [number, string][] {
    let targetColor = limits.targetColor ?? "#0AAEFF";
    if (targetColor === "#000000") {
      targetColor = "#0AAEFF";
    }
    const lowStops = getStopsByRegex(/low$/i, limits);
    const highStops = getStopsByRegex(/high$/i, limits);
    const allStopsAreZero =
      lowStops.every(([value]) => value === 0) && highStops.every(([value]) => value === 0);

    if (allStopsAreZero) {
      return [
        [0, targetColor],
        [1, targetColor]
      ];
    }
    const lastLowStopValue = lowStops[lowStops.length - 1]?.[0] ?? 0;
    const firstHighStopValue = highStops[0]?.[0] ?? 0;
    const targetValue = Math.round(((lastLowStopValue + firstHighStopValue) / 2) * 100) / 100;

    return [...lowStops, [targetValue, targetColor], ...highStops];
  }

  protected setChartOptions(): void {
    const component = this;
    const options: Highcharts.Options = {
      time: { useUTC: false },
      boost: {
        enabled: true
      },
      chart: {
        type: "heatmap",
        zooming: { type: "xy" },
        inverted: component.view.swapXY,
        animation: false
      },
      title: {
        text: this.interpolatedViewConfig.title ?? ""
      },
      plotOptions: {
        series: {
          boostThreshold: 2,
          showInLegend: true,
          animation: false,
          shadow: false,
          dataGrouping: { enabled: false }
        }
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          type: "datetime",
          tickWidth: 1,
          allowDecimals: false,
          endOnTick: false,
          min: isDefined(this.filterTimeRange?.from) ? this.filterTimeRange?.from.valueOf() : null,
          max: isDefined(this.filterTimeRange?.to) ? this.filterTimeRange?.to.valueOf() : null
        }
      ],
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          labels: {
            formatter: function () {
              return component.valueFormatter.formatValue(this.value, component.view.displayFormat);
            }
          },
          reversed: false
        }
      ],
      tooltip: {
        formatter: function () {
          const dataPointTimestamp = (this.point as any).customValue as Date;
          const x = moment(dataPointTimestamp).local().toDate();

          return (
            "<b>" +
            component.valueFormatter.formatValue(this.point.value, component.view.displayFormat) +
            "</b>" +
            "<br>" +
            "(" +
            component.valueFormatter.formatValue(x, component.view.displayFormat) +
            ")"
          );
        }
      },
      legend: {
        width: "100%",
        layout: "vertical"
      }
    };
    this.mergeChartOptions(options);
  }

  private resetChartData(): void {
    if (isDefined(this.chartObject)) {
      this.chartObject.update(
        {
          series: [],
          colorAxis: []
        },
        true,
        true,
        false
      );
    }
  }

  private resolvePeriodType(): string {
    return this.currentState.dataConnectorQuery.aggregationConfig.periodType === ""
      ? this.runtimeSettingsSelector.getPeriodType()
      : this.currentState.dataConnectorQuery.aggregationConfig.periodType;
  }
}

function getStopsByRegex(regex: RegExp, limits: LimitsDto): [number, string][] {
  return Object.entries(limits)
    .filter(([key]) => regex.test(key))
    .map(([key, value]) => {
      const normalizedValue = normalizeLimitValue(value as number, limits);
      return [normalizedValue, limits[key + "Color"] as string];
    });
}

function normalizeLimitValue(value: number, limits: LimitsDto): number {
  if (limits.extremeHigh === 0) {
    return 0;
  }
  const extremeLow = limits.extremeLow ?? 0;
  const extremeHigh = limits.extremeHigh ?? 1;
  return Math.round(((value - extremeLow) / (extremeHigh - extremeLow)) * 100) / 100;
}

function getWeekNumberOfMonth(date: Date): number {
  const firstDayOfMonth = moment(date).startOf("month");
  const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");
  const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");
  let weekNumber = Math.ceil((moment(date).date() + offset) / 7);
  if (firstDayOfMonth.day() > 3) {
    weekNumber--;
  }
  return weekNumber;
}

function getConnectorCategories(connectors: DataConnectorDto[]): string[] {
  return connectors.map((connector) => {
    const title = getEntityTitle(connector);
    return isDefined(connector.properties.unit) ? `${title} (${connector.properties.unit})` : title;
  });
}

function getSeriesConfig(periodType: string, pTypeService: IPTypeCheckService) {
  let columnSize;
  let rowSize;
  let tableRowSize;
  if (pTypeService.isYear(periodType)) {
    tableRowSize = 366 * MS_IN_DAY;
    columnSize = 1;
    rowSize = Number.MAX_SAFE_INTEGER * 366 * MS_IN_DAY;
  } else if (pTypeService.isMonth(periodType)) {
    tableRowSize = MS_IN_DAY * 31;
    columnSize = 1;
    rowSize = MS_IN_DAY * 366;
  } else if (pTypeService.isWeek(periodType)) {
    columnSize = 1;
    rowSize = MS_IN_DAY * 31;
    tableRowSize = MS_IN_DAY * 7;
  } else if (pTypeService.isDay(periodType)) {
    columnSize = 1;
    rowSize = MS_IN_DAY * 31;
    tableRowSize = MS_IN_DAY;
  } else if (pTypeService.isShift(periodType)) {
    columnSize = SHIFT_DURATION_HOURS;
    rowSize = MS_IN_DAY;
    tableRowSize = MS_IN_HOUR * SHIFT_DURATION_HOURS;
  } else if (pTypeService.isHour(periodType)) {
    columnSize = 1;
    rowSize = MS_IN_DAY;
    tableRowSize = MS_IN_HOUR;
  } else if (pTypeService.is30Minute(periodType)) {
    columnSize = 0.5;
    rowSize = MS_IN_DAY;
    tableRowSize = MS_IN_HOUR * 0.5;
  } else if (pTypeService.is15Minute(periodType)) {
    columnSize = 0.25;
    rowSize = MS_IN_DAY;
    tableRowSize = MS_IN_HOUR * 0.25;
  } else if (pTypeService.isMinute(periodType)) {
    columnSize = 1;
    rowSize = MS_IN_HOUR;
    tableRowSize = MS_IN_MINUTE;
  } else if (pTypeService.isPri(periodType)) {
    columnSize = 1;
    rowSize = MS_IN_MINUTE;
    tableRowSize = 1000;
  }
  return {
    columnSize,
    rowSize,
    tableRowSize
  };
}

function getAxisConfig(periodType: string, pTypeService: IPTypeCheckService) {
  let xAxisTickPositions;
  let xAxisTitle;
  let yAxisTitle;
  let xAxisMinTickInterval;

  if (pTypeService.isYear(periodType)) {
    xAxisTitle = "Year";
    yAxisTitle = "Infinity";
    xAxisMinTickInterval = getSeriesConfig(periodType, pTypeService).columnSize;
  } else if (pTypeService.isMonth(periodType)) {
    xAxisTitle = "Month";
    yAxisTitle = "Year";
    xAxisMinTickInterval = getSeriesConfig(periodType, pTypeService).columnSize;
  } else if (pTypeService.isWeek(periodType)) {
    xAxisTitle = "Week";
    yAxisTitle = "Month";
    xAxisMinTickInterval = getSeriesConfig(periodType, pTypeService).columnSize;
  } else if (pTypeService.isDay(periodType)) {
    xAxisTitle = "Day";
    yAxisTitle = "Month";
    xAxisMinTickInterval = getSeriesConfig(periodType, pTypeService).columnSize;
  } else if (pTypeService.isShift(periodType)) {
    xAxisTickPositions = [0, 8, 16];
    xAxisTitle = "Shift";
    yAxisTitle = "Day";
    xAxisMinTickInterval = getSeriesConfig(periodType, pTypeService).columnSize;
  } else if (pTypeService.isHour(periodType)) {
    xAxisTitle = "Hour";
    yAxisTitle = "Day";
    xAxisMinTickInterval = getSeriesConfig(periodType, pTypeService).columnSize;
  } else if (pTypeService.is30Minute(periodType)) {
    xAxisTitle = "Time";
    yAxisTitle = "Day";
    xAxisMinTickInterval = 1;
  } else if (pTypeService.is15Minute(periodType)) {
    xAxisTitle = "Time";
    yAxisTitle = "Day";
    xAxisMinTickInterval = 1;
  } else if (pTypeService.isMinute(periodType)) {
    xAxisTitle = "Minute";
    yAxisTitle = "Hour";
    xAxisMinTickInterval = getSeriesConfig(periodType, pTypeService).columnSize;
  } else if (pTypeService.isPri(periodType)) {
    xAxisTitle = "Second";
    yAxisTitle = "Minute";
    xAxisMinTickInterval = getSeriesConfig(periodType, pTypeService).columnSize;
  } else {
    yAxisTitle = "unknown period type";
    xAxisTitle = "unknown period type";
  }
  return {
    xAxisTickPositions,
    yAxisTitle,
    xAxisTitle,
    xAxisMinTickInterval
  };
}
