import { Injectable } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { FilterFactory } from "../../core/services/filter/filter-factory.service";
import { ValueFormatterService } from "../../core/services/value-formatter.service";
import { DataPointDto, DataPointValue } from "../../data-connectivity";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { DateFormatterService } from "../../environment/services/date-formatter.service";
import { EnvironmentSelector } from "../../environment/services/environment.selector";
import { LocalizationService } from "../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { EntityId, PropertyInfo, TypeDescriptor } from "../../meta/models";
import { TypeProvider } from "../../meta/services/type-provider";
import { overridePropertyByPathCreatingIntermediates } from "../../property-sheet/helpers/override-property-by-path.helper";
import { NestedProperty } from "../../property-sheet/models/nested-property";
import { first, last } from "../../ts-utils/helpers/array.helper";
import { isEmpty, isEmptyOrNotDefined } from "../../ts-utils/helpers/is-empty.helper";
import { isArray, isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import {
  containsInterpolation,
  extractInterpolationParams,
  findInterpolationSourceInConnectorGroups,
  getInterpolatedNestedProperties,
  getPropertyValueInDeep,
  getSourceName,
  isCircularSearching,
  shouldTakeValueFromSignalName
} from "../helpers/property-interpolation.helper";
import { ComponentStateDto, ComponentStateViewModel } from "../models";
import { BaseViewConfigDto } from "../models/base-view-config";
import { IComponentConfigDescriptor } from "../models/component-config-descriptor";
import { DATA_CONNECTOR_VIEW_DTO } from "../models/entity-type.constants";
import { InterpolationSourceOptions } from "../models/interpolation-source-options";
import { NavLinkInfo } from "../models/nav-link-info";
import {
  DATA_POINT_VALUE_PROPERTY_NAME,
  InterpolationParams,
  InterpolationSourceType,
  TargetProperty,
  WidgetQueryFilter
} from "../models/property-interpolation-models";
import {
  CONNECTOR_GROUP_INPUT,
  CONNECTOR_SOURCE,
  CONNECTOR_VIEW_SOURCE,
  DATA_POINT_SOURCE,
  FILTER_SOURCE,
  SOURCE_INDEX_SEPARATORS,
  STRING_INTERPOLATION_INPUT,
  STRING_INTERPOLATION_REGEX,
  WIDGET_QUERY_TYPENAME,
  WIDGET_SOURCE
} from "../models/property-interpolation.constants";
import { DataConnectorDescriptor } from "../models/store/data-connector-descriptor";
import { TabConfig } from "../models/tab-config";
import { ComponentStateViewModelDeserializer } from "./deserializers/component-state-vm.deserializer";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";
import { DataConnectorSelector } from "./entity-selectors/data-connector.selector";
import { FilterSelector } from "./entity-selectors/filter.selector";
import { RuntimeSettingsSelector } from "./entity-selectors/runtime-settings.selector";

export type InterpolableTabViewType = NavLinkInfo | TabConfig;
export type InterpolableTargetType<T extends BaseViewConfigDto> =
  | T
  | DataConnectorDto
  | DataConnectorViewDto
  | InterpolableTabViewType;

@Injectable({ providedIn: "root" })
export class PropertyInterpolationService {
  aliasMode: boolean = false;

  constructor(
    private typeProvider: TypeProvider,
    private dataConnectorSelector: DataConnectorSelector,
    private translationService: LocalizationService,
    private environmentSelector: EnvironmentSelector,
    private filterFactory: FilterFactory,
    private filterSelector: FilterSelector,
    private componentStateVmDeserializer: ComponentStateViewModelDeserializer,
    private runtimeSettingsSelector: RuntimeSettingsSelector,
    private dateFormatterService: DateFormatterService,
    private componentSelector: ComponentStateSelector,
    private valueFormatterService: ValueFormatterService
  ) {
    this.aliasMode = this.environmentSelector.getAliasMode();
  }

  prepareAndInterpolateProperties<T extends BaseViewConfigDto>(
    componentState: ComponentStateDto,
    dataConnectors: DataConnectorDto[]
  ): IComponentConfigDescriptor<T> {
    const connectorDescriptors: DataConnectorDescriptor[] =
      this.dataConnectorSelector.getDataConnectorDescriptorsByConnectors(dataConnectors);

    return this.collectInterpolatedProperties<T>(componentState, connectorDescriptors);
  }

  collectInterpolatedProperties<T extends BaseViewConfigDto>(
    componentState: ComponentStateDto,
    connectorDescriptors: DataConnectorDescriptor[]
  ): IComponentConfigDescriptor<T> {
    const componentViewModel = this.componentStateVmDeserializer.convert(componentState);

    const sourceOptions: InterpolationSourceOptions = {
      componentViewModel,
      connectorDescriptors
    };
    const interpolatedComponentView = this.interpolatePropertiesForTarget(
      componentState.view,
      sourceOptions
    );

    return {
      viewConfig: interpolatedComponentView as T,
      connectorDescriptors: this.interpolateConnectorsProperties(
        componentViewModel,
        connectorDescriptors
      )
    };
  }

  interpolateConnectorsProperties(
    componentViewModel: ComponentStateViewModel,
    connectorDescriptors: DataConnectorDescriptor[]
  ): DataConnectorDescriptor[] {
    return connectorDescriptors.reduce(
      (acc: DataConnectorDescriptor[], descriptor: DataConnectorDescriptor) => {
        const sourceOptions: InterpolationSourceOptions = {
          componentViewModel,
          connectorDescriptors: [descriptor]
        };
        let interpolatedConnectorProperties = this.interpolatePropertiesForTarget(
          descriptor?.connector,
          sourceOptions
        );
        const interpolatedConnectorViewProperties = this.interpolatePropertiesForTarget(
          descriptor?.connectorView,
          sourceOptions
        );

        if (!isEmptyOrNotDefined(descriptor?.connectorView?.dataValue)) {
          interpolatedConnectorProperties = {
            ...interpolatedConnectorProperties,
            dataPoints: this.insertEvaluatedValueIntoDataPoints(componentViewModel, descriptor)
          };
        }
        const interpolatedConnectors: DataConnectorDescriptor = {
          connector: interpolatedConnectorProperties as DataConnectorDto,
          connectorView: new DataConnectorViewDto({
            ...interpolatedConnectorViewProperties,
            id: descriptor?.connectorView?.id,
            dataValue: descriptor?.connectorView?.dataValue
          })
        };
        acc.push(interpolatedConnectors);
        return acc;
      },
      []
    );
  }

  insertEvaluatedValueIntoDataPoints(
    componentViewModel: ComponentStateViewModel,
    connectorDescriptor: DataConnectorDescriptor
  ): Maybe<DataPointDto[]> {
    if (isNotDefined(connectorDescriptor.connector)) {
      return null;
    }

    if (isEmptyOrNotDefined(connectorDescriptor.connector.dataPoints)) {
      return connectorDescriptor.connector.dataPoints;
    }

    const interpolationPlaceholder = connectorDescriptor.connectorView?.dataValue ?? "";
    const propertySheetTargetInfo: TargetProperty = {
      targetType: DATA_CONNECTOR_VIEW_DTO,
      localPath: [DATA_POINT_VALUE_PROPERTY_NAME]
    };
    const sourceOptions: InterpolationSourceOptions = {
      componentViewModel,
      connectorDescriptors: [connectorDescriptor]
    };

    return connectorDescriptor.connector.dataPoints.map((dataPoint: DataPointDto) => {
      return {
        ...dataPoint,
        evaluatedValue: this.evaluateDataPointValue(
          interpolationPlaceholder,
          { ...sourceOptions, dataPoint },
          propertySheetTargetInfo
        )
      };
    });
  }

  evaluateDataPointValue(
    interpolationPlaceholder: string,
    sourceOptions: InterpolationSourceOptions,
    targetPropertyInfo: TargetProperty
  ): DataPointValue {
    let dataPointValue: DataPointValue = interpolationPlaceholder;
    if (interpolationPlaceholder.match(STRING_INTERPOLATION_INPUT)) {
      dataPointValue = this.processInterpolation(
        interpolationPlaceholder,
        sourceOptions,
        targetPropertyInfo
      );
    }

    return isNaN(Number(dataPointValue)) || isEmpty(dataPointValue)
      ? dataPointValue
      : Number(dataPointValue);
  }

  interpolatePropertiesForTarget<T extends BaseViewConfigDto>(
    target: InterpolableTargetType<T>,
    sourceOptions: InterpolationSourceOptions
  ): InterpolableTargetType<T> {
    const propsToInterpolate: PropertyInfo<unknown>[] = this.findInterpolableProperties(target);
    return propsToInterpolate.reduce((acc: InterpolableTargetType<T>, propInfo) => {
      isArray(propInfo.value)
        ? this.interpolateNestedProperties(target, sourceOptions, propInfo, acc)
        : this.interpolateProperty(target, sourceOptions, propInfo, acc);
      return acc;
    }, _cloneDeep(target));
  }

  findInterpolableProperties<T extends BaseViewConfigDto>(
    target: InterpolableTargetType<T>
  ): PropertyInfo<unknown>[] {
    const viewType: TypeDescriptor = this.typeProvider.getType(target.typeName);
    return this.typeProvider
      .getAllPropertyItemsDeep(viewType, target)
      .filter(
        (prop: PropertyInfo<unknown>) =>
          prop.descriptor.allowInterpolation && containsInterpolation(prop.value as string)
      );
  }

  private interpolateNestedProperties<T extends BaseViewConfigDto>(
    target: InterpolableTargetType<T>,
    sourceOptions: InterpolationSourceOptions,
    propInfo: PropertyInfo<unknown>,
    acc: InterpolableTargetType<T>
  ): void {
    const interpolatedProperties: NestedProperty[] = getInterpolatedNestedProperties(
      propInfo.value
    );
    interpolatedProperties.map((nestedProperty) => {
      const interpolationString: string =
        propInfo.value[nestedProperty.objectIndex][nestedProperty.propertyName];
      const value: string = this.processInterpolation(interpolationString, sourceOptions, {
        targetType: target.typeName,
        localPath: propInfo.localPath
      } as TargetProperty);
      overridePropertyByPathCreatingIntermediates(propInfo.localPath, acc, value, nestedProperty);
    });
  }

  private interpolateProperty<T extends BaseViewConfigDto>(
    target: InterpolableTargetType<T>,
    sourceOptions: InterpolationSourceOptions,
    propInfo: PropertyInfo<unknown>,
    acc: InterpolableTargetType<T>
  ): void {
    const value: string = this.processInterpolation(propInfo.value as string, sourceOptions, {
      targetType: target.typeName,
      localPath: propInfo.localPath
    } as TargetProperty);
    overridePropertyByPathCreatingIntermediates(propInfo.localPath, acc, value);
  }

  processInterpolation(
    interpolationPlaceholder: string,
    sourceOptions: InterpolationSourceOptions,
    targetPropertyInfo: TargetProperty
  ): string {
    const interpolationPropertyService = this;
    return interpolationPlaceholder.replace(STRING_INTERPOLATION_REGEX, (interpolationString) =>
      interpolationPropertyService.replaceInterpolationWithActualValue(
        interpolationString,
        sourceOptions,
        targetPropertyInfo
      )
    );
  }

  replaceInterpolationWithActualValue(
    interpolationString: string,
    sourceOptions: InterpolationSourceOptions,
    targetPropertyInfo: TargetProperty
  ): any {
    const interpolationPlaceholderParams = extractInterpolationParams(interpolationString);
    const source: InterpolationSourceType = this.getInterpolationSource(
      getSourceName(interpolationPlaceholderParams.source),
      sourceOptions,
      targetPropertyInfo
    );

    if (
      isCircularSearching(source, interpolationPlaceholderParams.propertyName, targetPropertyInfo)
    ) {
      return this.translationService.get(
        LOCALIZATION_DICTIONARY.propertySheet.InvalidPropertySource
      );
    }
    const interpolationParams: InterpolationParams = {
      source: source as InterpolationSourceType,
      propertyName: interpolationPlaceholderParams.propertyName,
      displayFormat: sourceOptions.componentViewModel.view.displayFormat
    };

    return (
      this.getCorrespondingPropertyValue(interpolationParams, sourceOptions, targetPropertyInfo) ??
      ""
    );
  }

  getInterpolationSource(
    sourcePathFromInput: string,
    sourceOptions: InterpolationSourceOptions,
    targetPropertyInfo: Maybe<TargetProperty> = null
  ): InterpolationSourceType {
    const source: string[] = sourcePathFromInput.split(SOURCE_INDEX_SEPARATORS);
    if (source[0] === WIDGET_SOURCE) {
      const componentVM = sourceOptions.componentViewModel;
      //NOTE: emptied in order not to interfere with interpolated widget properties
      componentVM.children = [];
      componentVM.dataConnectors = [];
      return componentVM;
    }
    if (source[0] === FILTER_SOURCE) {
      return this.getWigetQueryFilter(sourceOptions);
    }
    if (sourcePathFromInput.match(CONNECTOR_GROUP_INPUT)) {
      return findInterpolationSourceInConnectorGroups(sourceOptions, source[0], source);
    }
    if (
      source[0] === CONNECTOR_SOURCE ||
      source[0] === CONNECTOR_VIEW_SOURCE ||
      source[0] === DATA_POINT_SOURCE
    ) {
      const connectorDescriptorToInterpolate: DataConnectorDescriptor =
        this.getConnectorDescriptorByIndex(
          Number(source[1]) - 1,
          sourceOptions.connectorDescriptors
        );
      return this.getInterpolationSourceFromConnectorDescriptor(
        source[0],
        connectorDescriptorToInterpolate,
        sourceOptions,
        targetPropertyInfo
      );
    }
  }

  private getConnectorDescriptorByIndex(
    connectorIndex: number,
    connectorDescriptors: DataConnectorDescriptor[]
  ): DataConnectorDescriptor {
    const connectorsOutsideGroups: DataConnectorDescriptor[] = connectorDescriptors?.filter(
      (connectorDescriptor: DataConnectorDescriptor) =>
        isDefined(connectorDescriptor.connectorView) &&
        isEmpty(connectorDescriptor.connectorView?.groupId)
    );

    return isNaN(connectorIndex)
      ? first(connectorsOutsideGroups)
      : connectorsOutsideGroups[connectorIndex];
  }

  private getInterpolationSourceFromConnectorDescriptor(
    interpolationSource: string,
    connectorDescriptor: DataConnectorDescriptor,
    sourceOptions: InterpolationSourceOptions,
    targetPropertyInfo: Maybe<TargetProperty> = null
  ): InterpolationSourceType {
    switch (interpolationSource) {
      case CONNECTOR_SOURCE:
        return {
          ...connectorDescriptor?.connector,
          dataPoints: [] //NOTE: empty data points because a new interpolation source (dataPoint) is added
        } as DataConnectorDto;
      case CONNECTOR_VIEW_SOURCE:
        return connectorDescriptor?.connectorView;
      case DATA_POINT_SOURCE:
        return this.extractInterpolableDataPoint(sourceOptions, targetPropertyInfo);
    }
  }

  private extractInterpolableDataPoint(
    sourceOptions: InterpolationSourceOptions,
    targetPropertyInfo: Maybe<TargetProperty> = null
  ): Maybe<DataPointDto> {
    return isDefined(sourceOptions.dataPoint)
      ? sourceOptions.dataPoint
      : this.getLastDataPoint(sourceOptions.connectorDescriptors, targetPropertyInfo);
  }

  getLastDataPoint(
    connectorDescriptors: DataConnectorDescriptor[],
    targetPropertyInfo: Maybe<TargetProperty>
  ): Maybe<DataPointDto> {
    //THE LAST DATA POINT IS USED AS THE SOURCE FOR NON-DATA-VALUE PROPERTIES WITHIN PROPERTY SHEET
    if (isNotDefined(targetPropertyInfo)) {
      return null;
    }

    if (this.containsInterpolationStringInDataValue(targetPropertyInfo.localPath)) {
      return null;
    }

    const firstConnectorDescriptorOutsideGroups = connectorDescriptors.find(
      (connectorDescriptor) =>
        isDefined(connectorDescriptor.connectorView) &&
        isEmpty(connectorDescriptor.connectorView?.groupId)
    );
    if (isNotDefined(firstConnectorDescriptorOutsideGroups)) {
      return null;
    }
    return last(firstConnectorDescriptorOutsideGroups.connector?.dataPoints);
  }

  private containsInterpolationStringInDataValue(propertyPath: string[]): boolean {
    const dataValuePropertyContainsInterpolation: Maybe<boolean> = last(propertyPath)?.includes(
      DATA_POINT_VALUE_PROPERTY_NAME
    );
    return (
      isDefined(dataValuePropertyContainsInterpolation) && dataValuePropertyContainsInterpolation
    );
  }

  getWigetQueryFilter(sourceOptions: InterpolationSourceOptions): WidgetQueryFilter {
    const filter = this.filterSelector.getByIdOrDefault(
      sourceOptions.componentViewModel.filterConfig.id
    );
    const queryFilter = this.filterFactory.createQueryFilterFromConfiguration(filter);
    const widgetPeriodType =
      sourceOptions.componentViewModel.dataConnectorQuery.aggregationConfig.periodType;
    const globalPeriodType = this.runtimeSettingsSelector.getPeriodType();
    const widgetDateFormat = sourceOptions.componentViewModel.view.displayFormat.dateFormat;
    return {
      queryFilter: {
        ...queryFilter,
        timeRange: {
          from: this.dateFormatterService.formatDate(queryFilter.timeRange.from, widgetDateFormat),
          to: this.dateFormatterService.formatDate(queryFilter.timeRange.to, widgetDateFormat)
        }
      },
      periodType: isEmptyOrNotDefined(widgetPeriodType) ? globalPeriodType : widgetPeriodType,
      typeName: WIDGET_QUERY_TYPENAME
    };
  }

  getCorrespondingPropertyValue(
    interpolationParams: InterpolationParams,
    sourceOptions: InterpolationSourceOptions,
    targetPropertyInfo: TargetProperty
  ): any {
    interpolationParams.propertyName = shouldTakeValueFromSignalName(
      interpolationParams,
      this.aliasMode
    );

    const actualValue = getPropertyValueInDeep(
      interpolationParams.source,
      interpolationParams.propertyName
    );
    targetPropertyInfo = {
      ...targetPropertyInfo,
      targetType: getSourceName(interpolationParams.source) ?? ""
    };

    if (containsInterpolation(actualValue)) {
      return this.processInterpolation(actualValue, sourceOptions, targetPropertyInfo);
    }

    return this.valueFormatterService.formatValue(actualValue, interpolationParams.displayFormat);
  }

  prepareAndProcessInterpolation(
    interpolationPlaceholder: string,
    componentId: EntityId,
    connectorDescriptors: DataConnectorDescriptor[]
  ): string {
    if (
      isDefined(interpolationPlaceholder) &&
      interpolationPlaceholder.match(STRING_INTERPOLATION_INPUT)
    ) {
      const component = this.componentSelector.getById(componentId);
      const componetVM = this.componentStateVmDeserializer.convert(component);
      const sourceOptions: InterpolationSourceOptions = {
        componentViewModel: componetVM,
        connectorDescriptors
      };
      return this.processInterpolation(interpolationPlaceholder, sourceOptions, {
        localPath: [""],
        targetType: ""
      });
    }
    return interpolationPlaceholder;
  }
}
