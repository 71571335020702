<webgui-core-outlet
  [id]="activeReport?.reportId"
  [hideHeader]="isMobileUserAgent()"
  [title]="projectName"
  [specificEnvironmentName]="environmentName"
  [hasAccessToEdit]="hasAccessToEdit"
  [showHeaderStatusIndicator]="false"
  (toggleReportBrowser)="toggleSidenav()"
  (activeReportChanged)="setActiveReport($event)"
>
  <sidebar-navigation
    *ngIf="!isMobileUserAgent()"
    class="left-sidebar-nav"
    [activeReport]="activeReport"
  >
  </sidebar-navigation>
</webgui-core-outlet>
