import { SCATTER_CHART_COMPONENT_NAME } from "../../core/models/dto-type.constants";
import { construct } from "../../core/services/construct.helper";
import { ClusterDisplayStrategies } from "../../elements/components/cluster-chart/cluster-display-strategies";
import {
  filterOutEnumValues,
  selectComponentTypeByConnectorViewId,
  selectConnectorComputability,
  selectDisplayStrategyByConnectorViewId
} from "../../elements/helpers/dynamically-visible-properties.helper";
import { getYAxesDisplayStrategies, getYAxisNames } from "../../elements/helpers/y-axis.helper";
import { DisplayStrategies as TimeSeriesDisplayStrategies } from "../../elements/models/display-strategies/time-series-display-strategies";
import { DATA_CONNECTOR_VIEW_DTO } from "../../elements/models/entity-type.constants";
import { ScatterConnectorView } from "../../elements/models/scatter-connector-view";
import { TableConnectorView } from "../../elements/models/table/table-connector-view";
import { TimeSeriesConnectorView } from "../../elements/models/timeseries-connector-view";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { DynamicallyVisible } from "../../meta/decorators/dynamically-visible.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { AllowInterpolation } from "../../meta/decorators/interpolation.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { Entity } from "../../meta/models/entity";
import { PropertyCategory } from "../../meta/models/property-category";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { StrictPartial } from "../../ts-utils/models/strict-partial.type";

const TYPE_NAME = DATA_CONNECTOR_VIEW_DTO;

// @dynamic
@EditableType({ fullName: TYPE_NAME, virtual: true, title: "data-connector-view" })
export class DataConnectorViewDto extends Entity {
  typeName = TYPE_NAME;

  @Serializable("")
  groupId!: string;

  @ConfigurableEnum({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.AxisName,
    enumSource: getYAxisNames
  })
  @DynamicallyVisible(selectDisplayStrategyByConnectorViewId, getYAxesDisplayStrategies())
  @Serializable(null)
  axisId: Maybe<string>;

  // cannot be on Data category, as these are not shown for dynamic connectors
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 2)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker
  })
  @Serializable("")
  color: Maybe<string>;

  @DynamicallyVisible(
    selectDisplayStrategyByConnectorViewId,
    filterOutEnumValues(TimeSeriesDisplayStrategies, [TimeSeriesDisplayStrategies.Table])
  )
  @Serializable(new TimeSeriesConnectorView())
  timeSeriesConfig!: TimeSeriesConnectorView;

  @DynamicallyVisible(selectComponentTypeByConnectorViewId, [SCATTER_CHART_COMPONENT_NAME])
  @Serializable(new ScatterConnectorView())
  scatterSeriesConfig!: ScatterConnectorView;

  @DynamicallyVisible(selectDisplayStrategyByConnectorViewId, [TimeSeriesDisplayStrategies.Table])
  @Serializable(new TableConnectorView())
  column!: TableConnectorView;

  @DynamicallyVisible(selectDisplayStrategyByConnectorViewId, [
    ClusterDisplayStrategies.ClusteredMultiPointBarChart
  ])
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DateFormat,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  dateFormat: string;

  @DynamicallyVisible(selectDisplayStrategyByConnectorViewId, [
    ClusterDisplayStrategies.ClusteredMultiPointBarChart
  ])
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LineType,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  isLine: boolean;

  @DynamicallyVisible(selectDisplayStrategyByConnectorViewId, [
    ClusterDisplayStrategies.ClusteredMultiPointBarChart
  ])
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowDataLabel,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  showDataLabel: boolean;

  @Serializable()
  title!: string;

  @Serializable()
  order!: number;

  @Serializable("")
  public role!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DataValue,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.DataValueTooltip,
    editorType: EditorType.TextBox
  })
  @AllowInterpolation()
  @Serializable("")
  @DynamicallyVisible(selectConnectorComputability, [false])
  dataValue!: string;

  constructor(connectorView: StrictPartial<DataConnectorViewDto, "id">) {
    super();
    construct(this, connectorView, TYPE_NAME);
  }
}
