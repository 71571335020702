import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  ConfigurableArray,
  ConfigurableEnum,
  ConfigurationCategory,
  EditableType,
  OnPropertyChange,
  Serializable
} from "../../../meta/decorators";
import { EditorSize } from "../../../meta/models/editor-size";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyCategory } from "../../../meta/models/property-category";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import {
  initStatusIndicatorStates,
  onChangeDisplayStrategy
} from "../../helpers/status-indicator.helper";
import { ComponentCssSize } from "../../models/component-size";
import { IndicatorDisplayStrategy } from "../../models/status-indicator/indicator-display-strategy";
import { IndicatorState } from "../../models/status-indicator/indicator-state";
import { STATUS_INDICATOR_VIEW_CONFIG } from "../../models/view-config-type.constants";
import { StrategizedChartViewConfig } from "../strategized-chart/view-config";

// @dynamic
@EditableType({ fullName: STATUS_INDICATOR_VIEW_CONFIG })
export class StatusIndicatorViewConfig extends StrategizedChartViewConfig {
  typeName = STATUS_INDICATOR_VIEW_CONFIG;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @ConfigurableEnum({
    enumSource: IndicatorDisplayStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.IndicatorType
  })
  @OnPropertyChange<any, StatusIndicatorViewConfig, IndicatorDisplayStrategy>(
    onChangeDisplayStrategy
  )
  @Serializable(IndicatorDisplayStrategy.Binary)
  displayStrategy!: IndicatorDisplayStrategy;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.IndicatorStates
  )
  @ConfigurableArray({
    editorType: EditorType.Array,
    typeConstructor: IndicatorState,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    arrayEditorSize: EditorSize.Medium,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.StatesTooltip
  })
  @Serializable([], IndicatorState)
  states!: IndicatorState[];

  constructor(viewConfigDto: DeepPartial<StatusIndicatorViewConfig> = {}) {
    super();
    viewConfigDto = {
      ...viewConfigDto,
      states: isDefined(viewConfigDto.states)
        ? viewConfigDto.states.map((state) => new IndicatorState(state))
        : initStatusIndicatorStates(viewConfigDto.displayStrategy, STATUS_INDICATOR_VIEW_CONFIG)
    };
    construct(this, viewConfigDto, STATUS_INDICATOR_VIEW_CONFIG, {
      size: getDefaultSize()
    });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("100", "75");
}
