import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurableEnum,
  ConfigurationCategory,
  EditableType,
  NotSerializable,
  Serializable
} from "../../../meta/decorators";
import { DynamicallyVisible } from "../../../meta/decorators/dynamically-visible.decorator";
import { Hidden } from "../../../meta/decorators/hidden.decorator";
import { HideChildProperties } from "../../../meta/decorators/hide-child-properties.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyCategory } from "../../../meta/models/property-category";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import {
  getTemplateBuilderMode$,
  selectDisplayStrategyByComponentId
} from "../../helpers/dynamically-visible-properties.helper";
import { ComponentCssSize } from "../../models/component-size";
import { ContainerComponentStyleDto } from "../../models/container-component-style";
import { DisplayFormatDto } from "../../models/display-format";
import { DisplayStrategies } from "../../models/display-strategies/container-display-strategies";
import {
  CardRuntimeViewProperties,
  NEUTRAL_SCALING_FACTOR
} from "../../models/runtime-view-properties";
import { BASIC_CARD_VIEW_CONFIG } from "../../models/view-config-type.constants";
import { ContainerComponentViewConfig } from "../container/view-config";

const DEFAULT_WIDTH = "755.00px";
const DEFAULT_HEIGHT = "500.00px";
const DEFAULT_COLLAPSED_HEIGHT = "245.00px";

// @dynamic
@EditableType({ fullName: BASIC_CARD_VIEW_CONFIG })
export class BasicCardViewConfig extends ContainerComponentViewConfig {
  typeName = BASIC_CARD_VIEW_CONFIG;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @ConfigurableEnum({
    enumSource: DisplayStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(DisplayStrategies.BasicCard)
  displayStrategy!: string;

  @ConfigurationCategory(PropertyCategory.Interaction)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Extendable,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(false)
  expandable!: boolean;

  @Serializable(true)
  expanded!: boolean;

  @Serializable(new ComponentCssSize(DEFAULT_WIDTH, DEFAULT_HEIGHT))
  size: ComponentCssSize;

  @HideChildProperties()
  @Serializable(new ComponentCssSize(DEFAULT_WIDTH, DEFAULT_COLLAPSED_HEIGHT))
  collapsedSize!: ComponentCssSize;

  @HideChildProperties()
  @Serializable(new ComponentCssSize(DEFAULT_WIDTH, DEFAULT_HEIGHT))
  expandedSize!: ComponentCssSize;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.TextColorTooltip
  })
  @DynamicallyVisible(getTemplateBuilderMode$, [false])
  @Serializable("")
  color!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowHeader,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(getTemplateBuilderMode$, [false])
  @Serializable(true)
  showHeader!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowFooter,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(getTemplateBuilderMode$, [false])
  @Serializable(true)
  showFooter!: boolean;

  @ConfigurationCategory(PropertyCategory.Interaction)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ExpandEnabled,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ExpandEnabledTooltip,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(true)
  historyViewEnabled!: boolean;

  @ConfigurationCategory(PropertyCategory.Interaction)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ExportEnabled,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ExportEnabledTooltip,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  exportEnabled!: boolean;

  @ConfigurationCategory(PropertyCategory.Interaction)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.EnableScaling,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  canBeScaled!: boolean;

  @NotSerializable()
  hidable!: boolean;

  @Serializable(new ContainerComponentStyleDto())
  css!: ContainerComponentStyleDto;

  @NotSerializable()
  exporting!: boolean;

  @Hidden()
  titleFormat!: string;

  @NotSerializable()
  @HideChildProperties()
  displayFormat!: DisplayFormatDto;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Display)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.VerticalAlignment,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.AutoLayout])
  @Serializable(true)
  vertical!: boolean;

  runtimeView: CardRuntimeViewProperties;

  noBoxShadow: boolean = false;
  resizable: boolean = true;

  constructor(viewConfigDto: DeepPartial<BasicCardViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<BasicCardViewConfig> = {
      title: "Basic Card",
      useDragOverlay: false,
      hasRuntimeData: false,
      runtimeView: {
        runtimeSize: this.runtimeView.runtimeSize,
        scalingFactor: NEUTRAL_SCALING_FACTOR,
        padding: 0
      }
    };
    construct(this, viewConfigDto, BASIC_CARD_VIEW_CONFIG, defaultOverrides);
  }
}
