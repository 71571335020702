import { Injectable } from "@angular/core";
import { Dictionary } from "@ngrx/entity";
import { cloneDeep as _cloneDeep } from "lodash";
import { getDefaultFilterIdForEntity } from "../../core/helpers/filter/filter-id.helper";
import {
  getConnectorIdByViewId,
  getConnectorViewId
} from "../../data-connectivity/helpers/connector-view-id.helper";
import {
  isEquipmentPartial,
  isGenericOrApiPartial
} from "../../data-connectivity/helpers/data-source-type.helper";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { EntityId } from "../../meta/models/entity";
import { TypeProvider } from "../../meta/services/type-provider";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { TabGroupCardViewConfig } from "../components/tab-group/view-config";
import { isPseudoConnector } from "../helpers/connectors.helper";
import {
  DataConnectorEntitiesToAdd,
  FilterEntitiesToAdd,
  ReportEntitiesWithChildrenIds
} from "../models/cloning-recursion-models";
import { ComponentStateDto } from "../models/component-state";
import { ComponentStateViewModel } from "../models/component-state.vm";
import { isTabGroup } from "../models/component-type.helper";
import { FullComponentStateVM } from "../models/full-component-state-vm";
import { MultiplePasteEntities } from "../models/multiple-paste-entities";
import { PasteEntities } from "../models/paste-entities";
import { ReportEntities } from "../models/report-entities";
import { TabConfig } from "../models/tab-config";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";
import { ComponentStateViewModelSerializer } from "./serializers/component-state-vm.serializer";
@Injectable({ providedIn: "root" })
export class CloningService {
  private localComponentCounter: number;

  constructor(
    private componentStateVMSerializer: ComponentStateViewModelSerializer,
    private typeProvider: TypeProvider,
    private componentStateSelector: ComponentStateSelector
  ) {
    this.localComponentCounter = 0;
  }

  private refreshLocalComponentCounter() {
    this.localComponentCounter = this.componentStateSelector.getComponentCount();
  }

  public cloneComponentTree(
    rootComponent: FullComponentStateVM,
    keepLocalCounter: boolean
  ): PasteEntities {
    if (!keepLocalCounter) {
      this.refreshLocalComponentCounter();
    }
    return this.cloneComponentFromRoot(rootComponent);
  }

  private cloneComponentFromRoot(rootVM: FullComponentStateVM): PasteEntities {
    const { childrenIds, reportEntitiesToAdd, childMappings } = getRecursiveReportEntities(
      rootVM,
      (child) => this.cloneComponentFromRoot(child)
    );
    const root = this.componentStateVMSerializer.convert(rootVM);
    let clonedComponent = this.createComponentClone(root);
    const dataConnectorEntitiesInfo = this.cloneDataConnectors(rootVM, clonedComponent);
    const filterEntitiesInfo = this.createFilterClone(
      rootVM,
      getDefaultFilterIdForEntity(clonedComponent.id)
    );
    clonedComponent = this.setComponentStateIds(
      clonedComponent,
      childrenIds,
      dataConnectorEntitiesInfo.entityIds,
      filterEntitiesInfo.entityId
    );
    clonedComponent = this.updateNestedIdsInViewConfig(clonedComponent, childMappings);
    childMappings[rootVM.id] = clonedComponent.id;
    return {
      entities: mergeReportEntities(reportEntitiesToAdd, {
        componentStates: [clonedComponent],
        filters: filterEntitiesInfo.entities.filters,
        dataConnectorViews: dataConnectorEntitiesInfo.entities.dataConnectorViews,
        dataConnectors: dataConnectorEntitiesInfo.entities.dataConnectors
      }),
      entityId: clonedComponent.id,
      mappings: childMappings
    };
  }

  private updateNestedIdsInViewConfig(
    component: ComponentStateDto,
    childMappings: Dictionary<EntityId>
  ): ComponentStateDto {
    if (isTabGroup(component.type)) {
      const tabs: TabConfig[] = this.cloneTabs(component, childMappings);
      component = this.setViewConfigTabs(component, tabs);
    }
    return component;
  }

  private setViewConfigTabs(component: ComponentStateDto, tabs: TabConfig[]): ComponentStateDto {
    return {
      ...component,
      view: new TabGroupCardViewConfig({
        ...component.view,
        tabs: tabs
      })
    };
  }

  private setComponentStateIds(
    component: ComponentStateDto,
    childrenIds: EntityId[],
    dataConnectorIds: EntityId[],
    filterId: EntityId | null
  ): ComponentStateDto {
    return {
      ...component,
      childrenIds,
      dataConnectorIds: dataConnectorIds,
      filterId: filterId
    };
  }

  cloneDataConnectors(
    clonedComponent: FullComponentStateVM,
    newComponent: ComponentStateDto
  ): DataConnectorEntitiesToAdd {
    const dataConnectorEntities = clonedComponent.dataConnectors.reduce(
      (connectorClones: DataConnectorEntitiesToAdd, dataConnector) => {
        const connectorClone = this.createConnectorClone(
          dataConnector,
          clonedComponent.id,
          newComponent
        );
        const originalConnectorView = clonedComponent.dataConnectorViews.find(
          (view) => view.id === getConnectorViewId(dataConnector.id)
        );
        const mapping: Dictionary<EntityId> = {};
        mapping[dataConnector.id] = connectorClone.id;
        return isDefined(originalConnectorView)
          ? this.addToConnectorEntities(
              connectorClone,
              originalConnectorView,
              mapping,
              connectorClones
            )
          : connectorClones;
      },
      {
        entities: {
          dataConnectors: [],
          dataConnectorViews: []
        },
        entityIds: [],
        connectorMappings: {}
      }
    );

    return {
      ...dataConnectorEntities,
      entities: {
        ...dataConnectorEntities.entities,
        dataConnectorViews: this.persistConnectorViewsOrder(dataConnectorEntities, clonedComponent)
      }
    };
  }

  private addToConnectorEntities(
    connector: DataConnectorDto,
    connectorView: DataConnectorViewDto,
    connectorMapping: Dictionary<EntityId>,
    connectorEntities: DataConnectorEntitiesToAdd
  ): DataConnectorEntitiesToAdd {
    return {
      entityIds: [...connectorEntities.entityIds, connector.id],
      entities: {
        dataConnectors: [...connectorEntities.entities.dataConnectors, connector],
        dataConnectorViews: [
          ...connectorEntities.entities.dataConnectorViews,
          this.createConnectorViewClone(connectorView, getConnectorViewId(connector.id))
        ]
      },
      connectorMappings: { ...connectorEntities.connectorMappings, ...connectorMapping }
    };
  }

  private persistConnectorViewsOrder(
    dataConnectorEntities: DataConnectorEntitiesToAdd,
    clonedComponent: FullComponentStateVM
  ) {
    return clonedComponent.dataConnectorViews
      .map((oldDcView) => {
        const oldConnectorId = getConnectorIdByViewId(oldDcView.id);
        const newConnectorViewId = getConnectorViewId(
          dataConnectorEntities.connectorMappings[oldConnectorId]
        );
        return dataConnectorEntities.entities.dataConnectorViews.find(
          (view) => view.id === newConnectorViewId
        );
      })
      .filter(isDefined);
  }

  private createFilterClone(
    component: ComponentStateViewModel,
    cloneID: EntityId
  ): FilterEntitiesToAdd {
    if (isNotDefined(component.filterConfig)) {
      return {
        entities: {
          filters: []
        },
        entityId: null
      };
    }
    return {
      entityId: cloneID,
      entities: {
        filters: [
          {
            ...component.filterConfig,
            timeRange: { ...component.filterConfig.timeRange },
            customFilters: { ...component.filterConfig.customFilters },
            id: cloneID
          }
        ]
      }
    };
  }

  private createComponentClone(component: ComponentStateDto): ComponentStateDto {
    return ComponentStateDto.createWithCounter(
      component.type,
      this.localComponentCounter++,
      this.typeProvider,
      component
    );
  }

  public createConnectorCloneWithRandomId(dataConnector: DataConnectorDto) {
    return {
      ..._cloneDeep(dataConnector),
      id: new DataConnectorDto().id
    };
  }

  public createConnectorClone(
    dataConnector: DataConnectorDto,
    oldComponentId: EntityId,
    newComponent: ComponentStateDto
  ): DataConnectorDto {
    const clonedConnectorId =
      isGenericOrApiPartial(newComponent.dataConnectorQuery) ||
      isEquipmentPartial(newComponent.dataConnectorQuery) ||
      isPseudoConnector(dataConnector.id)
        ? this.resolveNewConnectorId(dataConnector, newComponent, oldComponentId)
        : new DataConnectorDto().id;

    return {
      ..._cloneDeep(dataConnector),
      id: clonedConnectorId
    };
  }

  private resolveNewConnectorId(
    oldConnector: DataConnectorDto,
    newComponent: ComponentStateDto,
    oldComponentId: EntityId
  ): EntityId {
    if (oldConnector.id.toString().startsWith(oldComponentId.toString())) {
      return oldConnector.id
        .toString()
        .replace(oldComponentId.toString(), newComponent.id.toString());
    }
    return new DataConnectorDto().id;
  }

  public createConnectorViewClone(
    originalDCView: DataConnectorViewDto,
    cloneID: EntityId
  ): DataConnectorViewDto {
    return new DataConnectorViewDto({
      ...originalDCView,
      id: cloneID
    });
  }

  private createTabClone(tab: TabConfig, contentContainerId: EntityId): TabConfig {
    return new TabConfig({
      backgroundImage: tab.backgroundImage,
      title: tab.title,
      contentContainerId: contentContainerId
    });
  }

  private cloneTabs(
    component: ComponentStateDto,
    childMappings: Dictionary<EntityId>
  ): TabConfig[] {
    return (component.view as TabGroupCardViewConfig).tabs.reduce((tabs: TabConfig[], tab) => {
      const tabContentID = childMappings[tab.contentContainerId];
      return isDefined(tabContentID) ? tabs.concat(this.createTabClone(tab, tabContentID)) : tabs;
    }, []);
  }
}

function getRecursiveReportEntities(
  component: FullComponentStateVM,
  recursiveFunc: (child: FullComponentStateVM) => PasteEntities
): ReportEntitiesWithChildrenIds {
  return component.children.reduce(
    (entitiesAcc: ReportEntitiesWithChildrenIds, child: FullComponentStateVM) => {
      const childEntitiesInfo = recursiveFunc(child);
      return {
        reportEntitiesToAdd: mergeReportEntities(
          entitiesAcc.reportEntitiesToAdd,
          childEntitiesInfo.entities
        ),
        childrenIds: [...entitiesAcc.childrenIds, childEntitiesInfo.entityId],
        childMappings: {
          ...childEntitiesInfo.mappings,
          ...entitiesAcc.childMappings
        }
      };
    },
    {
      childMappings: {},
      reportEntitiesToAdd: {
        componentStates: [],
        dataConnectors: [],
        dataConnectorViews: [],
        filters: []
      },
      childrenIds: []
    }
  );
}

export function mergeReportEntities(
  targetEntities: ReportEntities,
  sourceEntities: ReportEntities
): ReportEntities {
  return {
    componentStates: [...targetEntities.componentStates, ...sourceEntities.componentStates],
    dataConnectors: [...targetEntities.dataConnectors, ...sourceEntities.dataConnectors],
    dataConnectorViews: [
      ...targetEntities.dataConnectorViews,
      ...sourceEntities.dataConnectorViews
    ],
    filters: [...targetEntities.filters, ...sourceEntities.filters]
  };
}
export function reducePasteEntities(allPasteEntities: PasteEntities[]): MultiplePasteEntities {
  return allPasteEntities.reduce(
    (acc: MultiplePasteEntities, singlePasteEntities: PasteEntities) => {
      return {
        mappings: { ...acc.mappings, ...singlePasteEntities.mappings },
        pastedEntitiesIds: [...acc.pastedEntitiesIds, singlePasteEntities.entityId],
        entities: mergeReportEntities(acc.entities, singlePasteEntities.entities)
      };
    },
    {
      mappings: {},
      pastedEntitiesIds: [],
      entities: {
        componentStates: [],
        dataConnectorViews: [],
        dataConnectors: [],
        filters: []
      }
    }
  );
}
