import { cloneDeep as _cloneDeep, isEqual as _isEqual, max as _max, sum as _sum } from "lodash";
import { EntityId } from "../../meta";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { BasicCardViewConfig } from "../components/basic-card/view-config";
import { cssSizeParser, SizeType } from "../models";
import { ComponentStateDto, hasChildren } from "../models/component-state";
import { isAutoLayoutContainer } from "../models/display-strategies/display-strategy-type.helper";
import { RuntimeViewProperties } from "../models/runtime-view-properties";
import { SizeInPx } from "../models/size-in-px";
import { RuntimeViewService } from "../services/runtime-view.service";
import { ROOT_COMPONENT_ID } from "../store/component-state/component-state.selectors";

export const AUTO_LAYOUT_PADDING_PX = 7.5;

export function setRuntimeViewOnLoad(
  reportEntities: Dictionary<ComponentStateDto>,
  runtimeViewService: RuntimeViewService
): Dictionary<ComponentStateDto> {
  const updates = getRuntimeViewUpdate(reportEntities, runtimeViewService);
  Object.keys(updates).forEach((componentId) => {
    const component = reportEntities[componentId];
    const viewPropsUpdate = updates[componentId];
    if (isDefined(component) && isDefined(viewPropsUpdate)) {
      component.view.runtimeView = viewPropsUpdate;
    }
  });
  return reportEntities;
}

export function getRuntimeViewUpdate(
  reportEntities: Dictionary<Maybe<ComponentStateDto>>,
  runtimeViewService: RuntimeViewService
): Dictionary<RuntimeViewProperties> {
  const root = reportEntities[ROOT_COMPONENT_ID];
  // NOTE when resizing while store is not yet loaded correctly size is undefined
  if (isNotDefined(root) || isNotDefined(root.view.size)) {
    return {};
  }
  const rootRuntimeView = runtimeViewService.calculateRootRuntimeSize();
  const updates: Dictionary<RuntimeViewProperties> = {};
  updates[ROOT_COMPONENT_ID] = rootRuntimeView;
  const rootContentSize = runtimeViewService.calculateContentSize(
    {
      ...root.view,
      runtimeView: rootRuntimeView
    },
    root.childrenIds.length
  );

  return {
    ...updates,
    ...calculateDeepChildrenRuntimeViewProps(
      root,
      reportEntities,
      rootContentSize,
      runtimeViewService
    )
  };
}

export function getRuntimeViewPropsUpdate(
  reportEntities: Dictionary<Maybe<ComponentStateDto>>,
  id: EntityId,
  parentContentSize: SizeInPx,
  runtimeViewService: RuntimeViewService
): Dictionary<RuntimeViewProperties> {
  const componentState = reportEntities[id];
  if (isNotDefined(componentState)) {
    return {};
  }
  let updates: Dictionary<RuntimeViewProperties> = {};

  const oldRuntimeView = componentState.view.runtimeView;
  const runtimeView = runtimeViewService.calculateRuntimeSize(
    componentState.view,
    parentContentSize
  );

  if (!_isEqual(runtimeView, oldRuntimeView)) {
    updates[componentState.id] = runtimeView;
  }
  if (!hasChildren(componentState)) {
    return updates;
  }
  const contentSize = runtimeViewService.calculateContentSize(
    {
      ...componentState.view,
      runtimeView: runtimeView
    },
    componentState.childrenIds.length
  );

  updates = {
    ...updates,
    ...calculateDeepChildrenRuntimeViewProps(
      componentState,
      reportEntities,
      contentSize,
      runtimeViewService
    )
  };
  if (isAutoLayoutContainer(componentState.view)) {
    updateComponentHugSize(componentState, reportEntities, updates);
  }

  return updates;
}

function updateComponentHugSize(
  componentState: ComponentStateDto,
  reportEntities: Dictionary<Maybe<ComponentStateDto>>,
  updates: Dictionary<RuntimeViewProperties>
): void {
  const isVertical = (componentState.view as BasicCardViewConfig).vertical;

  if (componentState.view.size.widthType === SizeType.Hug) {
    if (canHugComponentHorizontally(componentState, reportEntities)) {
      const newWidth = calcComponentHugWidth(componentState, updates, isVertical);
      updates[componentState.id].runtimeSize.widthInPx =
        newWidth + getTotalCardPadding(componentState.childrenIds.length, !isVertical);
    }
  }

  if (componentState.view.size.heightType === SizeType.Hug) {
    if (canHugComponentVertically(componentState, reportEntities)) {
      const newHeight = calcComponentHugHeight(componentState, updates, isVertical);
      updates[componentState.id].runtimeSize.heightInPx =
        newHeight + getTotalCardPadding(componentState.childrenIds.length, isVertical);
    }
  }
}

function calculateDeepChildrenRuntimeViewProps(
  rootComponent: ComponentStateDto,
  reportEntities: Dictionary<Maybe<ComponentStateDto>>,
  rootComponentContentSize: SizeInPx,
  runtimeViewService: RuntimeViewService
): Dictionary<RuntimeViewProperties> {
  const children = rootComponent.childrenIds
    .map((childId) => reportEntities[childId])
    .filter(isDefined);

  let childrenRuntimeViewUpdatesDict = children.reduce(
    (acc: Dictionary<RuntimeViewProperties>, child) => {
      return {
        ...acc,
        ...getRuntimeViewPropsUpdate(
          reportEntities,
          child.id,
          rootComponentContentSize,
          runtimeViewService
        )
      };
    },
    {}
  );

  if (isAutoLayoutContainer(rootComponent.view)) {
    const isVertical = (rootComponent.view as BasicCardViewConfig).vertical;

    childrenRuntimeViewUpdatesDict = {
      ...childrenRuntimeViewUpdatesDict,
      ...recalcSizeForFillChildren(
        children,
        childrenRuntimeViewUpdatesDict,
        isVertical,
        rootComponentContentSize,
        runtimeViewService,
        reportEntities
      )
    };
  }
  return childrenRuntimeViewUpdatesDict;
}

function canHugComponentVertically(
  component: ComponentStateDto,
  reportEntities: Dictionary<Maybe<ComponentStateDto>>
): boolean {
  return !component.childrenIds
    .map((childId) => reportEntities[childId])
    .some(
      (child) =>
        child!.view.size.heightType === SizeType.Fill ||
        (child!.view.size.heightType === SizeType.Fixed &&
          cssSizeParser(child!.view.size.height).unit === "%")
    );
}

function canHugComponentHorizontally(
  component: ComponentStateDto,
  reportEntities: Dictionary<Maybe<ComponentStateDto>>
): boolean {
  return !component.childrenIds
    .map((childId) => reportEntities[childId])
    .some(
      (child) =>
        child!.view.size.widthType === SizeType.Fill ||
        (child!.view.size.widthType === SizeType.Fixed &&
          cssSizeParser(child!.view.size.width).unit === "%")
    );
}

function calcComponentHugWidth(
  component: ComponentStateDto,
  childrenUpdatesDict: Dictionary<RuntimeViewProperties>,
  isVertical: boolean
): number {
  const aggregation = isVertical ? _max : _sum;
  return (
    aggregation(
      component.childrenIds.map((childid) => childrenUpdatesDict[childid].runtimeSize.widthInPx)
    ) ?? 0
  );
}

function calcComponentHugHeight(
  component: ComponentStateDto,
  childrenUpdatesDict: Dictionary<RuntimeViewProperties>,
  isVertical: boolean
): number {
  const aggregation = isVertical ? _sum : _max;
  return (
    aggregation(
      component.childrenIds.map((childid) => childrenUpdatesDict[childid].runtimeSize.heightInPx)
    ) ?? 0
  );
}

function recalcSizeForFillChildren(
  children: ComponentStateDto[],
  initialUpdatesDict: Dictionary<RuntimeViewProperties>,
  isVertical: boolean,
  rootComponentContentSize: SizeInPx,
  runtimeViewService: RuntimeViewService,
  reportEntities: Dictionary<Maybe<ComponentStateDto>>
): Dictionary<RuntimeViewProperties> {
  const fillChildren = children.filter(
    (child) =>
      child.view.size.heightType === SizeType.Fill || child.view.size.widthType === SizeType.Fill
  );

  const { fixedHeightSum, fixedWidthSum } = getChildrenFixedSizeSum(children, initialUpdatesDict);
  const { heightFillFactorSum, widthFillFactorSum } = getChildrenFillFactorSum(children);

  const reportEntitiesClone = _cloneDeep(reportEntities);
  return fillChildren.reduce((acc, fillChild) => {
    if (fillChild.view.size.heightType === SizeType.Fill) {
      reportEntitiesClone[fillChild.id].view.size.height = getFillChildHeight(
        isVertical,
        fillChild,
        rootComponentContentSize,
        fixedHeightSum,
        heightFillFactorSum
      );
    }

    if (fillChild.view.size.widthType === SizeType.Fill) {
      reportEntitiesClone[fillChild.id].view.size.width = getFillChildWidth(
        isVertical,
        fillChild,
        rootComponentContentSize,
        fixedWidthSum,
        widthFillFactorSum
      );
    }

    return {
      ...acc,
      ...getRuntimeViewPropsUpdate(
        reportEntitiesClone,
        fillChild.id,
        rootComponentContentSize,
        runtimeViewService
      )
    };
  }, {});
}

function getFillChildHeight(
  isVertical: boolean,
  fillChild: ComponentStateDto,
  rootComponentContentSize: SizeInPx,
  fixedHeightSum: number,
  heightFillFactorSum: number
): string {
  if (!isVertical) {
    return "100%";
  } else {
    return (
      (((Math.max(0, rootComponentContentSize.heightInPx - fixedHeightSum) /
        rootComponentContentSize.heightInPx) *
        100) /
        heightFillFactorSum) *
        fillChild.view.size.heightFactor +
      "%"
    );
  }
}

function getFillChildWidth(
  isVertical: boolean,
  fillChild: ComponentStateDto,
  rootComponentContentSize: SizeInPx,
  fixedWidthSum: number,
  widthFillFactorSum: number
): string {
  if (isVertical) {
    return "100%";
  } else {
    return (
      (((Math.max(0, rootComponentContentSize.widthInPx - fixedWidthSum) /
        rootComponentContentSize.widthInPx) *
        100) /
        widthFillFactorSum) *
        fillChild.view.size.widthFactor +
      "%"
    );
  }
}

function getChildrenFillFactorSum(children: ComponentStateDto[]): {
  widthFillFactorSum: number;
  heightFillFactorSum: number;
} {
  return {
    heightFillFactorSum: _sum(
      children
        .filter((child) => child.view.size.heightType === SizeType.Fill)
        .map((child) => child.view.size.heightFactor)
    ),
    widthFillFactorSum: _sum(
      children
        .filter((child) => child.view.size.widthType === SizeType.Fill)
        .map((child) => child.view.size.widthFactor)
    )
  };
}

function getChildrenFixedSizeSum(
  children: ComponentStateDto[],
  updates: Dictionary<RuntimeViewProperties>
): { fixedWidthSum: number; fixedHeightSum: number } {
  return {
    fixedHeightSum: _sum(
      children
        .filter((child) => child.view.size.heightType === SizeType.Fixed)
        .map((child) => updates[child.id].runtimeSize.heightInPx)
    ),
    fixedWidthSum: _sum(
      children
        .filter((child) => child.view.size.widthType === SizeType.Fixed)
        .map((child) => updates[child.id].runtimeSize.widthInPx)
    )
  };
}

export function getTotalCardPadding(
  childrenCount: number,
  shouldIncludeInnerPadding: boolean
): number {
  return (
    getAutoLayoutTotalOuterPadding() +
    (shouldIncludeInnerPadding ? getAutoLayoutInnerPadding(childrenCount) : 0)
  );
}

export function getAutoLayoutTotalOuterPadding(): number {
  return 2 * AUTO_LAYOUT_PADDING_PX;
}

export function getAutoLayoutInnerPadding(childrenCount: number): number {
  return (childrenCount - 1) * AUTO_LAYOUT_PADDING_PX;
}
