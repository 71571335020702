import Highcharts, { AlignValue } from "highcharts";
import { mergeWith as _mergeWith } from "lodash";
import { fontSize10, fontSize14, fontSize16 } from "../../../../_typography";
import { ColorListService } from "../../../environment/services/color-list.service";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import {
  getLegendBoxHeight,
  getLegendColumnWidth
} from "../../helpers/chart-legend-column-width.helper";
import { getTextColorForNoDataStatus } from "../../helpers/color.helper";
import { YAxisDescriptor } from "../../models";
import {
  BLACK_COLOR_HEX,
  BLACK_COLOR_WITH_OPACITY,
  DEFAULT_CHART_TEXT_COLOR_HEX,
  WHITE_COLOR_WITH_OPACITY
} from "../../models/colors.constants";
import { CommonOptionsConfig } from "../../models/common-options-configuration";
import { DataStatus } from "../../models/data-status";
import { ITimeSeriesDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { isWrap } from "../scalable-font-size.helper";

export const PRIMARY_X_AXIS_ID = "primaryXaxisId";
export const SECONDARY_X_AXIS_ID = "secondaryXaxisId";
export const Y_AXIS_PREFIX = "YAxis-";
export const PRIMARY_Y_AXIS_ID = Y_AXIS_PREFIX + "primary";
export const AXIS_LABEL_FONT_SIZE = fontSize10;
export const LEGEND_ITEM_FONT_SIZE = fontSize10;
const TITLE_FONT_SIZE = fontSize16;
const SUBTITLE_FONT_SIZE = fontSize14;

export function highchartsLighterColorGenerator(baseColor: string, length: number): string[] {
  const colorObjectFactory = (Highcharts as any).Color;
  const colors: string[] = Array.from({ length }).map((x, index) => {
    // Start out with a darkened base color (negative brighten), and end
    // up with a much brighter color
    return colorObjectFactory(baseColor)
      .brighten((index - 3) / 7)
      .get();
  });
  return colors;
}

export function setTransparentChartBackground(options: Highcharts.Options): void {
  if (options?.chart != null && options.chart.backgroundColor === undefined) {
    options.chart.backgroundColor = "rgba(255, 255, 255, 0)";
  }
}

export function getCommonOptions(
  config: CommonOptionsConfig,
  colorService: ColorListService
): Highcharts.Options {
  const textColor: string = getCommonTextColor(config.foregroundColor);
  const gridLineColor = getCommonGridLineColor(config.foregroundColor);
  const opt: Highcharts.Options = {
    chart: {
      events: {},
      showAxes: true,
      animation: config.disableChartAnimations ? false : true,
      zooming: { mouseWheel: false }
    },
    lang: {
      noData: config.dataStatus ?? DataStatus.NoDataDefined
      // NOTE resetZoom is localized only when app was started, so if language jsons are not loaded then, they will not be applied...
      // FIXME use showResetZoom method on chartObject
      // resetZoom: "Reset Zoom" // this would not be applied as default was already applied...
    },
    navigation: {
      buttonOptions: {
        enabled: config.exportingEnabled
      }
    },
    noData: {
      style: { color: getTextColorForNoDataStatus(config.dataStatus, textColor) }
    },
    credits: {
      enabled: false
    },
    title: {
      text: config.title ?? undefined,
      align: (config.horizontalAlignment?.toLocaleLowerCase() as AlignValue) ?? "center",
      useHTML: true,
      style: {
        textAlign: (config.horizontalAlignment?.toLocaleLowerCase() as AlignValue) ?? "center",
        fontFamily: "ABB Font",
        fontWeight: "bold",
        color: textColor,
        whiteSpace: isWrap(config.titleFormat) ? "normal" : "no-wrap !important",
        wordBreak: isWrap(config.titleFormat) ? "break-all" : "",
        fontSize: TITLE_FONT_SIZE + "px"
      }
    },
    subtitle: {
      style: {
        fontFamily: "ABB Font",
        fontWeight: "bold",
        color: textColor,
        fontSize: SUBTITLE_FONT_SIZE + "px"
      }
    },
    xAxis: [
      {
        id: PRIMARY_X_AXIS_ID,
        labels: {
          style: { color: textColor, fontSize: `${AXIS_LABEL_FONT_SIZE}px`, fontFamily: "ABB Font" }
        },
        title: {
          style: { color: textColor, fontSize: `${AXIS_LABEL_FONT_SIZE}px` }
        },
        lineColor: gridLineColor,
        tickColor: gridLineColor,
        gridLineColor
      },
      {
        id: SECONDARY_X_AXIS_ID,
        labels: {
          style: { color: textColor, fontSize: `${AXIS_LABEL_FONT_SIZE}px` }
        },
        title: {
          style: { color: textColor, fontSize: `${AXIS_LABEL_FONT_SIZE}px` }
        },
        lineColor: gridLineColor,
        tickColor: gridLineColor,
        visible: false
      }
    ],
    yAxis: [
      {
        id: PRIMARY_Y_AXIS_ID,
        labels: {
          style: { color: textColor, fontSize: `${AXIS_LABEL_FONT_SIZE}px`, fontFamily: "ABB Font" }
        },
        title: {
          style: { color: textColor, fontSize: `${AXIS_LABEL_FONT_SIZE}px` }
        },
        lineColor: gridLineColor,
        tickColor: gridLineColor,
        gridLineColor
      }
    ],

    plotOptions: {
      series: {
        stacking: false as any,
        // FIXME: remove once updateDisplay refactor is done
        animation: config.disableChartAnimations ? false : true,
        dataLabels: {
          style: {
            color: textColor,
            fontSize: `${AXIS_LABEL_FONT_SIZE}px`
          }
        }
      }
    },
    colors: colorService.seriesColors,
    tooltip: {
      style: { zIndex: 9999 },
      outside: true
    }
  };

  if (isDefined(config.runtimeSize)) {
    opt.legend = {
      itemWidth: getLegendColumnWidth(config.runtimeSize),
      itemHoverStyle: { color: textColor },
      itemStyle: {
        textOverflow: "ellipsis",
        color: textColor,
        fontWeight: "bold",
        fontSize: `${LEGEND_ITEM_FONT_SIZE}px`
      },
      maxHeight: getLegendBoxHeight(config.runtimeSize),
      itemMarginTop: 3,
      navigation: { style: { color: textColor } }
    };
  }
  return opt;
}

export function getCommonTextColor(foregroundColor: Maybe<string>): string {
  return foregroundColor ?? DEFAULT_CHART_TEXT_COLOR_HEX;
}

export function getCommonGridLineColor(foregroundColor: Maybe<string>): string {
  if (isNotDefined(foregroundColor)) {
    return "";
  }
  return foregroundColor === BLACK_COLOR_HEX ? BLACK_COLOR_WITH_OPACITY : WHITE_COLOR_WITH_OPACITY;
}

// this method mutates base
export function mergeChartOptions(
  base: Highcharts.Options,
  specific: Highcharts.Options
): Highcharts.Options {
  const res = _mergeWith(base, specific, mergeCustomizer);
  return res;
}

function mergeCustomizer(objValue: any, srcValue: any): any {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    for (const srcItem of srcValue) {
      // IP objValue is mutated
      const corresponding = objValue.filter((x) => x.id != null && x.id === srcItem.id);
      if (corresponding.length === 0) {
        // console.log("push ", srcItem);
        objValue.push(srcItem); // new item
      } else {
        // console.log("merge ", corresponding[0], srcItem);
        _mergeWith(corresponding[0], srcItem, mergeCustomizer);
      }
    }
    return objValue;
  } else {
    return undefined; // use standard lodash merge logic
  }
}

export function getSeriesAxisOptions(
  connectorDesc: DataConnectorDescriptor,
  viewConfig: ITimeSeriesDisplayConfig
): Pick<Highcharts.SeriesLineOptions, "yAxis" | "visible" | "showInLegend"> {
  const yAxisIndex = resolveConnectorAxisIndex(connectorDesc, viewConfig.yAxes);
  const yAxisConfig = viewConfig.yAxes[yAxisIndex];
  return {
    yAxis: yAxisIndex,
    visible: !yAxisConfig.isHidden,
    showInLegend: !yAxisConfig.isHidden
  };
}

export function resolveConnectorAxisIndex(
  connectorDesc: DataConnectorDescriptor,
  yAxisDesc: YAxisDescriptor[]
): number {
  const configuredAxisId = connectorDesc.connectorView?.axisId;
  return axisIdToIndex(configuredAxisId, yAxisDesc);
}

export function axisIdToIndex(
  configuredAxisId: Maybe<string>,
  yAxisDesc: YAxisDescriptor[]
): number {
  const axisIndex = yAxisDesc.findIndex((descriptor) => descriptor.id === configuredAxisId);
  return axisIndex >= 0 ? axisIndex : 0;
}
