import { SizeInPx } from "./size-in-px";

export interface RuntimeViewProperties {
  runtimeSize: SizeInPx;
}

export interface ContainerRuntimeViewProperties extends RuntimeViewProperties {
  padding: number;
}

export interface CardRuntimeViewProperties extends ContainerRuntimeViewProperties {
  scalingFactor: number;
}

export const NEUTRAL_SCALING_FACTOR = 1;
