import { isEqual as _isEqual } from "lodash";
import { maxPropertySheetWidthInVw, minPropertySheetWidth } from "../../../style-variables";
import { DATA_CONNECTOR, DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { GenericDataSourceDto } from "../../data-connectivity/models/data-source/generic-data-source";
import { LabelViewConfig } from "../../elements/components/label/view-config";
import { StrategizedChartViewConfig } from "../../elements/components/strategized-chart/view-config";
import { ComponentStateDto } from "../../elements/models/component-state";
import { ComponentStateViewModel } from "../../elements/models/component-state.vm";
import { isLabel } from "../../elements/models/component-type.helper";
import { DEFAULT_TITLE } from "../../elements/models/default-property-value.constants";
import { isSingleLineLabel } from "../../elements/models/display-strategies/display-strategy-type.helper";
import {
  COMPONENT_STATE_VIEW_MODEL,
  DATA_CONNECTOR_VIEW_MODEL,
  GLOBAL_FILTER_VIEW_MODEL
} from "../../elements/models/entity-type.constants";
import {
  BASIC_CARD_VIEW_CONFIG,
  CATEGORY_VIEW_CONFIG,
  LABEL_VIEW_CONFIG,
  SINGLE_VALUE_VIEW_CONFIG,
  STATUS_INDICATOR_VIEW_CONFIG,
  TIME_SERIES_VIEW_CONFIG
} from "../../elements/models/view-config-type.constants";
import { EnvironmentSelector } from "../../environment/services/environment.selector";
import { LocalizationService } from "../../i18n/localization.service";
import { tryGetPropertyByPath } from "../../meta/helpers/property-extraction.helper";
import {
  CategoryConfig,
  Entity,
  ItemInfo,
  PropertyCategory,
  PropertyDescriptor,
  PropertyInfo,
  SubcategoryConfig,
  TypeDescriptor,
  TypeDescriptorAlias
} from "../../meta/models";
import { TypeProvider } from "../../meta/services/type-provider";
import {
  convertDashCaseToTitleText,
  CriticalError,
  first,
  isDefined,
  isEmptyOrNotDefined,
  isEmptyOrNotDefined2,
  isNotDefined,
  isUndefined,
  Maybe
} from "../../ts-utils";
import { resolveComponentClassName } from "../../ts-utils/helpers/component-display-name.helper";
import { ModifiedObject } from "../models";
import { shouldRenderEditor } from "../services/editable-property.helper";
import { hasDataProps, hasInteractionProps } from "./entity-props.helper";

const DEFAULT_TAB_FOR_CONNECTOR = 1;
const DEFAULT_GLOBAL_FILTER_TARGET_TITLE = "Report filter";

export function createTabDict(): CategoryConfig {
  const allCategories: PropertyCategory[] = Object.values(PropertyCategory);
  return allCategories.reduce((acc: CategoryConfig, categoryName) => {
    acc.set(categoryName, new Map<string, ItemInfo[]>());
    return acc;
  }, new Map<PropertyCategory, SubcategoryConfig>());
}

export function populateTabDict(
  tabDictionary: CategoryConfig,
  typeProvider: TypeProvider,
  targetType: TypeDescriptor,
  newTarget: Entity,
  evaluatedTarget: Maybe<Entity> = null
): CategoryConfig {
  const allTargetPropItems: PropertyInfo<unknown>[] = typeProvider.getAllPropertyItemsDeep(
    targetType,
    newTarget,
    evaluatedTarget
  );
  return allTargetPropItems.reduce((acc, propertyItem: PropertyInfo<unknown>) => {
    const descriptor = propertyItem.descriptor;
    if (shouldRenderProperty(descriptor, newTarget)) {
      let targetSection: Maybe<ItemInfo[]> = acc
        .get(descriptor.category)
        ?.get(descriptor.subCategory);
      if (isNotDefined(targetSection)) {
        targetSection = [];
      }
      targetSection.push({ propertyInfo: propertyItem, parentInfo: newTarget });
      acc.get(descriptor.category)?.set(descriptor.subCategory, targetSection);
    }
    return acc;
  }, tabDictionary);
}

export function shouldRenderProperty(descriptor: PropertyDescriptor, newTarget: Entity): boolean {
  const shouldSkipDataProperty =
    descriptor.category === PropertyCategory.Data && !hasDataProps(newTarget);
  const shouldSkipInteractionProperty =
    descriptor.category === PropertyCategory.Interaction && !hasInteractionProps(newTarget);
  return (
    shouldRenderEditor(descriptor) && !shouldSkipDataProperty && !shouldSkipInteractionProperty
  );
}

export function getOriginalTarget(entity: ModifiedObject<Entity>): Entity {
  if (!isDefined(entity) || isUndefined(entity.value)) {
    throw new CriticalError("Object to be edited is undefined");
  }
  return entity.value;
}

export function getTargetType(entity: ModifiedObject<Entity>): TypeDescriptor {
  if (!isDefined(entity) || isUndefined(entity.type)) {
    throw new CriticalError("Undefined type descriptor for the target");
  }
  return entity.type;
}

export function resolveTargetTitle(
  originalTarget: any,
  targetType: TypeDescriptor,
  interpolatedTitle?: Maybe<string>
): string {
  switch (targetType.name) {
    case COMPONENT_STATE_VIEW_MODEL:
      return getReadableComponentTitle(originalTarget as ComponentStateDto, interpolatedTitle);
    case DATA_CONNECTOR_VIEW_MODEL:
      return getReadableDataConnectorTitle(originalTarget as DataConnectorDto, interpolatedTitle);
    case GLOBAL_FILTER_VIEW_MODEL:
      return DEFAULT_GLOBAL_FILTER_TARGET_TITLE;
    default:
      return getReadableClassTitle(targetType);
  }
}

export function resolveActiveTabIndex(originalTarget: Entity, targetType: TypeDescriptor): number {
  switch (targetType.name) {
    case COMPONENT_STATE_VIEW_MODEL:
      return (originalTarget as ComponentStateDto).activeTabIndex;
    case DATA_CONNECTOR_VIEW_MODEL:
      return DEFAULT_TAB_FOR_CONNECTOR;
    default:
      return 0;
  }
}

export function resolveTargetSubtitle(
  typeProvider: TypeProvider,
  targetType: TypeDescriptor,
  originalTarget: Entity,
  localizer: LocalizationService,
  environmentSelector: EnvironmentSelector
): string {
  if (targetType.name !== COMPONENT_STATE_VIEW_MODEL) {
    return "";
  }
  const displayStrategy: Maybe<string> = getComponentDisplayStrategy(
    originalTarget as ComponentStateDto
  );
  if (isEmptyOrNotDefined(displayStrategy)) {
    return getComponentDisplayName(typeProvider, originalTarget, environmentSelector) ?? "";
  }
  return localizer.propertySheet[displayStrategy];
}

function getComponentDisplayStrategy(component: ComponentStateDto): Maybe<string> {
  const { typeName } = component.view;
  if (
    typeName === TIME_SERIES_VIEW_CONFIG ||
    typeName === SINGLE_VALUE_VIEW_CONFIG ||
    typeName === CATEGORY_VIEW_CONFIG ||
    typeName === LABEL_VIEW_CONFIG ||
    typeName === STATUS_INDICATOR_VIEW_CONFIG ||
    typeName === BASIC_CARD_VIEW_CONFIG
  ) {
    return "Display_" + (component.view as StrategizedChartViewConfig).displayStrategy;
  }
}

export function getComponentDisplayName(
  typeProvider: TypeProvider,
  originalTarget: any,
  environmentSelector: EnvironmentSelector
): Maybe<string> {
  const targetDisplayName: Maybe<string> = resolveTargetDisplayName(typeProvider, originalTarget);
  if (isDefined(targetDisplayName)) {
    return resolveComponentClassName(targetDisplayName, environmentSelector);
  }
}

function resolveTargetDisplayName(typeProvider: TypeProvider, originalTarget: any): Maybe<string> {
  const typeDescriptor: TypeDescriptor = typeProvider.getType(
    (originalTarget as ComponentStateViewModel).type
  );

  if (isEmptyOrNotDefined(originalTarget.view.displayStrategy)) {
    return first(typeDescriptor.aliases)?.displayName;
  }

  return (
    typeDescriptor.aliases.find(
      (alias: TypeDescriptorAlias) => alias.name === originalTarget.view.displayStrategy
    )?.displayName ?? ""
  );
}

export function getReadableComponentTitle(
  componentState: ComponentStateDto,
  interpolatedTitle: Maybe<string>
): string {
  let componentTitle = interpolatedTitle ?? componentState.view.title;

  if (isLabel(componentState.type)) {
    componentTitle = getTitleForLabel(componentState.view as LabelViewConfig, interpolatedTitle);
  }
  if (
    isEmptyOrNotDefined(componentTitle) ||
    componentTitle === DEFAULT_TITLE ||
    componentTitle === " "
  ) {
    return convertDashCaseToTitleText(componentState.id.toString());
  }
  return componentTitle;
}

function getTitleForLabel(viewConfig: LabelViewConfig, interpolatedTitle: Maybe<string>): string {
  return isSingleLineLabel(viewConfig.displayStrategy)
    ? interpolatedTitle ?? viewConfig.title
    : viewConfig.htmlText;
}

export function getReadableDataConnectorTitle(
  dataConnector: DataConnectorDto,
  interpolatedTitle: Maybe<string>
): string {
  const dataConnectorTitle = interpolatedTitle ?? dataConnector.title;
  return isEmptyOrNotDefined2(dataConnectorTitle) || dataConnectorTitle === " "
    ? DATA_CONNECTOR
    : dataConnectorTitle;
}

export function getReadableClassTitle(typeDescriptor: TypeDescriptor): string {
  return convertDashCaseToTitleText(typeDescriptor.titleProperty);
}

export function getTargetSubcategoryProperties(
  targetType: TypeDescriptor,
  newTarget: Entity,
  typeProvider: TypeProvider,
  propertyCategory: PropertyCategory,
  propertySubCategory: string,
  evaluatedTarget: Entity
): ItemInfo[] {
  const allPropertyInfos = typeProvider.getAllPropertyItemsDeep(
    targetType,
    newTarget,
    evaluatedTarget
  );
  const visiblePropertyInfos = allPropertyInfos.filter((prop) =>
    shouldRenderEditor(prop.descriptor)
  );
  return typeProvider
    .filterPropertyItems(visiblePropertyInfos, propertyCategory, propertySubCategory)
    .map((propertyInfo) => ({
      propertyInfo,
      parentInfo: newTarget
    }));
}

export function shouldUpdateProperty(
  previousTarget: Entity,
  newTarget: Entity,
  propertyInfo: PropertyInfo<unknown>
): boolean {
  const previousValue = tryGetPropertyByPath(previousTarget, propertyInfo.originalPath);
  const newValue = tryGetPropertyByPath(newTarget, propertyInfo.originalPath);
  return !_isEqual(previousValue, newValue);
}

export function hasDataSourceChanged(
  oldDataSource: DataSourceDto,
  newDataSource: DataSourceDto
): boolean {
  // FIXME Use typeName instead of instanceof
  return (
    oldDataSource instanceof GenericDataSourceDto &&
    newDataSource instanceof GenericDataSourceDto &&
    oldDataSource.entity !== newDataSource.entity
  );
}

export function limitWidthAfterResizing(width: number): number {
  const maxPropertySheetWidth = (window.innerWidth * maxPropertySheetWidthInVw) / 100;
  if (width < minPropertySheetWidth) {
    width = minPropertySheetWidth;
  } else if (width > maxPropertySheetWidth) {
    width = maxPropertySheetWidth;
  }
  return width;
}

export function shouldResetActiveTab(properties: CategoryConfig, activeTabIndex: number): boolean {
  const activeCategory = getActiveCategory(properties, activeTabIndex);
  const propsByCategory = properties.get(activeCategory);
  return isNotDefined(propsByCategory) || propsByCategory.size < 1;
}

export function getActiveCategory(
  properties: CategoryConfig,
  activeTabIndex: number
): PropertyCategory {
  const tabNames = getCategoriesTitle(properties);
  return tabNames[activeTabIndex];
}

export function getCategoriesTitle(properties: CategoryConfig): PropertyCategory[] {
  return Array.from(properties.keys());
}
