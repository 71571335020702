import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { isUndefined } from "lodash";
import { Observable, combineLatest, iif, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import {
  Dispatcher,
  EnvironmentSelector,
  LocalizationService,
  RuntimeSettingsSelector,
  ViewMode,
  WebServicesConfiguration
} from "ui-core";
import { SidemenuComponents } from "../../../../../ui-core/src/lib/core/models/sidemenu-components.enum";
import { SidebarService } from "../../../../../ui-core/src/lib/elements/services/sidebar.service";
import { BuilderHeaderButtonConfig } from "../../../../../ui-core/src/lib/shared/models/button/builder-header-button.config";
import { HeaderButtonsForSidebarGenerator } from "../../../../../ui-core/src/lib/shared/services/header-buttons-for-sidebar-generator-service";
import { ModuleDTO } from "../../dtos";
import { FLEET_VIEW_REPORT_ID } from "../../models/constants/predefined-reports";

@Injectable()
export class RdsHeaderButtonsForSidebarGenerator extends HeaderButtonsForSidebarGenerator {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiConfig: WebServicesConfiguration,
    protected environmentSelector: EnvironmentSelector,
    protected runtimeSettingsSelector: RuntimeSettingsSelector,
    protected dispatcher: Dispatcher,
    protected sidebarService: SidebarService,
    protected localizer: LocalizationService
  ) {
    super(sidebarService, dispatcher, environmentSelector, localizer);
  }

  private readonly previewModeCommonHeaderButtonItems: BuilderHeaderButtonConfig[] = [
    new BuilderHeaderButtonConfig({
      title: "",
      icon: "abb-icon Map",
      sidebarDisplay: SidemenuComponents.MapExplorer,
      clickEventFunction: () => this.router.navigateByUrl(`/report/${FLEET_VIEW_REPORT_ID}`)
    }),
    new BuilderHeaderButtonConfig({
      title: "",
      icon: "abb-icon Tree_view",
      sidebarDisplay: SidemenuComponents.TreeExplorer,
      clickFunction: () => this.toggleButtonClick(SidemenuComponents.TreeExplorer)
    })
  ];

  private readonly editModeCommonHeaderButtonItems: BuilderHeaderButtonConfig[] = [
    new BuilderHeaderButtonConfig({
      title: "",
      icon: "abb-icon Database",
      sidebarDisplay: SidemenuComponents.DataExplorer,
      clickFunction: () => this.toggleButtonClick(SidemenuComponents.DataExplorer)
    })
  ];

  private getCommonExplorerHeaderButtonsItems(viewMode: ViewMode): BuilderHeaderButtonConfig[] {
    switch (viewMode) {
      case ViewMode.PreviewMode:
        return this.previewModeCommonHeaderButtonItems;
      case ViewMode.EditMode:
        return this.editModeCommonHeaderButtonItems;
      default:
        throw new Error(`View mode ${viewMode} not supported!`);
    }
  }

  private getExclusiveExplorerHeaderButtonItems$(
    items: BuilderHeaderButtonConfig[],
    viewMode: ViewMode,
    rootPath: string
  ): Observable<BuilderHeaderButtonConfig[]> {
    const customer = rootPath?.split("/")[0];

    const isCustomerSelected = !isUndefined(customer) && customer !== "";
    const shouldShowModuleIcons = (): boolean =>
      isCustomerSelected && viewMode === ViewMode.PreviewMode;

    const $items = of(items);
    const $modules = this.http
      .get<ModuleDTO[]>(`${this.apiConfig.itemsServiceUrl}/access-management/modules/${customer}`)
      .pipe(
        map((modules: ModuleDTO[]) => {
          let result: BuilderHeaderButtonConfig[] = [];

          modules.map((module: ModuleDTO) => {
            result.push(
              new BuilderHeaderButtonConfig({
                title: "",
                icon: `abb-icon ${module.name}`,
                sidebarDisplay: module.name as SidemenuComponents,
                clickEventFunction: () =>
                  window.open(this.getModuleLink(module.name, customer), "_blank")
              })
            );
          });

          return items.concat(result);
        })
      );

    return iif(shouldShowModuleIcons, $modules, $items);
  }

  generateExplorerHeaderButtonItems$(): Observable<BuilderHeaderButtonConfig[]> {
    const viewMode$ = this.environmentSelector.selectViewMode() as Observable<ViewMode>;
    const rootPath$ = this.runtimeSettingsSelector.selectCurrentRootPath() as Observable<string>;

    return combineLatest([viewMode$, rootPath$]).pipe(
      map(([viewMode, rootPath]) => {
        return {
          commonItems: this.getCommonExplorerHeaderButtonsItems(viewMode),
          viewMode,
          rootPath
        };
      }),
      switchMap(({ commonItems, viewMode, rootPath }) =>
        this.getExclusiveExplorerHeaderButtonItems$(commonItems, viewMode, rootPath)
      )
    );
  }

  private getModuleLink(moduleName: string, customer: string | undefined): string {
    switch (moduleName) {
      case SidemenuComponents.Copilot:
        return `${window.location.origin}/copilot/customer/${customer}`;
      case SidemenuComponents.Trendex:
        return `${window.location.origin}/trendex`;
      default:
        return "";
    }
  }
}

