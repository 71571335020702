import { LegendOptions, Options, PointOptionsObject, SeriesOptionsType } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { Maybe, isEmptyOrNotDefined } from "../../../ts-utils";
import { getTextColorForNoDataStatus } from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { DataStatus } from "../../models";
import { DEFAULT_CHART_TEXT_COLOR_HEX } from "../../models/colors.constants";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { SizeInPx } from "../../models/size-in-px";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";
import { DialGaugeDisplayService } from "./dial-gauge-display.service";

export class MultiDialGaugeDisplayService extends DialGaugeDisplayService {
  protected colorListService: ColorListService;
  constructor(protected valueFormatter: ValueFormatterService, colorListService: ColorListService) {
    super(valueFormatter, colorListService);
    this.chartType = "gauge";
    this.isTargetAsAxis = true;
    this.colorListService = colorListService;
  }

  protected getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    let series: SeriesOptionsType[] = [];
    let legend: LegendOptions = {};

    if (dataConnectorDescriptors && dataConnectorDescriptors.length > 0) {
      series = dataConnectorDescriptors.map((dataConnectorDescriptor, index) => {
        const connector = dataConnectorDescriptor?.connector;
        const value = getSingleValueFromConnector(connector);
        const seriesColor = this.getSeriesColorFromConnectorDescriptor(
          dataConnectorDescriptor,
          index
        );
        return {
          id: `value_gauge_${connector?.id}`,
          type: "gauge",
          name: connector?.title,
          data: [value],
          showInLegend: true,
          color: seriesColor,
          dial: {
            backgroundColor: seriesColor
          }
        };
      });
      legend = {
        layout: "vertical",
        align: "center",
        verticalAlign: "bottom",
        enabled: viewConfig.showLegend,
        useHTML: true,
        labelFormatter: function () {
          const name = this.name !== "" ? this.name : "-";
          return `<span style="color:${
            (this.options as PointOptionsObject).color
          }">&nbsp${name}</span>`;
        },
        symbolWidth: 0,
        itemStyle: {
          textAlign: "center"
        }
      };
    }

    const gaugeOptions: Options = {
      noData: {
        style: {
          color: getTextColorForNoDataStatus(
            dataStatus,
            viewConfig.foregroundColor ?? DEFAULT_CHART_TEXT_COLOR_HEX
          )
        }
      },
      plotOptions: {
        gauge: {
          wrap: false,
          dataLabels: {
            borderWidth: 0,
            enabled: false
          }
        }
      },
      pane: {
        startAngle: -140,
        endAngle: 140,
        background: [
          {
            borderWidth: 0,
            outerRadius: "105%",
            innerRadius: "103%"
          }
        ]
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          labels: this.getYAxisLabels(viewConfig),

          minorTicks: this.shouldEnableMinorTicks(viewConfig.runtimeView.runtimeSize),
          minorTickInterval: "auto",
          minorTickWidth: 1,
          minorTickLength: 10,
          minorTickPosition: "inside",
          minorTickColor: "#000",

          tickPixelInterval: 30,
          tickWidth: 2,
          tickPosition: "inside",
          tickLength: 12,
          tickColor: "#000",
          plotBands: this.getPlotBands(viewConfig)
        },
        {
          linkedTo: 0,
          tickLength: 0,
          minorTickLength: 0,
          tickPositions: this.getTickPositions(viewConfig),
          labels: this.getLimitsLabels(viewConfig)
        }
      ],
      series: series,
      legend: legend
    };
    return gaugeOptions;
  }

  shouldEnableMinorTicks(widgetSize: SizeInPx): boolean {
    const minorTickSizeThreshold = 225;
    const { heightInPx, widthInPx } = widgetSize;
    return widthInPx > minorTickSizeThreshold && heightInPx > minorTickSizeThreshold;
  }

  getSeriesColorFromConnectorDescriptor(
    dataConnectorDescriptor: Maybe<DataConnectorDescriptor>,
    dataConnectorDescriptorIndex: number
  ): string {
    const isConnectorColorNotSet = isEmptyOrNotDefined(
      dataConnectorDescriptor?.connectorView?.color
    );
    const seriesColor: string = isConnectorColorNotSet
      ? this.colorListService.getSeriesColorAtIndex(dataConnectorDescriptorIndex)
      : (dataConnectorDescriptor?.connectorView?.color as string);

    return seriesColor;
  }
}
