import { isEqual as _isEqual } from "lodash";
import { DataConnectorDto } from "../../data-connectivity";
import { ConnectorContextService } from "../../data-connectivity/services/connector-context.service";
import { isDefined, Maybe } from "../../ts-utils";
import { StrategizedChartViewConfig } from "../components/strategized-chart/view-config";
import { ComponentStateDto } from "../models/component-state";
import { ReportEntities } from "../models/report-entities";
import { RuntimeViewProperties } from "../models/runtime-view-properties";
import { SizeInPx } from "../models/size-in-px";
import { hasDynamicDataPoints } from "./dynamic-data-points.helper";

export function getReportConnectorsWithContext(
  reportEntities: ReportEntities,
  contextService: ConnectorContextService
): DataConnectorDto[] {
  return reportEntities.componentStates.reduce((acc, componentState) => {
    const componentConnectors: DataConnectorDto[] = componentState.dataConnectorIds
      .map((connectorId) =>
        reportEntities.dataConnectors.find((connector) => connector.id === connectorId)
      )
      .filter(isDefined);
    const connectorsWithContext = getConnectorsWithContext(
      componentConnectors,
      componentState,
      contextService
    );
    acc = acc.concat(connectorsWithContext);
    return acc;
  }, [] as DataConnectorDto[]);
}

export function getConnectorsWithContext(
  connectors: DataConnectorDto[],
  ownerComponent: ComponentStateDto,
  contextService: ConnectorContextService
): DataConnectorDto[] {
  const connectorsWithContext = connectors.map((connector: DataConnectorDto) =>
    getConnectorWithContext(connector, ownerComponent, contextService)
  );
  return connectorsWithContext;
}

export function getConnectorWithContext(
  connector: DataConnectorDto,
  ownerComponent: ComponentStateDto,
  contextService: ConnectorContextService
): DataConnectorDto {
  const strategy: string | undefined = (ownerComponent.view as StrategizedChartViewConfig)
    .displayStrategy;
  const size: SizeInPx = ownerComponent.view.runtimeView.runtimeSize;
  const connectorWithContext: DataConnectorDto = {
    ...connector,
    numberOfRequestedDataPoints: contextService.getNumberOfDataPointsToRequest(
      ownerComponent.type,
      strategy,
      size
    )
  };
  return connectorWithContext;
}

export function shouldUpdateConnectorsContext(
  component: Maybe<ComponentStateDto>,
  runtimeViewProperties: RuntimeViewProperties
): boolean {
  return (
    isDefined(component) &&
    hasDynamicDataPoints(component.type) &&
    !_isEqual(component.view.runtimeView, runtimeViewProperties)
  );
}
