import { CSSObject, YAxisLabelsOptions } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ITextualValueViewConfig } from "../../components/simple-components/textual-value/i-textual-value-view-config";
import { getLimitOrPrimaryColor, getTextColorForSingleValue } from "../../helpers/color.helper";
import { isOnlyLimitColorsMode } from "../../helpers/limit-modes.helper";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { AXIS_LABEL_FONT_SIZE } from "./base-highcharts-options.helper";

export function getDataLabelColor(
  value: Maybe<number>,
  viewConfig: ITextualValueViewConfig | IGaugeDisplayConfig,
  colorListService: ColorListService
): string {
  return isOnlyLimitColorsMode(viewConfig.limitsMode)
    ? getTextColorForSingleValue(value, viewConfig)
    : getPrimaryColor(viewConfig, colorListService);
}

export function getBarColor(
  value: number,
  viewConfig: IGaugeDisplayConfig,
  colorListService: ColorListService
): string {
  const primaryColor = getPrimaryColor(viewConfig, colorListService);
  return isOnlyLimitColorsMode(viewConfig.limitsMode)
    ? getLimitOrPrimaryColor(value, viewConfig.limits, primaryColor)
    : primaryColor;
}

export function getYAxisLabelsOptions(
  viewConfig: IGaugeDisplayConfig,
  shouldEnableLabels: boolean | undefined,
  valueFormatter: ValueFormatterService
): YAxisLabelsOptions {
  const { displayFormat, foregroundColor } = viewConfig;
  return {
    enabled: shouldEnableLabels,
    distance: AXIS_LABEL_FONT_SIZE,
    useHTML: true,
    /** Cannot be and arrow fn, we need to preserve 'this' binding */
    formatter: function () {
      return valueFormatter.formatValue(this.value, displayFormat);
    },
    style: getYAxisLabelStyle(foregroundColor)
  };
}

export function getMinMaxLabelOptions(
  viewConfig: IGaugeDisplayConfig,
  valueFormatter: ValueFormatterService
): YAxisLabelsOptions {
  const { displayFormat, foregroundColor } = viewConfig;
  return {
    enabled: true,
    distance: AXIS_LABEL_FONT_SIZE,
    useHTML: true,
    /** Cannot be and arrow fn, we need to preserve 'this' binding */
    formatter: function () {
      if (this.isFirst || this.isLast) return valueFormatter.formatValue(this.value, displayFormat);
    },
    style: getYAxisLabelStyle(foregroundColor)
  };
}

export function getHorizontalGaugePlotLineLabel(
  value: number,
  viewConfig: IGaugeDisplayConfig,
  valueFormatter: ValueFormatterService
) {
  return {
    text: value,
    align: "center",
    rotation: 0,
    x: 0,
    y: -5,
    formatter: () => valueFormatter.formatValue(value, viewConfig.displayFormat),
    style: getYAxisLabelStyle(viewConfig.foregroundColor)
  };
}

export function getYAxisLabelStyle(foregroundColor: string): CSSObject {
  return {
    fontSize: AXIS_LABEL_FONT_SIZE + "px",
    fontFamily: "ABB Font",
    fontWeight: "normal",
    border: "0px",
    color: foregroundColor
  };
}

export function getPrimaryColor(
  viewConfig: ITextualValueViewConfig | IGaugeDisplayConfig,
  colorListService: ColorListService
): string {
  return isDefined(viewConfig) && !isEmptyOrNotDefined(viewConfig.primaryColor)
    ? viewConfig.primaryColor
    : colorListService.getFirstColor();
}
