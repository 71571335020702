import {
  BaseViewConfigDto,
  ComponentCssSize,
  Configurable,
  construct,
  DeepPartial,
  EditableType,
  EditorType,
  Serializable
} from "ui-core";
import { RDS_LOCALIZATION_DICTIONARY } from "../../../assets/i18n/models/rds-localization-dictionary";

const TYPE_NAME = "VibrationChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class VibrationChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.HighestColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#FFCD38")
  highestColor!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.HigherColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#76D053")
  higherColor!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.MediumColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#20918B")
  mediumColor!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.LowerColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#3D4C8A")
  lowerColor!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.LowestColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#17085C")
  lowestColor!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.NumberOfPoles,
    editorType: EditorType.Number
  })
  @Serializable(true)
  numberOfPoles!: number;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.XPrecision,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  xPrecision!: number;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.YPrecision,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  yPrecision!: number;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.PolePass,
    advancedMode: true,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#0E8EAC")
  polePass1!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.PolePass3,
    advancedMode: true,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#FF8413")
  polePass2!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.PolePass6,
    advancedMode: true,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#228B22")
  polePass3!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.PolePass9,
    advancedMode: true,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#D2042D")
  polePass4!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.PolePass12,
    advancedMode: true,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#9370DB")
  polePass5!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.PolePass15,
    advancedMode: true,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#834333")
  polePass6!: string;

  @Configurable({
    displayName: RDS_LOCALIZATION_DICTIONARY.propertySheet.PolePassThickness,
    advancedMode: true,
    editorType: EditorType.Number
  })
  @Serializable(3)
  polePassThickness!: number;

  constructor(viewConfigDto: DeepPartial<VibrationChartViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("1760", "665");
}

