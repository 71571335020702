import { Action } from "@ngrx/store";
import * as unitsCss from "units-css";
import { SizeMeasurement } from "../../core/models/size-measurement";
import { EntityId } from "../../meta";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { RectangleDto } from "../models";
import { ComponentCssSize, SizeType } from "../models/component-size";
import {
  cssSizeParser,
  FULL_HEIGHT_CSS_SIZE,
  FULL_WIDTH_CSS_SIZE,
  PX_AUTOFILL_KEY
} from "../models/component-size.constants";
import { SizeInPx } from "../models/size-in-px";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { UNIT_PERCENTAGE, UNIT_PX } from "./column-width-validation.helper";

const PIXELS_PER_DATA_POINT: number = 5;
const MAX_NUMBER_OF_DATA_POINTS = 500;

export const FULL_HEIGHT_PAGE_CSS = {
  height: "auto",
  minHeight: "100%"
};

export function isFullWidth(size: ComponentCssSize): boolean {
  return size.width === FULL_WIDTH_CSS_SIZE;
}

export function isFullHeight(size: ComponentCssSize): boolean {
  return size.height === FULL_HEIGHT_CSS_SIZE;
}

export function calculateNumberOfPointsByLengthInPix(length: number): number {
  return Math.min(Math.round(length / PIXELS_PER_DATA_POINT), MAX_NUMBER_OF_DATA_POINTS);
}

export function convertCssSizeToPx(cssSize: ComponentCssSize, availableSpace: SizeInPx): SizeInPx {
  let width: number = 0;
  let height: number = 0;

  const parsedHeight: SizeMeasurement = cssSizeParser(cssSize.height);
  const parsedWidth: SizeMeasurement = cssSizeParser(cssSize.width);

  if (parsedHeight.unit === UNIT_PERCENTAGE) {
    height = (parsedHeight.value / 100) * availableSpace.heightInPx;
  } else {
    height = unitsCss.convert(UNIT_PX, cssSize.height, undefined, PX_AUTOFILL_KEY);
  }

  if (parsedWidth.unit === UNIT_PERCENTAGE) {
    width = (parsedWidth.value / 100) * availableSpace.widthInPx;
  } else {
    width = unitsCss.convert(UNIT_PX, cssSize.width, undefined, PX_AUTOFILL_KEY);
  }
  return { widthInPx: width, heightInPx: height };
}

export function calculateNewComponentSize(
  componentSize: ComponentCssSize,
  newComponentRect: RectangleDto,
  parentContentSize: SizeInPx,
  oldRuntimeSize: SizeInPx
): ComponentCssSize {
  const width = Math.floor(newComponentRect.width);
  const height = Math.floor(newComponentRect.height);

  const newSize = new ComponentCssSize(
    preserveDimensionUnit(width, componentSize.width, parentContentSize.widthInPx),
    preserveDimensionUnit(height, componentSize.height, parentContentSize.heightInPx)
  );

  if (oldRuntimeSize.widthInPx !== width) {
    newSize.widthType = SizeType.Fixed;
  }
  if (oldRuntimeSize.heightInPx !== height) {
    newSize.heightType = SizeType.Fixed;
  }
  return newSize;
}

function preserveDimensionUnit(
  newDimension: number,
  oldDimension: string,
  parentDimension: number
): string {
  const originalUnit = cssSizeParser(oldDimension).unit;
  if (originalUnit === UNIT_PERCENTAGE) {
    return ((100 * newDimension) / parentDimension).toFixed(2).toString() + UNIT_PERCENTAGE;
  } else {
    return (
      unitsCss
        .convert(originalUnit, newDimension, undefined, PX_AUTOFILL_KEY)
        .toFixed(2)
        .toString() + originalUnit
    );
  }
}

export function getSizeUpdateAction(
  sizeUpdate: DeepPartial<ComponentCssSize>,
  componentId: EntityId
): Action {
  return ComponentStateActions.updateComponentSize({
    componentId,
    newSize: sizeUpdate
  });
}
