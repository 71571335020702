import { Injectable } from "@angular/core";
import { Action } from "@ngrx/store";
import { Subject } from "rxjs";
import { PositionDto } from "../../core/models/position";
import { getConnectorDtoViewId } from "../../data-connectivity/helpers/data-connector-view.helper";
import { EntityId } from "../../meta/models/entity";
import { first, isEmpty } from "../../ts-utils";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { TabGroupComponent } from "../components/tab-group/tab-group.component";
import { clearDataPoints } from "../helpers/connectors.helper";
import { resolveActionToUpdateOrder } from "../helpers/widget-dragging.helper";
import { Clipboard } from "../models/clipboard";
import { ClipboardContext } from "../models/clipboard-context";
import { ComponentStateDto } from "../models/component-state";
import { ComponentStateViewModel } from "../models/component-state.vm";
import { isBasicCard, isTabContent, isTabGroup } from "../models/component-type.helper";
import { CssPosition } from "../models/css-position";
import { FullComponentStateVM } from "../models/full-component-state-vm";
import { MultiplePasteEntities } from "../models/multiple-paste-entities";
import { PasteEntities } from "../models/paste-entities";
import { PositioningType } from "../models/positioning-type";
import { ReportEntities } from "../models/report-entities";
import { SizeInPx } from "../models/size-in-px";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { CloningService, mergeReportEntities } from "./cloning.service";
import { findSelectableParent } from "./component-selection.service";
import { ComponentStateViewModelDeserializer } from "./deserializers/component-state-vm.deserializer";
import { DomMapper } from "./dom-mapper.service";
import { fromPx } from "./drop.helper";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "./entity-selectors/data-connector-view.selector";
import { FilterSelector } from "./entity-selectors/filter.selector";
import { ComponentStateViewModelSerializer } from "./serializers/component-state-vm.serializer";

const CLONE_OFFSET: number = 20;

@Injectable({ providedIn: "root" })
export class ClipboardService {
  private _clipboardData: Maybe<Clipboard>;
  public clipboardDataChanged$: Subject<Maybe<Clipboard>> = new Subject();
  private broadcastChannel: BroadcastChannel;

  constructor(
    private dataConnectorViewSelector: DataConnectorViewSelector,
    private componentStateVMDeserializer: ComponentStateViewModelDeserializer,
    private componentStateVMSerializer: ComponentStateViewModelSerializer,
    private filterSelector: FilterSelector,
    private cloningService: CloningService,
    private componentStateSelector: ComponentStateSelector
  ) {
    this.broadcastChannel = new BroadcastChannel("clipboard_channel");
    this.broadcastChannel.onmessage = (event) => {
      this.setInternalClipboard(event.data, true);
    };
  }

  set clipboardData(value: Maybe<Clipboard>) {
    this.setInternalClipboard(value);
    this.broadcastChannel.postMessage(value);
  }

  get clipboardData(): Maybe<Clipboard> {
    return this._clipboardData;
  }

  private setInternalClipboard(
    clipboard: Maybe<Clipboard>,
    isClipboardCopied: boolean = false
  ): void {
    if (isClipboardCopied && isDefined(clipboard)) {
      clipboard.context = {
        isCut: false,
        consecutivePastesDict: {},
        sourceContainerId: null,
        wasInitiallyCut: false
      };
    }
    this._clipboardData = clipboard;
    this.clipboardDataChanged$.next(clipboard);
  }

  public initCopy(components: ComponentStateDto[]): Clipboard {
    return this.createClipboardContent(components, false);
  }

  public initCut(components: ComponentStateDto[]): Clipboard {
    return this.createClipboardContent(components, true);
  }

  public initPaste(clipboardContent: Clipboard): PasteEntities[] {
    return clipboardContent.context.isCut
      ? clipboardContent.content.map((vm) => this.convertToPasteEntities(vm))
      : clipboardContent.content.map((vm, index) => {
          return this.cloningService.cloneComponentTree(vm, index !== 0);
        });
  }

  private createClipboardContent(components: ComponentStateDto[], isCut: boolean): Clipboard {
    return {
      content: components.map((component) => this.createFullVMWithoutData(component)),
      context: {
        isCut,
        sourceContainerId: this.componentStateSelector.getParent(components[0].id)?.id,
        consecutivePastesDict: {},
        wasInitiallyCut: isCut
      }
    };
  }

  private createFullVMWithoutData(root: ComponentStateDto): FullComponentStateVM {
    let rootVM = this.componentStateVMDeserializer.convert(root);
    rootVM = this.clearDataPointsAndEmptyFilters(rootVM);
    return this.appendDCViews(rootVM);
  }

  private clearDataPointsAndEmptyFilters(
    component: ComponentStateViewModel
  ): ComponentStateViewModel {
    const connectors = component.dataConnectors.map((connector) => ({ ...connector }));
    component.dataConnectors = clearDataPoints(connectors);
    const componentHasFilter = isDefined(this.filterSelector.getById(component.filterConfig.id));
    component.filterConfig = componentHasFilter ? component.filterConfig : null;

    component.children.forEach((childComponent) =>
      this.clearDataPointsAndEmptyFilters(childComponent)
    );
    return component;
  }

  private appendDCViews(component: ComponentStateViewModel): FullComponentStateVM {
    return {
      ...component,
      children: component.children.map((dc) => this.appendDCViews(dc)),
      allDescendants: component.allDescendants,
      dataConnectorViews: this.dataConnectorViewSelector.getManyByIdAsArray(
        component.dataConnectors.map((dc) => getConnectorDtoViewId(dc))
      )
    };
  }

  private convertToPasteEntities(component: FullComponentStateVM): PasteEntities {
    const nestedEntities: ReportEntities = component.children.reduce(
      (entitiesAcc: ReportEntities, childEntities: FullComponentStateVM) => {
        return mergeReportEntities(
          entitiesAcc,
          this.convertToPasteEntities(childEntities).entities
        );
      },
      {
        componentStates: [],
        dataConnectors: [],
        filters: [],
        dataConnectorViews: []
      }
    );
    return {
      entityId: component.id,
      entities: this.mergeNestedEntitiesWithComponentEntities(component, nestedEntities),
      mappings: {}
    };
  }

  private mergeNestedEntitiesWithComponentEntities(
    component: FullComponentStateVM,
    nestedEntities: ReportEntities
  ): ReportEntities {
    return {
      componentStates: [this.componentStateVMSerializer.convert(component)].concat(
        nestedEntities.componentStates
      ),
      dataConnectors: [...component.dataConnectors].concat(nestedEntities.dataConnectors),
      dataConnectorViews: [...component.dataConnectorViews].concat(
        nestedEntities.dataConnectorViews
      ),
      filters: isDefined(component.filterConfig)
        ? [component.filterConfig].concat(nestedEntities.filters)
        : nestedEntities.filters
    };
  }

  isBasicCardInClipboard(): boolean {
    return (
      isDefined(this.clipboardData) &&
      !isEmpty(this.clipboardData.content) &&
      isBasicCard(first(this.clipboardData.content)?.type ?? "")
    );
  }
}

export function getPasteContainerId(
  pasteDestination: ComponentStateDto,
  isWidgetPasted: boolean,
  componentStateSelector: ComponentStateSelector
): Maybe<EntityId> {
  let pasteContainer = getSelectableContainerOrRoot(
    isWidgetPasted,
    pasteDestination,
    componentStateSelector
  );

  if (isNotDefined(pasteContainer)) {
    return;
  }
  const firstChild = componentStateSelector.getChildrenAsArray(pasteContainer.id)[0];
  if (isDefined(firstChild) && isTabGroup(firstChild.type)) {
    const tabGroupComponentRef = (
      document.getElementById(DomMapper.getHostId(firstChild.id)) as any
    ).angularComponentRef as TabGroupComponent;

    return tabGroupComponentRef.getActiveTabContentId();
  } else {
    return pasteContainer.id;
  }
}

function getSelectableContainerOrRoot(
  isWidgetPasted: boolean,
  pasteDestination: ComponentStateDto,
  componentStateSelector: ComponentStateSelector
): Maybe<ComponentStateDto> {
  if (isWidgetPasted) {
    return isBasicCard(pasteDestination.type)
      ? pasteDestination
      : findSelectableParent(pasteDestination.id, componentStateSelector);
  } else {
    return componentStateSelector.getRoot();
  }
}

export function offsetPastedComponents<T extends ComponentStateViewModel | ComponentStateDto>(
  components: T[],
  offset: PositionDto = { top: CLONE_OFFSET, left: CLONE_OFFSET }
): T[] {
  return components.map((component) => {
    component.view.css.top = (fromPx(component.view.css.top) + offset.top).toString() + "px";
    component.view.css.left = (fromPx(component.view.css.left) + offset.left).toString() + "px";
    return component;
  });
}

export function resolveConsecutivePasteCount(
  clipboardContext: ClipboardContext,
  pasteIntoId: EntityId
): number {
  const currentCount = clipboardContext.consecutivePastesDict[pasteIntoId];
  return (currentCount ?? calculateInitialPasteCount(clipboardContext, pasteIntoId)) + 1;
}

function calculateInitialPasteCount(
  clipboardContext: ClipboardContext,
  pasteIntoId: EntityId
): number {
  return !clipboardContext.isCut &&
    pasteIntoId === clipboardContext.sourceContainerId &&
    !clipboardContext.wasInitiallyCut
    ? 0
    : -1;
}

export function calculatePasteOffsetObject(pasteCount: number): PositionDto {
  return {
    left: CLONE_OFFSET * pasteCount,
    top: CLONE_OFFSET * pasteCount
  };
}

export function calculateWidgetFitPosition<T extends ComponentStateDto | FullComponentStateVM>(
  widget: T,
  shouldLimitMaximum: boolean,
  cardContentSize: SizeInPx
): { newLeft: Maybe<number>; newTop: Maybe<number> } {
  const widgetSize = widget.view.runtimeView.runtimeSize;
  const newLeft = Math.min(
    shouldLimitMaximum ? cardContentSize.widthInPx - widgetSize.widthInPx : Number.MAX_SAFE_INTEGER,
    Math.max(0, fromPx(widget.view.css.left))
  );
  const newTop = Math.min(
    shouldLimitMaximum
      ? cardContentSize.heightInPx - widgetSize.heightInPx
      : Number.MAX_SAFE_INTEGER,
    Math.max(0, fromPx(widget.view.css.top))
  );
  return {
    newLeft,
    newTop
  };
}

export function getWidgetPositioningUpdateActionOnPaste(
  reportEntities: MultiplePasteEntities,
  pasteTargetId: Maybe<EntityId>,
  targetMode: PositioningType,
  componentStateSelector: ComponentStateSelector,
  isDroppedOnRightSide: boolean
): Action[] {
  if (targetMode === PositioningType.Absolute) {
    return [
      getPositioningTypeUpdateAction(reportEntities.pastedEntitiesIds, PositioningType.Absolute)
    ];
  } else if (isDefined(pasteTargetId)) {
    const positioningUpdateAction = getPositioningTypeUpdateAction(
      reportEntities.pastedEntitiesIds,
      PositioningType.Relative
    );
    const pasteTarget = componentStateSelector.getById(pasteTargetId);
    if (isDefined(pasteTarget)) {
      const orderUpdateActions =
        isBasicCard(pasteTarget.type) || isTabContent(pasteTarget.type)
          ? reportEntities.pastedEntitiesIds.map((id) =>
              ComponentStateActions.updateOrderSetAtEnd({ componentId: id })
            )
          : reportEntities.pastedEntitiesIds.map((id) =>
              resolveActionToUpdateOrder(isDroppedOnRightSide, pasteTargetId, id)
            );
      return [positioningUpdateAction, ...orderUpdateActions];
    }
  }
  return [];
}

export function getCardPositioningUpdateActionOnPaste(
  reportEntities: MultiplePasteEntities,
  pasteTargetId: Maybe<EntityId>,
  isDroppedOnRightSide: boolean
): Action {
  return pasteTargetId
    ? resolveActionToUpdateOrder(
        isDroppedOnRightSide,
        pasteTargetId,
        reportEntities.pastedEntitiesIds[0]
      )
    : ComponentStateActions.updateOrderSetAtEnd({
        componentId: reportEntities.pastedEntitiesIds[0]
      });
}

export function getPositioningTypeUpdateAction(
  componentsIds: EntityId[],
  positioningType: PositioningType
): Action {
  return ComponentStateActions.updateMany({
    componentUpdates: componentsIds.map((x) => {
      return {
        id: x.toString(),
        changes: {
          view: {
            css:
              positioningType === PositioningType.Absolute
                ? {
                    position: CssPosition.absolute
                  }
                : {
                    position: CssPosition.relative,
                    left: "",
                    top: ""
                  }
          }
        } as Partial<ComponentStateDto>
      };
    })
  });
}
