import { Options, PaneOptions, YAxisLabelsOptions, YAxisPlotBandsOptions } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { first, isEmptyOrNotDefined, Maybe, parseAndRoundToNumber } from "../../../ts-utils";
import { getTextColorForNoDataStatus } from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { DataStatus } from "../../models";
import { DEFAULT_CHART_TEXT_COLOR_HEX } from "../../models/colors.constants";
import { IBaseDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";
import { GaugeDisplayService } from "./gauge-display.service";
import { switchedOnOff } from "./requires-full-redraw.helper";
import { getBarColor, getYAxisLabelsOptions } from "./single-value-display.helper";

export const solidGaugeInnerRadiusInPercent = 80;
const solidGaugeOuterRadiusInPercent = 100;

export class SolidGaugeDisplayService extends GaugeDisplayService {
  protected colorListService: ColorListService;
  constructor(protected valueFormatter: ValueFormatterService, colorListService: ColorListService) {
    super(valueFormatter);
    this.chartType = "solidgauge";
    this.isTargetAsAxis = true;
    this.colorListService = colorListService;
  }

  protected requiresFullRedraw(
    previousProcessedViewConfig: IBaseDisplayConfig,
    currentProcessedViewConfig: IBaseDisplayConfig
  ): boolean {
    const prev = previousProcessedViewConfig as IGaugeDisplayConfig;
    const cur = currentProcessedViewConfig as IGaugeDisplayConfig;
    return switchedOnOff(prev.limits.target, cur.limits.target);
  }

  protected getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const min = parseAndRoundToNumber(viewConfig.min);
    const max = parseAndRoundToNumber(viewConfig.max);
    const color = getBarColor(value, viewConfig, this.colorListService);
    const options: Options = {
      chart: {
        animation: false
      },
      noData: {
        style: {
          color: getTextColorForNoDataStatus(
            dataStatus,
            viewConfig.foregroundColor ?? DEFAULT_CHART_TEXT_COLOR_HEX
          )
        }
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            borderWidth: 0,
            y: 0,
            style: {
              textOutline: "none"
            }
          }
        }
      },
      pane: {
        size: "140%",
        center: ["50%", "85%"],
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            innerRadius: `${solidGaugeInnerRadiusInPercent}%`,
            outerRadius: `${solidGaugeOuterRadiusInPercent}%`,
            shape: "arc"
          }
        ]
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          stops: [[0, color]],
          tickPositions: [min, max],
          minorTickInterval: null,
          lineWidth: 0,
          tickLength: 0,
          minorTickLength: 0,
          labels: this.getYAxisLabels(viewConfig),
          plotBands: this.getPlotBands(viewConfig)
        },
        {
          linkedTo: 0,
          lineWidth: 0,
          tickLength: 0,
          tickWidth: 0,
          minorTickLength: 0,
          tickPositions: this.getTickPositions(viewConfig),
          labels: this.getLimitsLabels(viewConfig)
        }
      ],
      series: [
        {
          id: "value_solidgauge",
          type: "solidgauge",
          innerRadius: `${solidGaugeInnerRadiusInPercent}%`,
          /** The series color can be seen for 1px behind  plotBands (limits), thus reduced radius */
          radius: `${solidGaugeOuterRadiusInPercent - 1}%`,
          name: viewConfig.title ?? "",
          data: value != null ? [value] : []
        }
      ]
    };

    if (
      !isEmptyOrNotDefined(viewConfig.neutralColor) &&
      options.pane != null &&
      (options.pane as PaneOptions).background != null
    ) {
      (options.pane as PaneOptions).background[0].backgroundColor = {
        stops: [
          [0, viewConfig.neutralColor],
          [1, viewConfig.neutralColor]
        ]
      };
    }
    return options;
  }

  private getYAxisLabels(viewConfig: IGaugeDisplayConfig): YAxisLabelsOptions {
    const { showYAxisLabels } = viewConfig;
    return getYAxisLabelsOptions(viewConfig, showYAxisLabels, this.valueFormatter);
  }

  getYAxisPlotBands(viewConfig: IGaugeDisplayConfig): YAxisPlotBandsOptions[] {
    const plotBands = super.getYAxisPlotBands(viewConfig);
    const zIndexAboveSeries = 5;
    return plotBands.map((plotBand: YAxisPlotBandsOptions): YAxisPlotBandsOptions => {
      const plotLineInnerRadius = solidGaugeOuterRadiusInPercent - 5;
      return {
        ...plotBand,
        zIndex: zIndexAboveSeries,
        outerRadius: `${solidGaugeOuterRadiusInPercent}%`,
        innerRadius: `${plotLineInnerRadius}%`
      };
    });
  }
}
