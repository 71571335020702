import { getLink } from "../../browsing/services/link-resolver";
import { ButtonConfig } from "../../shared/models/button/button-config";
import { ButtonStyle } from "../../shared/models/button/button-style";
import { FunctionWithParams } from "../../shared/models/function-with-params";
import { isDefined, isNotDefined, Maybe } from "../../ts-utils";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { ComponentButtonParams } from "../components/base/component-button-params";
import { LabelViewConfig } from "../components/label/view-config";
import { SingleValueViewConfig } from "../components/single-value/view-config";
import {
  isBasicCard,
  isButton,
  isLabel,
  isNavigationBar,
  isPage,
  isSingleValue,
  isTabGroup,
  isTimeSeries
} from "../models/component-type.helper";
import {
  isAutoLayoutContainer,
  isSingleLineLabel,
  isTextualSingleValue
} from "../models/display-strategies/display-strategy-type.helper";
import { DynamicButtonType } from "../models/dynamic-button-type";
import { BASIC_CARD } from "../models/element-type.constants";
import {
  CARD_ITEM_DRAG_HANDLE,
  PAGE_ITEM_DRAG_HANDLE
} from "../services/component-positioning.service";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { ROOT_COMPONENT_ID } from "../store/component-state/component-state.selectors";
import { HIDDEN_ELEMENT_CLASS } from "./dom-element-visibility.helper";
import { openLink } from "./link-resolution.helper";

export const LINK_BUTTON_CSS_CLASS = "link-button";
export const CSS_INLINE_EDIT_BUTTON = "inline-button";

export function getComponentButtonParams(component: ComponentButtonParams): ButtonConfig[] {
  return getConfigButtons(component).concat(getRuntimeButtons(component));
}

/**
 * Returns array of params for dynamic buttons of component in reverse (right-to-left) order.
 * @param component BaseComponent derivate instance.
 */
function getConfigButtons(component: ComponentButtonParams): ButtonConfig[] {
  const componentType: string = component.currentState.type;
  if (isPage(componentType)) {
    return [];
  }
  const configButtons = isBasicCard(componentType)
    ? getCardButtons(component)
    : getStandardButtons(component);

  configButtons.push(...getSpecificButtons(component));

  return configButtons;
}

function getSpecificButtons(component: ComponentButtonParams): ButtonConfig[] {
  const specificButtons: ButtonConfig[] = [];
  if (isTimeSeries(component.currentState.type) || isLabel(component.currentState.type)) {
    specificButtons.push(getInlineEditParams(component, component.localizer.buttons.InlineEdit));
  }
  return specificButtons;
}

function getCardButtons(component: ComponentButtonParams): ButtonConfig[] {
  return [
    getEditParams(component, component.localizer.buttons.Edit),
    getDeleteParams(component, component.localizer.buttons.Delete),
    getCloneParams(component, component.localizer.buttons.Clone),
    getMoveParams(component, component.localizer.buttons.Move)
  ];
}

function getStandardButtons(component: ComponentButtonParams): ButtonConfig[] {
  return [
    getEditParams(component, component.localizer.buttons.Edit),
    getDeleteParams(component, component.localizer.buttons.Delete),
    getBringToFrontParams(component, component.localizer.buttons.BringToFront),
    getSendToBackParams(component, component.localizer.buttons.SendToBack),
    getCloneParams(component, component.localizer.buttons.Clone)
  ];
}

function getEditParams(component: ComponentButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "Settings"
    }),
    clickFunction: new FunctionWithParams(editHostComponent, component)
  });
}

function editHostComponent(component: ComponentButtonParams): void {
  component.propertySheetService.openOrReplaceTarget(component.currentState);
}

function getDeleteParams(component: ComponentButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "Trash"
    }),
    clickFunction: new FunctionWithParams(deleteComponent, component)
  });
}

function deleteComponent(component: ComponentButtonParams): void {
  component.snapshotDispatch(
    ComponentStateActions.deleteManyWithChildren({
      targetComponents: [component.currentState]
    })
  );
}

function getCloneParams(component: ComponentButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "Copy",
      buttonClass: isTabGroup(component.currentState.type) ? HIDDEN_ELEMENT_CLASS : null
    }),
    clickFunction: new FunctionWithParams(cloneComponent, component)
  });
}

function getMoveParams(component: ComponentButtonParams, titleKey: string): ButtonConfig {
  const root = component.componentStateSelector.getRoot();
  return new ButtonConfig({
    shouldShow: () => isAutoLayoutContainer(component.currentState.view),
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "More",
      buttonClass: root.childrenIds.includes(component.id)
        ? PAGE_ITEM_DRAG_HANDLE
        : CARD_ITEM_DRAG_HANDLE
    }),
    clickFunction: new FunctionWithParams(() => {}, component)
  });
}

function cloneComponent(component: ComponentButtonParams): void {
  const selectedComponent = component.componentStateSelector.getById(component.id);
  if (isNotDefined(selectedComponent)) {
    throw new CriticalError("Component not found in store.");
  }
  const selectedComponentParent = component.componentStateSelector.getParent(selectedComponent.id);
  if (isDefined(selectedComponentParent)) {
    component.snapshotDispatch(
      ComponentStateActions.cloneComponent({
        component: selectedComponent,
        cloneInto: selectedComponentParent.id,
        orderSource: selectedComponent.id,
        isWidget: selectedComponentParent.id !== ROOT_COMPONENT_ID
      })
    );
  }
}

function getBringToFrontParams(component: ComponentButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "Bring_to_front",
      buttonClass: determineLayerOrderButtonsClass(component.currentState.type)
    }),
    clickFunction: new FunctionWithParams(onBringToFront, component)
  });
}

function onBringToFront(component: ComponentButtonParams): void {
  const componentState = component.componentStateSelector.getById(component.id);
  if (isNotDefined(componentState)) {
    throw new CriticalError("Component not found in store.");
  }
  component.snapshotDispatch(
    ComponentStateActions.bringToFront({
      componentId: componentState.id
    })
  );
}

function getSendToBackParams(component: ComponentButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "Send_to_back",
      buttonClass: determineLayerOrderButtonsClass(component.currentState.type)
    }),
    clickFunction: new FunctionWithParams(onSendToBack, component)
  });
}

function determineLayerOrderButtonsClass(componentType: string): Maybe<string> {
  return isTabGroup(componentType) || isNavigationBar(componentType) ? HIDDEN_ELEMENT_CLASS : null;
}

function onSendToBack(component: ComponentButtonParams): void {
  const componentState = component.componentStateSelector.getById(component.id);
  if (isNotDefined(componentState)) {
    throw new CriticalError("Component not found in store.");
  }
  component.snapshotDispatch(
    ComponentStateActions.sendToBack({
      componentId: componentState.id
    })
  );
}

function getInlineEditParams(component: ComponentButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    style: new ButtonStyle({
      title: titleKey,
      iconClass: "abb-icon",
      icon: "Edit",
      buttonClass: CSS_INLINE_EDIT_BUTTON
    }),
    clickFunction: new FunctionWithParams(toggleInlineMode, component)
  });
}

function toggleInlineMode(component: ComponentButtonParams): void {
  component.inlineEditService.enterInlineEditMode(component);
}

function getRuntimeButtons(component: ComponentButtonParams): ButtonConfig[] {
  let runtimeButtons: ButtonConfig[] = [];
  if (isPage(component.currentState.type)) {
    return runtimeButtons;
  }

  if (component.currentState.type !== BASIC_CARD) {
    runtimeButtons.push(getLinkButton(component, component.localizer.buttons.Link));
  }
  return runtimeButtons;
}

export function getLinkButton(component: ComponentButtonParams, titleKey: string): ButtonConfig {
  return new ButtonConfig({
    style: new ButtonStyle({
      buttonType: DynamicButtonType.Runtime,
      iconClass: "abb-icon",
      icon: "Right_arrow",
      title: titleKey,
      buttonClass: LINK_BUTTON_CSS_CLASS
    }),
    clickFunction: new FunctionWithParams(openLink, component),
    shouldShow: () => shouldShowLinkButton(component),
    pageUrl: getLink(component)
  });
}

function shouldShowLinkButton(component: ComponentButtonParams): boolean {
  const { type, view } = component.currentState;
  return (
    component.hasLink &&
    !(isLabel(type) && isSingleLineLabel((view as LabelViewConfig).displayStrategy)) &&
    !(
      isSingleValue(type) && isTextualSingleValue((view as SingleValueViewConfig).displayStrategy)
    ) &&
    !isButton(type)
  );
}
