<div class="data-explorer__container">
  <ul class="data-explorer__list">
    <li
      class="data-explorer__item"
      [ngClass]="
        isSignalTabSelected ? 'data-explorer__item--inactive' : 'data-explorer__item--active'
      "
      (click)="toggleSelectedTab(false)"
    >
      {{ localizer.sidebar.Equipment | translate }}
    </li>
    <li
      class="data-explorer__item"
      [ngClass]="
        isSignalTabSelected ? 'data-explorer__item--active' : 'data-explorer__item--inactive'
      "
      (click)="toggleSelectedTab(true)"
    >
      {{ localizer.sidebar.Signals | translate }}
    </li>
  </ul>
  <div class="data-explorer__content">
    <equipment-data-explorer
      [ngClass]="{ 'element--hidden': isSignalTabSelected }"
      (collapseSidebar)="collapseSidebarOnDragStart()"
      (expandSidebar)="expandSidebarOnDrop()"
    ></equipment-data-explorer>
    <signal-browser
      [ngClass]="{ 'element--hidden': !isSignalTabSelected }"
      [isDataExplorerTab]="true"
      [showCheckboxes]="false"
      (collapseSidebar)="collapseSidebarOnDragStart()"
      (expandSidebar)="expandSidebarOnDrop()"
    ></signal-browser>
  </div>
</div>
