import { BasicCardViewConfig } from "../../components/basic-card/view-config";
import { BaseViewConfigDto } from "../base-view-config";
import { DisplayStrategies } from "./container-display-strategies";
import { DisplayStrategies as LabelDisplayStrategies } from "./label-display-strategies";
import { SingleValueDisplayStrategy } from "./single-value-display-strategies";
import { DisplayStrategies as TimeseriesDisplayStategies } from "./time-series-display-strategies";

export function isTable(displayStrategy: string): boolean {
  return displayStrategy === TimeseriesDisplayStategies.Table;
}

export function isSingleLineLabel(displayStrategy: string): boolean {
  return displayStrategy === LabelDisplayStrategies.Label;
}

export function isTextualSingleValue(displayStrategy: SingleValueDisplayStrategy): boolean {
  return (
    isValueSingleValue(displayStrategy) ||
    isCircleValueSingleValue(displayStrategy) ||
    isTrendValueSingleValue(displayStrategy)
  );
}

export function isValueSingleValue(displayStrategy: SingleValueDisplayStrategy): boolean {
  return displayStrategy === SingleValueDisplayStrategy.Value;
}

export function isCircleValueSingleValue(displayStrategy: SingleValueDisplayStrategy): boolean {
  return displayStrategy === SingleValueDisplayStrategy.CircleValue;
}

export function isTrendValueSingleValue(displayStrategy: SingleValueDisplayStrategy): boolean {
  return displayStrategy === SingleValueDisplayStrategy.TrendValue;
}

export function isAutoLayoutContainer(view: BaseViewConfigDto): boolean {
  return (view as BasicCardViewConfig).displayStrategy === DisplayStrategies.AutoLayout;
}

export function isMultiDialGauge(displayStrategy: SingleValueDisplayStrategy): boolean {
  return displayStrategy === SingleValueDisplayStrategy.MultiDialGauge;
}
