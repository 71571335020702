import { construct } from "../../../core";
import { CommonDataPropertyNames } from "../../../data-connectivity";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  ConfigurableArray,
  ConfigurableEnum,
  ConfigurationCategory,
  DynamicDefaultFromMetadata,
  EditableType,
  Serializable
} from "../../../meta/decorators";
import { ConfigurableArrayWithCheckbox } from "../../../meta/decorators/configurable-array-with-checkbox.decorator";
import { DynamicallyVisible } from "../../../meta/decorators/dynamically-visible.decorator";
import { CutOffStrategy, PropertyCategory } from "../../../meta/models";
import { EditorSize } from "../../../meta/models/editor-size";
import { EditorType } from "../../../meta/models/editor-type";
import { validateNumberOrInterpolatedString } from "../../../property-sheet/helpers/number-validation.helper";
import { DeepPartial, isDefined } from "../../../ts-utils";
import { DynamicallyDecorated } from "../../../ts-utils/helpers/decorator.helper";
import {
  filterOutEnumValues,
  selectDisplayStrategyByComponentId,
  selectFooterVisibility,
  selectHeaderVisibility
} from "../../helpers/dynamically-visible-properties.helper";
import { getDefaultFooters, getDefaultHeaders } from "../../helpers/table-header-footer.helper";
import { DisplayStrategies } from "../../models/display-strategies/time-series-display-strategies";
import { FILTERING_AND_AGGREGATION__CUT_OFF } from "../../models/help-constants";
import { ITimeSeriesChartDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { LimitsDto } from "../../models/limits";
import { configureMinMax, MinMaxConfigDto } from "../../models/min-max-config";
import { TableFooterDescriptor } from "../../models/table/table-footer-descriptor";
import { TableHeaderDescriptor } from "../../models/table/table-header-descriptor";
import { DEFAULT_HEADER_DESCRIPTOR } from "../../models/table/table-header-footer-options";
import { TIME_SERIES_VIEW_CONFIG } from "../../models/view-config-type.constants";
import { YAxisDescriptor } from "../../models/y-axis-descriptor";
import { getCommonChartSize } from "../base/common-view-config-defaults";
import { IHistogramViewConfig } from "../simple-components/histogram/i-histogram-view-config";
import { ITableViewConfig } from "../simple-components/table-for-connectors/i-table-for-connectors-view-config";
import { StrategizedChartViewConfig } from "../strategized-chart/view-config";
import { Roles } from "./roles";

const DEFAULT_TABLE_PAGE_SIZE = 20;
export const DEFAULT_BINS_NUMBER = 10;

// @dynamic
@EditableType({ fullName: TIME_SERIES_VIEW_CONFIG })
export class TimeSeriesViewConfig
  extends StrategizedChartViewConfig
  implements MinMaxConfigDto, ITableViewConfig, ITimeSeriesChartDisplayConfig, IHistogramViewConfig
{
  typeName = TIME_SERIES_VIEW_CONFIG;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @ConfigurableEnum({
    enumSource: DisplayStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(DisplayStrategies.Line)
  displayStrategy!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Stack,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @DynamicallyVisible(
    selectDisplayStrategyByComponentId,
    filterOutEnumValues(DisplayStrategies, [DisplayStrategies.Table, DisplayStrategies.Histogram])
  )
  @Serializable(false)
  stacked!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XAxisMinimumValue,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateNumberOrInterpolatedString
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Histogram])
  @Serializable()
  @AllowInterpolation()
  xAxisMin!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XAxisMaximumValue,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateNumberOrInterpolatedString
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Histogram])
  @Serializable()
  @AllowInterpolation()
  xAxisMax!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.NumberOfBins,
    editorType: EditorType.Number,
    advancedMode: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.BinsTooltip
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Histogram])
  @Serializable(DEFAULT_BINS_NUMBER)
  numberOfBins!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.SharedBins,
    editorType: EditorType.CheckBox,
    advancedMode: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.SharedBinsTooltip
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Histogram])
  @Serializable(false)
  sharedBins!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitle,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitleTooltip,
    editorType: EditorType.TextBox,
    advancedMode: true
  })
  @DynamicallyVisible(
    selectDisplayStrategyByComponentId,
    filterOutEnumValues(DisplayStrategies, [DisplayStrategies.Table, DisplayStrategies.Histogram])
  )
  @Serializable("")
  xAxisTitle!: string;

  @ConfigurableArray({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YAxes,
    editorType: EditorType.Array,
    typeConstructor: YAxisDescriptor,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    arrayEditorSize: EditorSize.Medium,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.YAxesTooltip,
    canBeHidden: true
  })
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.YAxes, 12)
  @DynamicallyVisible(
    selectDisplayStrategyByComponentId,
    filterOutEnumValues(DisplayStrategies, [DisplayStrategies.Table])
  )
  @Serializable([new YAxisDescriptor()], YAxisDescriptor)
  @AllowInterpolation()
  yAxes!: YAxisDescriptor[];

  @DynamicDefaultFromMetadata(Roles.Value.name, CommonDataPropertyNames.unit, -1, true, false)
  yAxisTitle!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayLegend,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(
    selectDisplayStrategyByComponentId,
    filterOutEnumValues(DisplayStrategies, [DisplayStrategies.Table])
  )
  @Serializable(true)
  showLegend!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowRegression,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ShowRegressionTooltip,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @DynamicallyVisible(
    selectDisplayStrategyByComponentId,
    filterOutEnumValues(DisplayStrategies, [DisplayStrategies.Table, DisplayStrategies.Histogram])
  )
  @Serializable(false)
  showTrendLine: boolean = false;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 2)
  @Configurable({
    editorType: EditorType.LimitsEditor,
    advancedMode: true
  })
  limits!: LimitsDto;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowLimitsAsLines,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(false)
  showLimitsAsLines!: boolean;

  //#region MinMaxConfigDto
  @DynamicallyDecorated
  min: string;

  @DynamicallyDecorated
  max: string;
  //#endregion

  @Serializable(DEFAULT_TABLE_PAGE_SIZE)
  tablePageSize!: number;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation,
    11
  )
  @ConfigurableEnum({
    enumSource: CutOffStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategy,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategyTooltip,
    userHelp: FILTERING_AND_AGGREGATION__CUT_OFF
  })
  @Serializable(CutOffStrategy.Downsample)
  cutOffStrategy!: CutOffStrategy;

  tablePageSizeOptions: number[] = [5, 10, DEFAULT_TABLE_PAGE_SIZE, 50, 100];

  //TODO show column data labels are only for vertical bar
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayColumnDataLabels,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Column])
  @Serializable(false)
  showColumnDataLabels: boolean;

  //TODO columns are only for tables
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 6)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayFilter,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Table])
  @Serializable(false)
  enableFilter!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.AlternateRowColors,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Table])
  @Serializable(false)
  alternateTableRowColors!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.EnableHeaderAndFooterLabels,
    editorType: EditorType.CheckBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.EnableHeaderAndFooterLabelsTooltip
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Table])
  @Serializable(false)
  enableHeaderAndFooterLabels!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.ShowHeader)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowHeader,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Table])
  @Serializable(true)
  showHeader!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.ShowHeader)
  @ConfigurableArrayWithCheckbox({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowHeader,
    editorType: EditorType.ExtendableCheckboxList,
    typeConstructor: TableHeaderDescriptor,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    enableCustomItems: true
  })
  @DynamicallyVisible(selectHeaderVisibility, [true])
  @Serializable([DEFAULT_HEADER_DESCRIPTOR], TableHeaderDescriptor)
  headerDescriptors!: TableHeaderDescriptor[];

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.ShowFooter)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowFooter,
    editorType: EditorType.CheckBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.FooterTooltip
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Table])
  @Serializable(false)
  showFooter!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.ShowFooter)
  @ConfigurableArrayWithCheckbox({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowFooter,
    editorType: EditorType.ExtendableCheckboxList,
    typeConstructor: TableFooterDescriptor
  })
  @DynamicallyVisible(selectFooterVisibility, [true])
  @Serializable([], TableFooterDescriptor)
  footerDescriptors!: TableFooterDescriptor[];

  constructor(viewConfigDto: DeepPartial<TimeSeriesViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<TimeSeriesViewConfig> = {
      size: getCommonChartSize()
    };

    viewConfigDto = {
      ...viewConfigDto,
      yAxes: isDefined(viewConfigDto.yAxes)
        ? viewConfigDto.yAxes.map((yAxis) => new YAxisDescriptor(yAxis))
        : [new YAxisDescriptor()],
      footerDescriptors: isDefined(viewConfigDto.footerDescriptors)
        ? viewConfigDto.footerDescriptors.map(
            (footerDescriptor) => new TableFooterDescriptor(footerDescriptor)
          )
        : getDefaultFooters(),
      headerDescriptors: isDefined(viewConfigDto.headerDescriptors)
        ? viewConfigDto.headerDescriptors.map(
            (headerDescriptor) => new TableHeaderDescriptor(headerDescriptor)
          )
        : getDefaultHeaders()
    };
    construct(this, viewConfigDto, TIME_SERIES_VIEW_CONFIG, defaultOverrides);
  }
}

configureMinMax(
  TimeSeriesViewConfig,
  Roles.Value,
  Roles.Minimum,
  Roles.Maximum,
  null,
  null,
  0,
  true
);
