import { FlatTreeControl } from "@angular/cdk/tree";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from "@angular/material/tree";
import { isEqual as _isEqual } from "lodash";
import { filter, takeUntil } from "rxjs/operators";
import { DraggedItem } from "../../../core";
import { filterByRootClass, onRootPathChange } from "../../../core/helpers/root-class.helper";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { Equipment } from "../../../core/models/equipment";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { Dispatcher } from "../../../dispatcher";
import { RuntimeSettingsSelector } from "../../../elements/services/entity-selectors/runtime-settings.selector";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import {
  Maybe,
  isArray,
  isDefined,
  isEmpty,
  isEmptyOrNotDefined,
  isNotDefined
} from "../../../ts-utils";
import { DataExplorerSelector } from "../../services/data-explorer.selector";
import { EquipmentSelector } from "../../services/equipment.selector";
import { DataExplorerActions } from "../../store/data-explorer";
import { ItemBrowserComponent } from "../item-browser/item-browser.component";

// @dynamic
@Component({
  selector: "equipment-browser",
  templateUrl: "equipment-browser.component.html",
  styleUrls: ["equipment-browser.component.scss"]
})
export class EquipmentBrowserComponent
  extends ItemBrowserComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild("tree") tree: MatTreeModule;
  @Output() selectedEquipmentChanged: EventEmitter<Equipment> = new EventEmitter<Equipment>();
  @Output() collapseSidebar: EventEmitter<any> = new EventEmitter();
  @Output() expandSidebar: EventEmitter<any> = new EventEmitter();
  @Input() showInDialog: boolean;
  @Input() rootPath: string = "/";
  @Input() showTitle: boolean = true;
  @Input() openFullTree: boolean = true;
  @Input() rootClass: string = "";

  public treeControl: FlatTreeControl<Equipment>;
  public treeFlattener: MatTreeFlattener<Equipment, Equipment>;
  public dataSource: MatTreeFlatDataSource<Equipment, Equipment>;
  private levels: Map<Equipment, number>;
  public recursive = false;
  matTreeNodePadding = 25;
  public currentRootNode: Equipment = new Equipment();
  public fullEquipmentTree: Equipment;
  public showProgressBar: boolean = true;
  private treeModelFromStore: Maybe<Equipment[]>;
  private isDraggingInProgress: boolean = false;

  constructor(
    protected dragDropService: IDragDropService,
    protected dispatcher: Dispatcher,
    protected equipmentSelector: EquipmentSelector,
    protected dataExplorerSelector: DataExplorerSelector,
    public localizer: LocalizationService,
    private runtimeSettingsSelector: RuntimeSettingsSelector,
    protected environmentSelector: EnvironmentSelector
  ) {
    super(dragDropService, environmentSelector);
    this.levels = new Map<Equipment, number>();
    this.treeControl = new FlatTreeControl<Equipment>(this.getLevel, this.isExpandable);
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToEquipmentTree();
    if (!this.showInDialog) {
      this.subscribeToPropertyBrowserMode();
      this.resolveSelectedEquipment();
      this.resolveExpandedNodes();
    }
    if (this.rootClass) {
      this.filterEquipmentTreeByClass(this.rootClass);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["rootPath"]) {
      this.currentRootNode = onRootPathChange(this.rootPath, this.fullEquipmentTree);
      this.updateEquipmentTree(this.currentRootNode);
    } else if (changes["rootClass"]) {
      this.filterEquipmentTreeByClass(this.rootClass);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (
      !this.showInDialog &&
      !_isEqual(this.treeModelFromStore, this.treeControl.expansionModel.selected)
    ) {
      this.dispatcher.dispatch(
        DataExplorerActions.setEquipmentTreeModel({
          treeModel: this.treeControl.expansionModel.selected
        })
      );
    }
  }

  private subscribeToEquipmentTree(): void {
    this.equipmentSelector
      .selectEquipmentTreeFromRootPath(this.openFullTree)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((fullEquipmentTree) => {
        if (isDefined(fullEquipmentTree)) {
          this.fullEquipmentTree = fullEquipmentTree;
        }
        if (isEmptyOrNotDefined(fullEquipmentTree)) {
          this.updateEquipmentTree(new Equipment());
        } else {
          this.currentRootNode = onRootPathChange(this.rootPath, this.fullEquipmentTree);
          this.updateEquipmentTree(this.currentRootNode);
        }
        this.showProgressBar = false;
      });
  }

  private updateEquipmentTree(rootNode: Equipment): void {
    if (isNotDefined(rootNode)) {
      return;
    }
    this.refreshTree(rootNode);
    if (this.getLevel(rootNode) === 0 && !isEmpty(rootNode.children)) {
      this.treeControl.expand(rootNode);
    }
  }

  private refreshTree(rootNode: Equipment): void {
    this.dataSource.data = []; // FIXME: This hack makes padding left of parent and children nodes to work properly
    this.dataSource.data = [rootNode];
  }

  private subscribeToPropertyBrowserMode(): void {
    this.dataExplorerSelector
      .selectPropertyBrowserMode()
      .pipe(
        filter((isExpanded) => !isExpanded),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(() => {
        if (!this.isDraggingInProgress) {
          super.setSelectedItem(null);
        }
      });
  }

  private resolveExpandedNodes(): void {
    this.treeModelFromStore = this.dataExplorerSelector.getEquipmentTreeModel();
    if (isArray(this.treeModelFromStore)) {
      this.treeModelFromStore.map((node) => this.treeControl.expand(node));
    }
  }

  private resolveSelectedEquipment(): void {
    const equipment: Maybe<Equipment> = this.dataExplorerSelector.getEquipment();
    if (isDefined(equipment)) {
      super.setSelectedItem(equipment);
      this.dispatcher.dispatch(DataExplorerActions.expandPropertyBrowser());
    }
  }

  private filterEquipmentTreeByClass(rootClass: string): void {
    if (rootClass === "Any") {
      return;
    }
    const node = filterByRootClass(this.currentRootNode, rootClass, this.aliasMode);
    if (isDefined(node)) {
      this.currentRootNode = node;
      this.updateEquipmentTree(this.currentRootNode);
    }
  }

  getLevel = (equipment: Equipment): number => {
    return this.levels.get(equipment) || 0;
  };

  isExpandable = (equipment: Equipment): boolean => {
    return equipment.children.length > 0;
  };

  getChildren = (equipment: Equipment): Equipment[] => {
    return equipment.children;
  };

  // FIXME naming
  transformer = (equipment: Equipment, level: number): Equipment => {
    this.levels.set(equipment, level);
    return equipment;
  };

  hasChildren(_index: number, node: Equipment): boolean {
    return node?.children != null && node.children.length > 0;
  }

  setSelectedItem(equipment: Equipment): void {
    if (equipment?.disabled) {
      return;
    }
    super.setSelectedItem(equipment);
    this.selectedEquipmentChanged.emit(this.selectedItem as Equipment);
  }

  getDragTarget(node: Equipment): DraggedItem {
    return {
      type: DraggedItemType.Equipment,
      item: {
        equipment: node
      }
    };
  }

  dragStart(event, node: Equipment): void {
    this.isDraggingInProgress = true;
    this.collapseSidebar.emit();
    super.dragStart(event, node, this.runtimeSettingsSelector.getCurrentRootPath());
  }

  dragEnd(): void {
    this.isDraggingInProgress = false;
    this.expandSidebar.emit();
  }
}
