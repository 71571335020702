<div class="core__wrapper">
  <c-history-view *ngIf="isHistoryViewOpened$ | async" class="history-view"></c-history-view>
  <c-app-header
    [hasAccessToEdit]="hasAccessToEdit"
    [showStatusIndicator]="showHeaderStatusIndicator"
    *ngIf="!hideHeader"
  ></c-app-header>
  <c-app-body [hideFooter]="hideHeader" (toggleReportBrowser)="toggleReportBrowserSidebar()">
    <ng-content></ng-content>
  </c-app-body>
</div>
<div id="dragging_indicator_wrapper" class="element--hidden"></div>
