import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import Highcharts, { SeriesOptionsType, SeriesScatterOptions } from "highcharts";
import { SCATTER_CHART_COMPONENT_NAME } from "../../../core/models/dto-type.constants";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { DataPointDto } from "../../../data-connectivity/models/data-point";
import {
  SERIES_TYPE_DEFAULT,
  SERIES_TYPE_SCATTER
} from "../../../data-connectivity/models/series-type.strategies";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  EditableWidget,
  LayoutBuilder,
  NumberOfDataPointsToRequest
} from "../../../meta/decorators";
import { ComponentCategory } from "../../../meta/models";
import { insertItemsIntoGroups, sortGroups } from "../../../property-sheet/helpers/groups.helper";
import { ConnectorGroupDto } from "../../../shared/models/connector-group";
import {
  isDefined,
  isWhiteSpace,
  mapDictionary,
  toDictionary,
  tryConvertToNumber
} from "../../../ts-utils/helpers";
import { Dictionary, Maybe } from "../../../ts-utils/models";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { calculateNumberOfPointsByLengthInPix } from "../../helpers/component-size.helper";
import {
  MinMaxAggregator,
  prepareTrendSeries,
  simpleLinearRegression
} from "../../helpers/regression-line.helper";
import { SCATTER_CHART } from "../../models/help-constants";
import { SizeInPx } from "../../models/size-in-px";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { DataConnectorViewSelector } from "../../services/entity-selectors/data-connector-view.selector";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID
} from "../../services/highcharts/base-highcharts-options.helper";
import {
  PlotLineGenerator,
  PlotLineGeneratorScatter
} from "../../services/highcharts/plot-line-generator.service";
import { ShapeGenerator } from "../../services/highcharts/shape-generator.service";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ChartComponent } from "../chart/chart.component";
import { Roles } from "./roles";
import { ScatterChartViewConfig } from "./view-config";

const NO_GROUP_SERIES_NAME = "noGroup";

interface Group {
  id: string;
  name: string;
  index: number;
  items: DataConnectorDescriptor[];
}

@Component({
  selector: "scatter-chart",
  templateUrl: "./scatter-chart.component.html",
  styleUrls: ["./scatter-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: ScatterChartComponent }]
})
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  SCATTER_CHART_COMPONENT_NAME,
  "Trend_scatter",
  "abb-icon",
  LOCALIZATION_DICTIONARY.layoutEditor.ScatterChart,
  null,
  SCATTER_CHART
)
@ConnectorRoles(Roles)
@NumberOfDataPointsToRequest(calculateNumberOfDataPointsToRequest)
@MaxConnectors(20)
@EditableWidget({
  fullName: SCATTER_CHART_COMPONENT_NAME,
  title: "scatter-chart"
})
export class ScatterChartComponent extends ChartComponent {
  public Highcharts = Highcharts;
  private groups: ConnectorGroupDto[] = [];
  private highchartSeries: SeriesOptionsType[] = [];
  viewConfig!: ScatterChartViewConfig;
  connectorDescriptors: DataConnectorDescriptor[] = [];

  @View(ScatterChartViewConfig)
  public get view(): ScatterChartViewConfig {
    return this.currentState.view as ScatterChartViewConfig;
  }

  private plotLineGenerator: PlotLineGenerator = new PlotLineGeneratorScatter();
  private shapeGenerator = new ShapeGenerator();

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    cdr: ChangeDetectorRef,
    public connectorViewSelector: DataConnectorViewSelector,
    private valueFormatter: ValueFormatterService
  ) {
    super(params, hostElementRef, cdr);
  }

  protected updateChartData(): void {
    const interpolatedProperties =
      this.propertyInterpolationService.prepareAndInterpolateProperties<ScatterChartViewConfig>(
        this.currentState,
        this.dataAccessor.getAllConnectors()
      );
    this.viewConfig = interpolatedProperties.viewConfig;
    this.connectorDescriptors = interpolatedProperties.connectorDescriptors;

    this.groups = this.getScatterGroups();
    this.resetChartData();

    const groupsScatterData: Dictionary<number[][]> = toDictionary(
      this.groups,
      (group) => group.id,
      (group) => this.calculateGroupScatterData(group)
    );

    let groupsTrendData: Dictionary<number[][]> = {};
    if (this.viewConfig.showTrendLine) {
      const minMaxAggregator = new MinMaxAggregator();
      Object.values(groupsScatterData).forEach((groupData) => minMaxAggregator.process(groupData));
      groupsTrendData = mapDictionary(groupsScatterData, (scatterData) => {
        const regression = simpleLinearRegression(scatterData);
        const trendEndpoints = minMaxAggregator.range.map((x) => [x, regression(x)]);
        return trendEndpoints;
      });
    }

    this.highchartSeries = this.groups
      .map((group, index) =>
        this.createHighchartsSeriesFromGroup(group, groupsScatterData, groupsTrendData, index)
      )
      .flat();
  }

  private calculateGroupScatterData(group: ConnectorGroupDto): number[][] {
    const firstTwo = group.items.slice(0, 2);
    const dataPoints = firstTwo.map((x) => x?.dataPoints).filter(isDefined);
    return dataPoints.length >= 2 ? combineData(dataPoints) : [];
  }

  private getScatterGroups(): ConnectorGroupDto[] {
    this.groups = [];
    const connectors = this.connectorDescriptors
      .filter((dcd) => isDefined(dcd.connector) && isScatterSeries(dcd.connectorView))
      .map((connectorDescriptor) => connectorDescriptor.connector);

    const filledGroups = insertItemsIntoGroups(
      this.viewConfig.groups ?? [],
      connectors,
      this.connectorViewSelector
    );
    this.groups = sortGroups(filledGroups);
    // discard groups with only one DC
    return this.groups.filter((group) => group.items.length >= 2);
  }

  private resetChartData(): void {
    if (isDefined(this.chartObject)) {
      this.chartObject.update(
        {
          series: []
        },
        true,
        true,
        false
      );
    }
  }

  private createHighchartsSeriesFromGroup(
    group: ConnectorGroupDto,
    groupsScatterData: Dictionary<number[][]>,
    groupsTrendData: Dictionary<number[][]>,
    groupIndex: number
  ): SeriesOptionsType[] {
    const firstConnectorView = this.dataConnectorViewSelector.getById(
      getConnectorViewId(group.items[0].id)
    );
    const configuredColor = firstConnectorView?.color;
    const color =
      isDefined(configuredColor) && !isWhiteSpace(configuredColor)
        ? configuredColor
        : this.colorService.getSeriesColorAtIndex(groupIndex);
    const scatterSeries: SeriesScatterOptions = {
      id: group.id,
      name: group.id === "" ? NO_GROUP_SERIES_NAME : group.name,
      data: groupsScatterData[group.id],
      type: "scatter",
      color: color
    };
    const result: SeriesOptionsType[] = [scatterSeries];

    if (this.viewConfig.showTrendLine) {
      const trendSeries = prepareTrendSeries(scatterSeries);
      trendSeries.data = groupsTrendData[group.id];
      result.push(trendSeries);
    }
    return result;
  }

  protected setChartOptions(): void {
    const viewConfig = this.viewConfig;
    const valueFormatter = this.valueFormatter;
    const options: Highcharts.Options = {
      chart: {
        type: "scatter",
        zooming: { type: "xy" }
      },
      title: {
        text: this.viewConfig.title ?? ""
      },
      legend: {
        enabled: this.viewConfig.showLegend
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          }
        },
        scatter: {
          tooltip: {
            headerFormat: "{series.name}<br/>",
            pointFormatter: function () {
              return getScatterTooltip(this, viewConfig, valueFormatter);
            }
          },
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: "rgb(100,100,100)"
              }
            }
          }
        }
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          title: { text: this.viewConfig.xAxisTitle },
          labels: {
            formatter: function () {
              return valueFormatter.formatValue(Number(this.value), viewConfig.displayFormat);
            }
          },
          gridLineWidth: 1,
          min: tryConvertToNumber(this.viewConfig.xMin),
          max: tryConvertToNumber(this.viewConfig.xMax)
        }
      ],
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          title: { text: this.viewConfig.yAxisTitle },
          labels: {
            formatter: function () {
              return valueFormatter.formatValue(Number(this.value), viewConfig.displayFormat);
            }
          },
          min: tryConvertToNumber(this.viewConfig.yMin),
          max: tryConvertToNumber(this.viewConfig.yMax)
        }
      ]
    };

    this.highchartSeries.map((series) => {
      this.chartObject?.addSeries(series, false, false);
    });

    this.plotLineGenerator.addPlotLines(options, this.connectorDescriptors, []);
    this.shapeGenerator.addShapes(options, this.connectorDescriptors, []);

    this.mergeChartOptions(options);
  }
}

function getScatterTooltip(
  chartPoint: Highcharts.Point,
  viewConfig: ScatterChartViewConfig,
  valueFormatter: ValueFormatterService
): string {
  return `X: ${valueFormatter.formatValue(
    chartPoint.x,
    viewConfig.displayFormat
  )}<br/>Y: ${valueFormatter.formatValue(chartPoint.y, viewConfig.displayFormat)}`;
}

export function calculateNumberOfDataPointsToRequest(
  _strategy: string,
  componentSize: SizeInPx
): number {
  return calculateNumberOfPointsByLengthInPix(
    Math.max(componentSize.widthInPx, componentSize.heightInPx)
  );
}

function combineData(dataConnectors: DataPointDto[][]): number[][] {
  const res = dataConnectors.reduce<Dictionary<number[]>>(
    (outerAcc, dataConnector) =>
      dataConnector.reduce((acc, dataPoint) => {
        const key = dataPoint.x?.toString();
        if (isDefined(key)) {
          const existing = acc[key];
          if (isDefined(existing)) {
            existing.push(dataPoint.evaluatedValue ?? dataPoint.y);
          } else {
            acc[key] = [dataPoint.evaluatedValue ?? dataPoint.y];
          }
        }
        return acc;
      }, outerAcc),
    {}
  );

  const fullCoords = Object.values(res).filter((coords) => coords.length === dataConnectors.length);
  return fullCoords;
}

function isScatterSeries(connectorView: Maybe<DataConnectorViewDto>): boolean {
  const seriesType = connectorView?.scatterSeriesConfig?.seriesType;
  return (
    !isDefined(seriesType) ||
    seriesType === SERIES_TYPE_DEFAULT ||
    seriesType === SERIES_TYPE_SCATTER
  );
}
