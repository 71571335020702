import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { BasicCardViewConfig } from "../components/basic-card/view-config";
import { ComponentStateDto } from "../models";
import { HorizontalAlignment } from "../models/alignment/horizontal-alignment";
import { VerticalAlignment } from "../models/alignment/vertical-alignment";
import { isAutoLayoutContainer } from "../models/display-strategies/display-strategy-type.helper";
import { ComponentStateSelector } from "../services";

export function resolveSelfAlignment(
  component: ComponentStateDto,
  componentStateSelector: ComponentStateSelector
): string {
  const parent = componentStateSelector.getParent(component.id);
  if (isDefined(parent) && isAutoLayoutContainer(parent.view)) {
    const shouldApplyVerticalAlignment = !(parent.view as BasicCardViewConfig).vertical;
    if (shouldApplyVerticalAlignment) {
      switch (component.view.css.verticalAlignment) {
        case VerticalAlignment.Top:
          return "flex-start";
        case VerticalAlignment.Middle:
          return "center";
        case VerticalAlignment.Bottom:
          return "flex-end";
      }
    } else {
      switch (component.view.css.horizontalAlignment) {
        case HorizontalAlignment.Left:
          return "flex-start";
        case HorizontalAlignment.Center:
          return "center";
        case HorizontalAlignment.Right:
          return "flex-end";
      }
    }
  }
  return "";
}
