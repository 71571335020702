import { DataTransferObject } from "../../core/models/data-transfer-object";
import { EmptyLinkDto, LinkDto } from "../../core/models/link";
import { EventSubscription } from "../../core/models/widget-eventing/widget-event";
import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurableEnum,
  ConfigurationCategory,
  DynamicallyVisible,
  Title,
  Virtual
} from "../../meta/decorators";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { AllowInterpolation } from "../../meta/decorators/interpolation.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { CutOffStrategy } from "../../meta/models/cut-off-strategy";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { ValidationContext } from "../../meta/models/validation-context";
import { ConnectorGroupDto } from "../../shared/models/connector-group";
import { DeepPartial } from "../../ts-utils";
import { selectTitleHorizontalAlignmentVisibility } from "../helpers/dynamically-visible-properties.helper";
import { HorizontalAlignment } from "./alignment/horizontal-alignment";
import { BLACK_COLOR_HEX } from "./colors.constants";
import {
  ComponentCssSize,
  DEFAULT_COMPONENT_HEIGHT,
  DEFAULT_COMPONENT_WIDTH
} from "./component-size";
import { ComponentStyleDto } from "./component-style";
import { DisplayFormatDto } from "./display-format";
import { SINGLE_VALUE } from "./element-type.constants";
import {
  FILTERING_AND_AGGREGATION__CUT_OFF,
  PROPERTY_INTERPOLATION,
  WIDGET_PROPERTIES__BACKGROUND_IMAGE_AND_COLOR
} from "./help-constants";
import { DEFAULT_TITLE_INTERPOLATION } from "./property-interpolation.constants";
import { RuntimeViewProperties } from "./runtime-view-properties";
import { SizeInPx } from "./size-in-px";
import { TitleFormat } from "./title-format";

const TYPE_NAME = "BaseViewConfigDto";
const DEFAULT_GROUP_NAME = "Group";

// @dynamic
@EditableType({ fullName: TYPE_NAME, virtual: true })
export class BaseViewConfigDto implements DataTransferObject {
  typeName = TYPE_NAME;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 2)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.PropertyInterpolationTooltip,
    editorType: EditorType.TextBox,
    userHelp: PROPERTY_INTERPOLATION
  })
  @Title()
  @Serializable(DEFAULT_TITLE_INTERPOLATION)
  @AllowInterpolation()
  title?: string;

  @Serializable(new ComponentStyleDto())
  css!: ComponentStyleDto;

  @Serializable()
  size: ComponentCssSize;

  @Virtual()
  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.Navigation
  )
  @Serializable(new EmptyLinkDto())
  link!: LinkDto;

  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.LinkedWidgets
  )
  @Configurable({
    editorType: EditorType.LinkWidget,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Events
  })
  @Serializable([], EventSubscription)
  subscribedEvents: EventSubscription[];

  @Serializable(new DisplayFormatDto())
  @DynamicallyVisible(
    (context: any) => context.services.environmentSelector.selectTemplateBuilderMode(),
    [false]
  )
  displayFormat!: DisplayFormatDto;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 3)
  @ConfigurableEnum({
    enumSource: TitleFormat,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TitleFormat,
    advancedMode: true
  })
  @Serializable(TitleFormat.Truncate)
  titleFormat!: string;

  @ConfigurationCategory(PropertyCategory.Interaction)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Hidable,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(false)
  hidable!: boolean;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation,
    11
  )
  @ConfigurableEnum({
    enumSource: CutOffStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategy,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategyTooltip,
    validationFunction: validateCutoff,
    userHelp: FILTERING_AND_AGGREGATION__CUT_OFF
  })
  @Serializable(CutOffStrategy.End)
  cutOffStrategy!: CutOffStrategy;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Background)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Image,
    editorType: EditorType.ImageSelection,
    advancedMode: true,
    inlineAlignment: true,
    userHelp: WIDGET_PROPERTIES__BACKGROUND_IMAGE_AND_COLOR
  })
  @Serializable("")
  backgroundImage!: string;

  @ConfigurationCategory(PropertyCategory.Interaction)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Exporting,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(false)
  exporting!: boolean;

  @DynamicallyVisible(selectTitleHorizontalAlignmentVisibility, [false])
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 6)
  @ConfigurableEnum({
    enumSource: HorizontalAlignment,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.HorizontalTitleAlignment,
    advancedMode: true
  })
  @Serializable(HorizontalAlignment.Center)
  horizontalAlignment!: string;

  foregroundColor: string = BLACK_COLOR_HEX;

  hidden: boolean = false;

  hasRuntimeData: boolean = true;
  supportsInteraction: boolean = true;
  useDragOverlay: boolean = true;
  selectable: boolean = true;

  backgroundImageData: string = "";
  runtimeView!: RuntimeViewProperties;

  groupName?: string = DEFAULT_GROUP_NAME;

  @Serializable([new ConnectorGroupDto()], ConnectorGroupDto)
  groups: ConnectorGroupDto[] = [];

  constructor(viewConfigDto: DeepPartial<BaseViewConfigDto> = {}) {
    const defaultOverrides: Partial<BaseViewConfigDto> = {
      runtimeView: {
        runtimeSize: getDefaultRuntimeSize()
      }
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function validateCutoff(cutOffStrategy: CutOffStrategy, context: ValidationContext): boolean {
  return cutOffStrategy !== CutOffStrategy.Start || context.itemOwner?.type !== SINGLE_VALUE;
}

function getDefaultRuntimeSize(): SizeInPx {
  return { widthInPx: DEFAULT_COMPONENT_WIDTH, heightInPx: DEFAULT_COMPONENT_HEIGHT };
}
