import { isEmptyDict } from "../../ts-utils/helpers/dictionary.helper";
import { DEFAULT_OVERRIDES_FOR_AUTO_LAYOUT } from "../components/basic-card/default-overrides";
import { DEFAULT_OVERRIDES_FOR_CATEGORY_BAR } from "../components/category/default-overrides";
import { DEFAULT_OVERRIDES_FOR_MULTILINE_LABEL } from "../components/label/default-overrides";
import {
  DEFAULT_OVERRIDES_FOR_CIRCLE_VALUE,
  DEFAULT_OVERRIDES_FOR_HORIZONTAL_GAUGE,
  DEFAULT_OVERRIDES_FOR_RADIAL_GAUGE,
  DEFAULT_OVERRIDES_FOR_VERTICAL_GAUGE
} from "../components/single-value/default-overrides";
import { StrategizedChartViewConfig } from "../components/strategized-chart/view-config";
import { DEFAULT_OVERRIDES_FOR_TIME_TABLE } from "../components/time-series/default-overrides";
import { TimeSeriesStrategies } from "../models";
import { DisplayStrategies as CategoryStrategies } from "../models/display-strategies/category-display-strategies";
import { DisplayStrategies as ContainerStrategies } from "../models/display-strategies/container-display-strategies";
import { DisplayStrategies as LabelStrategies } from "../models/display-strategies/label-display-strategies";
import { SingleValueDisplayStrategy } from "../models/display-strategies/single-value-display-strategies";
import { StrategyDefaultsOverrideService } from "./strategy-defaults-override.service";

export function getDefaultOverridesForStrategy(
  strategy: string,
  strategyDefaultsOverrideService: StrategyDefaultsOverrideService
): Partial<StrategizedChartViewConfig> {
  const primaryStrategyOverrides = strategyDefaultsOverrideService.getStrategyOverrides(strategy);
  if (!isEmptyDict(primaryStrategyOverrides)) {
    return primaryStrategyOverrides;
  }

  switch (strategy) {
    case SingleValueDisplayStrategy.BulletChart:
    case SingleValueDisplayStrategy.HorizontalGauge:
      return DEFAULT_OVERRIDES_FOR_HORIZONTAL_GAUGE;
    case SingleValueDisplayStrategy.VerticalGauge:
      return DEFAULT_OVERRIDES_FOR_VERTICAL_GAUGE;
    case SingleValueDisplayStrategy.DialGauge:
    case SingleValueDisplayStrategy.MultiDialGauge:
    case SingleValueDisplayStrategy.SolidGauge:
      return DEFAULT_OVERRIDES_FOR_RADIAL_GAUGE;
    case SingleValueDisplayStrategy.CircleValue:
      return DEFAULT_OVERRIDES_FOR_CIRCLE_VALUE;
    case SingleValueDisplayStrategy.Value:
    case SingleValueDisplayStrategy.TrendValue:
      return {};
    case CategoryStrategies.CategoryHorizontalBar:
    case CategoryStrategies.CategoryVerticalBar:
      return DEFAULT_OVERRIDES_FOR_CATEGORY_BAR;
    case TimeSeriesStrategies.Table:
      return DEFAULT_OVERRIDES_FOR_TIME_TABLE;
    case LabelStrategies.MultilineLabel:
      return DEFAULT_OVERRIDES_FOR_MULTILINE_LABEL;
    case ContainerStrategies.AutoLayout:
      return DEFAULT_OVERRIDES_FOR_AUTO_LAYOUT;
    default: {
      return {};
    }
  }
}
