export const DAY_FORMAT = "d|dd|ddd|dddd|D|DD|DDD|DDDD";
export const MONTH_FORMAT = "m|mm|mmm|mmmm|M|MM|MMM|MMMM";
export const YEAR_FORMAT = "yy|yyyy|YY|YYYY";
export const HOUR_FORMAT = "h|hh|H|HH";
export const MINUTE_FORMAT = "^(n|nn|N|NN)$";
export const SECOND_FORMAT = "s|ss";
export const MILISECOND_FORMAT = "S|SS|SSS";
export const AM_PM_FORMAT = "a|A";
export const DATE_FORMAT = new RegExp(
  "^(" +
    DAY_FORMAT +
    "|" +
    MONTH_FORMAT +
    "|" +
    YEAR_FORMAT +
    "|" +
    HOUR_FORMAT +
    "|" +
    MINUTE_FORMAT +
    "|" +
    SECOND_FORMAT +
    "|" +
    MILISECOND_FORMAT +
    "|" +
    AM_PM_FORMAT +
    ")$"
);
export const SEPARATORS = new RegExp(/(\.| |\-|:|\/)/);
export const DAY_OF_WEEK_FORMAT = `^(ddd|dddd|DDD|DDDD)$`;
export const MINUTE_WITHOUT_LEADING_ZERO_FORMAT = "^(n|N)$";
export const MOMENT_AM_PM_FORMAT = "A";
export const MOMENT_MINUTE_WITHOUT_LEADING_ZERO_FORMAT = "m";
export const MOMENT_MINUTE_WITH_LEADING_ZERO_FORMAT = "mm";
// NOTE: Following formats must be changed together.
export const DEFAULT_DATE_FORMAT = "lll";
export const EXPANDED_DEFAULT_DATE_FORMAT_WITH_SECONDS = "MMM D, YYYY h:mm:ss A";
export const MOMENT_SECOND_TOKEN = "s";
export const TIME_AM_PM_FORMAT = "h:mm:ss A";
const TIME_12_HOUR_FORMAT = `^((1[0-2]|0[1-9])\\:([0-5][0-9])(\\:([0-5][0-9]))?\\s([AaPp][Mm]))$`;
const TIME_24_HOUR_FORMAT = `^([0|1][0-9]|2[0-3])\\:([0-5][0-9])(\\:([0-5][0-9]))?$`;
export const TIME_FORMAT_REGEX = new RegExp(`${TIME_12_HOUR_FORMAT}|${TIME_24_HOUR_FORMAT}`);
export const SHORT_YEAR_FORMAT = "yy";
export const LONG_YEAR_FORMAT = "yyyy";
