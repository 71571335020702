export const RELEASE_NOTES = 'assets/ui-core/documentation/release-notes/current/release-notes.html';
export const RELEASE_NOTES__DASHBOARD_RELEASE_33 = 'assets/ui-core/documentation/release-notes/current/release-notes.html#dashboard-release-33';
export const RELEASE_NOTES__NEW_FEATURES_33 = 'assets/ui-core/documentation/release-notes/current/release-notes.html#new-features-33';
export const RELEASE_NOTES__DASHBOARD_RELEASE_32_2024_05_31 = 'assets/ui-core/documentation/release-notes/current/release-notes.html#dashboard-release-32-2024-05-31';
export const RELEASE_NOTES__NEW_FEATURES_32 = 'assets/ui-core/documentation/release-notes/current/release-notes.html#new-features-32';
export const RELEASE_NOTES__BREAKING_CHANGES = 'assets/ui-core/documentation/release-notes/current/release-notes.html#breaking-changes';
export const RELEASE_NOTES__NUMBER_FORMATTING_CHANGED = 'assets/ui-core/documentation/release-notes/current/release-notes.html#number-formatting-changed';
export const RELEASE_NOTES__IMPROVED_FILTER_CONFIGURATION = 'assets/ui-core/documentation/release-notes/current/release-notes.html#improved-filter-configuration';
export const RELEASE_NOTES__KM3A_REDESIGNED_OUT_OF_THE_BOX_DASHBOARDS = 'assets/ui-core/documentation/release-notes/current/release-notes.html#km3a-redesigned-out-of-the-box-dashboards';
export const RELEASE_NOTES__IN_WIDGET_EDITING = 'assets/ui-core/documentation/release-notes/current/release-notes.html#in-widget-editing';
export const RELEASE_NOTES__PLOTBANDS_AND_REFERENCE_LINES = 'assets/ui-core/documentation/release-notes/current/release-notes.html#plotbands-and-reference-lines';
export const RELEASE_NOTES__CROSS_WIDGET_EVENTING = 'assets/ui-core/documentation/release-notes/current/release-notes.html#cross-widget-eventing';
export const RELEASE_NOTES__TREND_LINE = 'assets/ui-core/documentation/release-notes/current/release-notes.html#trend-line';
export const RELEASE_NOTES__TARGET_AREA_IN_SCATTER_CHART = 'assets/ui-core/documentation/release-notes/current/release-notes.html#target-area-in-scatter-chart';
export const RELEASE_NOTES__PREVIOUS_VERSIONS = 'assets/ui-core/documentation/release-notes/current/release-notes.html#previous-versions';
export const RELEASE_NOTES_1 = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html';
export const RELEASE_NOTES__RELEASE_31_2023_10_31 = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#release-31-2023-10-31';
export const RELEASE_NOTES__NEW_FEATURES_31 = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#new-features-31';
export const RELEASE_NOTES__KNOWN_ISSUES = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#known-issues';
export const RELEASE_NOTES__WIDGET_SIZE = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#widget-size';
export const RELEASE_NOTES__MULTIPLE_Y_AXES = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#multiple-y-axes';
export const RELEASE_NOTES__MULTILINE_LABEL = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#multiline-label';
export const RELEASE_NOTES__PROPERTY_INTERPOLATION = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#property-interpolation';
export const RELEASE_NOTES__SHOWING_VALUES_IN_BAR_CHARTS = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#showing-values-in-bar-charts';
export const RELEASE_NOTES__UNDO2FREDO = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#undo2fredo';
export const RELEASE_NOTES__KEYBOARD_SHORTCUTS = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#keyboard-shortcuts';
export const RELEASE_NOTES__LIGHT_AND_DARK_THEME = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#light-and-dark-theme';
export const RELEASE_NOTES__ALIGNING_AND_DISTRIBUTING_WIDGETS = 'assets/ui-core/documentation/release-notes/v3.1/release-notes.html#aligning-and-distributing-widgets';
export const COMMON_USECASES_TODO = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases-todo.html';
export const COMMON_USECASES_TODO__TIME_USAGE_MODEL = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases-todo.html#time-usage-model';
export const COMMON_USECASES_TODO__GOAL = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases-todo.html#goal';
export const COMMON_USECASES_TODO__TOP_5_FAILURE_REASONS = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases-todo.html#top-5-failure-reasons';
export const COMMON_USECASES_TODO__GOAL_1 = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases-todo.html#goal-1';
export const COMMON_USECASES_TODO__KPI_TABLE = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases-todo.html#kpi-table';
export const COMMON_USECASES_TODO__GOAL_2 = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases-todo.html#goal-2';
export const COMMON_USECASES = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html';
export const COMMON_USECASES__DASHBOARD_SAMPLES = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#dashboard-samples';
export const COMMON_USECASES__SHOW_A_KPI_OVER_THE_LAST_7_DAYS = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#show-a-kpi-over-the-last-7-days';
export const COMMON_USECASES__GOAL = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#goal';
export const COMMON_USECASES__CONFIGURATION = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#configuration';
export const COMMON_USECASES__SHOW_A_KPI_OF_THE_CURRENT_DAY_FOR_INDIVIDUAL_EQUIPMENTS = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#show-a-kpi-of-the-current-day-for-individual-equipments';
export const COMMON_USECASES__GOAL_1 = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#goal-1';
export const COMMON_USECASES__CONFIGURATION_1 = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#configuration-1';
export const COMMON_USECASES__ALTERNATIVE_WITH_EQUIPMENT_MODEL = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#alternative-with-equipment-model';
export const COMMON_USECASES__TODO_2F_EXTENSIONS = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#todo-2f-extensions';
export const COMMON_USECASES__MIMICS = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#mimics';
export const COMMON_USECASES__GOAL_2 = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#goal-2';
export const COMMON_USECASES__CONFIGURATION_2 = 'assets/ui-core/documentation/user-guide/common-usecases/common-usecases.html#configuration-2';
export const TRIVIAL_USECASSE = 'assets/ui-core/documentation/user-guide/common-usecases/trivial-usecasse.html';
export const TRIVIAL_USECASSE__VIEWING_A_REPORT_FOR_THE_PREVIOUS_WEEK = 'assets/ui-core/documentation/user-guide/common-usecases/trivial-usecasse.html#viewing-a-report-for-the-previous-week';
export const TRIVIAL_USECASSE__GOAL = 'assets/ui-core/documentation/user-guide/common-usecases/trivial-usecasse.html#goal';
export const TRIVIAL_USECASSE__ACTION = 'assets/ui-core/documentation/user-guide/common-usecases/trivial-usecasse.html#action';
export const TRIVIAL_USECASSE__PLOT_SIGNALS_OVER_TIME = 'assets/ui-core/documentation/user-guide/common-usecases/trivial-usecasse.html#plot-signals-over-time';
export const TRIVIAL_USECASSE__GOAL_1 = 'assets/ui-core/documentation/user-guide/common-usecases/trivial-usecasse.html#goal-1';
export const GETTING_STARTED = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html';
export const GETTING_STARTED__GETTING_STARTED_WITH_DASHBOARD = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html#getting-started-with-dashboard';
export const GETTING_STARTED__CREATE_YOUR_FIRST_DASHBOARD = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html#create-your-first-dashboard';
export const GETTING_STARTED__ADD_A_WIDGET = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html#add-a-widget';
export const GETTING_STARTED__CONNECTING_TO_THE_DATA = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html#connecting-to-the-data';
export const GETTING_STARTED__OTHER_DATA_SOURCES = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html#other-data-sources';
export const GETTING_STARTED__SETTING_FILTERS = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html#setting-filters';
export const GETTING_STARTED__CUSTOMIZING_THE_WIDGET = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html#customizing-the-widget';
export const GETTING_STARTED__WHERE_TO_GO_FROM_HERE = 'assets/ui-core/documentation/user-guide/getting-started/getting-started.html#where-to-go-from-here';
export const GLOSSARY = 'assets/ui-core/documentation/user-guide/glossary.html';
export const GLOSSARY__DASHBOARD_GLOSSARY = 'assets/ui-core/documentation/user-guide/glossary.html#dashboard-glossary';
export const GLOSSARY__REPORT = 'assets/ui-core/documentation/user-guide/glossary.html#report';
export const GLOSSARY__WIDGET = 'assets/ui-core/documentation/user-guide/glossary.html#widget';
export const GLOSSARY__DATA_CONNECTOR = 'assets/ui-core/documentation/user-guide/glossary.html#data-connector';
export const GLOSSARY__DATA_CONNECTOR_QUERY = 'assets/ui-core/documentation/user-guide/glossary.html#data-connector-query';
export const GLOSSARY__DATA_SOURCE = 'assets/ui-core/documentation/user-guide/glossary.html#data-source';
export const GLOSSARY__FILTER_AND_AGGREGATION = 'assets/ui-core/documentation/user-guide/glossary.html#filter-and-aggregation';
export const GLOSSARY__VIEW_AND_EDIT_MODE = 'assets/ui-core/documentation/user-guide/glossary.html#view-and-edit-mode';
export const GLOSSARY__ROOT_PATH = 'assets/ui-core/documentation/user-guide/glossary.html#root-path';
export const HELP_LANDING_PAGE = 'assets/ui-core/documentation/user-guide/help-landing-page.html';
export const HELP_LANDING_PAGE__DASHBOARD_HELP = 'assets/ui-core/documentation/user-guide/help-landing-page.html#dashboard-help';
export const HELP_LANDING_PAGE__WHAT_IS_ABB_DASHBOARD = 'assets/ui-core/documentation/user-guide/help-landing-page.html#what-is-abb-dashboard';
export const HELP_LANDING_PAGE__GETTING_STARTED = 'assets/ui-core/documentation/user-guide/help-landing-page.html#getting-started';
export const HELP_LANDING_PAGE__SAMPLE_GALLERY = 'assets/ui-core/documentation/user-guide/help-landing-page.html#sample-gallery';
export const HELP_LANDING_PAGE__WIDGETS = 'assets/ui-core/documentation/user-guide/help-landing-page.html#widgets';
export const HELP_LANDING_PAGE__IN_DEPTH = 'assets/ui-core/documentation/user-guide/help-landing-page.html#in-depth';
export const HELP_LANDING_PAGE__ADVANCED3A_EXTENDING_DASHBOARD = 'assets/ui-core/documentation/user-guide/help-landing-page.html#advanced3a-extending-dashboard';
export const HELP_LANDING_PAGE__GLOSSARY = 'assets/ui-core/documentation/user-guide/help-landing-page.html#glossary';
export const API_DATASOURCE = 'assets/ui-core/documentation/user-guide/in-depth/api-datasource/api-datasource.html';
export const API_DATASOURCE__API_DATASOURCE = 'assets/ui-core/documentation/user-guide/in-depth/api-datasource/api-datasource.html#api-datasource';
export const API_DATASOURCE__SERVER_IMPLEMENTATION = 'assets/ui-core/documentation/user-guide/in-depth/api-datasource/api-datasource.html#server-implementation';
export const API_DATASOURCE__DATACONNECTOR_PROPERTIES = 'assets/ui-core/documentation/user-guide/in-depth/api-datasource/api-datasource.html#dataconnector-properties';
export const API_DATASOURCE__DATAPOINT_PROPERTIES = 'assets/ui-core/documentation/user-guide/in-depth/api-datasource/api-datasource.html#datapoint-properties';
export const API_DATASOURCE__DATETIME_ISSUES = 'assets/ui-core/documentation/user-guide/in-depth/api-datasource/api-datasource.html#datetime-issues';
export const BASIC_CARD = 'assets/ui-core/documentation/user-guide/in-depth/basic-card/basic-card.html';
export const BASIC_CARD__BASIC_CARD = 'assets/ui-core/documentation/user-guide/in-depth/basic-card/basic-card.html#basic-card';
export const CLUSTER_CHART = 'assets/ui-core/documentation/user-guide/in-depth/cluster-chart/cluster-chart.html';
export const CLUSTER_CHART__CLUSTER_CHART = 'assets/ui-core/documentation/user-guide/in-depth/cluster-chart/cluster-chart.html#cluster-chart';
export const CLUSTER_CHART__CLUSTERED_MULTIPOINT_BAR_CHART = 'assets/ui-core/documentation/user-guide/in-depth/cluster-chart/cluster-chart.html#clustered-multipoint-bar-chart';
export const CONFIGURATION_VS_RUNTIME_FILTER = 'assets/ui-core/documentation/user-guide/in-depth/configuration-vs-runtime-filter/configuration-vs-runtime-filter.html';
export const CONFIGURATION_VS_RUNTIME_FILTER__FILTERS = 'assets/ui-core/documentation/user-guide/in-depth/configuration-vs-runtime-filter/configuration-vs-runtime-filter.html#filters';
export const CONFIGURATION_VS_RUNTIME_FILTER__GLOBAL_FILTER = 'assets/ui-core/documentation/user-guide/in-depth/configuration-vs-runtime-filter/configuration-vs-runtime-filter.html#global-filter';
export const CONFIGURATION_VS_RUNTIME_FILTER__CONFIGURATION_PROPERTIES = 'assets/ui-core/documentation/user-guide/in-depth/configuration-vs-runtime-filter/configuration-vs-runtime-filter.html#configuration-properties';
export const CONFIGURATION_VS_RUNTIME_FILTER__RUNTIME_PROPERTIES = 'assets/ui-core/documentation/user-guide/in-depth/configuration-vs-runtime-filter/configuration-vs-runtime-filter.html#runtime-properties';
export const CONFIGURATION_VS_RUNTIME_FILTER__OPEN_NEW_REPORT_WITH_RUNTIME_PARAMETERS = 'assets/ui-core/documentation/user-guide/in-depth/configuration-vs-runtime-filter/configuration-vs-runtime-filter.html#open-new-report-with-runtime-parameters';
export const CONFIGURATION_VS_RUNTIME_FILTER__COMPONENT_AND_CONNECTOR_SPECIFIC_FILTERS = 'assets/ui-core/documentation/user-guide/in-depth/configuration-vs-runtime-filter/configuration-vs-runtime-filter.html#component-and-connector-specific-filters';
export const CROSS_WIDGET_EVENTING = 'assets/ui-core/documentation/user-guide/in-depth/cross-widget-eventing/cross-widget-eventing.html';
export const CROSS_WIDGET_EVENTING__CROSS_WIDGET_EVENTING = 'assets/ui-core/documentation/user-guide/in-depth/cross-widget-eventing/cross-widget-eventing.html#cross-widget-eventing';
export const CUSTOM_SQL_QUERIES = 'assets/ui-core/documentation/user-guide/in-depth/custom-sql-queries/custom-sql-queries.html';
export const CUSTOM_SQL_QUERIES__CUSTOM_SQL_QUERIES = 'assets/ui-core/documentation/user-guide/in-depth/custom-sql-queries/custom-sql-queries.html#custom-sql-queries';
export const CUSTOM_SQL_QUERIES__1_WRITE_SQL = 'assets/ui-core/documentation/user-guide/in-depth/custom-sql-queries/custom-sql-queries.html#1-write-sql';
export const CUSTOM_SQL_QUERIES__CREATE_A_DASHBOARD_REPORT = 'assets/ui-core/documentation/user-guide/in-depth/custom-sql-queries/custom-sql-queries.html#create-a-dashboard-report';
export const CUSTOM_SQL_QUERIES__DECLARE_FILTER = 'assets/ui-core/documentation/user-guide/in-depth/custom-sql-queries/custom-sql-queries.html#declare-filter';
export const DATA_SOURCES_AND_QUERYING = 'assets/ui-core/documentation/user-guide/in-depth/data-sources-and-querying/data-sources-and-querying.html';
export const DATA_SOURCES_AND_QUERYING__WIDGET_DATASOURCE_DATACONNECTORQUERY2C_DYNAMIC_CONNECTORS = 'assets/ui-core/documentation/user-guide/in-depth/data-sources-and-querying/data-sources-and-querying.html#widget-datasource-dataconnectorquery2c-dynamic-connectors';
export const EQUIPMENT_MODEL = 'assets/ui-core/documentation/user-guide/in-depth/equipment-model/equipment-model.html';
export const EQUIPMENT_MODEL__EQUIPMENT_MODEL = 'assets/ui-core/documentation/user-guide/in-depth/equipment-model/equipment-model.html#equipment-model';
export const EQUIPMENT_MODEL__TEMPLATE_REPORT = 'assets/ui-core/documentation/user-guide/in-depth/equipment-model/equipment-model.html#template-report';
export const EQUIPMENT_MODEL__RUNTIME_ROOT_PATH = 'assets/ui-core/documentation/user-guide/in-depth/equipment-model/equipment-model.html#runtime-root-path';
export const EQUIPMENT_MODEL__NAVIGATING_WITH_ROOT_PATH = 'assets/ui-core/documentation/user-guide/in-depth/equipment-model/equipment-model.html#navigating-with-root-path';
export const EQUIPMENT_MODEL__ROOT_CLASS = 'assets/ui-core/documentation/user-guide/in-depth/equipment-model/equipment-model.html#root-class';
export const FILTERING_AND_AGGREGATION = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html';
export const FILTERING_AND_AGGREGATION__FILTERING_AND_AGGREGATION = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#filtering-and-aggregation';
export const FILTERING_AND_AGGREGATION__FILTER = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#filter';
export const FILTERING_AND_AGGREGATION__AGGREGATION = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#aggregation';
export const FILTERING_AND_AGGREGATION__TIME_AGGREGATION = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#time-aggregation';
export const FILTERING_AND_AGGREGATION__ATTRIBUTE_AGGREGATION = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#attribute-aggregation';
export const FILTERING_AND_AGGREGATION__CUT_OFF = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#cut-off';
export const FILTERING_AND_AGGREGATION__EXAMPLES_FOR_SINGLE_VALUE_WIDGETS = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#examples-for-single-value-widgets';
export const FILTERING_AND_AGGREGATION__SHOW_THE_LATEST_VALUE = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#show-the-latest-value';
export const FILTERING_AND_AGGREGATION__SHOW_THE_SUM_OF_THE_LAST_4_HOURS = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#show-the-sum-of-the-last-4-hours';
export const FILTERING_AND_AGGREGATION__CONFIGURATION_LEVELS = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#configuration-levels';
export const FILTERING_AND_AGGREGATION__OVERRIDING_TIME_FILTERS = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#overriding-time-filters';
export const FILTERING_AND_AGGREGATION__EXAMPLES = 'assets/ui-core/documentation/user-guide/in-depth/filtering-and-aggregation/filtering-and-aggregation.html#examples';
export const FILTERING = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/filtering.html';
export const FILTERING__FILTERING_FOR_GROUPED_AND_TABULAR_DATASOURCE = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/filtering.html#filtering-for-grouped-and-tabular-datasource';
export const FILTERING__TIME_FILTER = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/filtering.html#time-filter';
export const FILTERING__SIMPLE_FILTER = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/filtering.html#simple-filter';
export const FILTERING__COMPLEX_FILTER = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/filtering.html#complex-filter';
export const FILTERING__GET_ALL_RECORDS_WITH_22PROPERTY3D022_AND_22EQUIPMENTCLASS3DSCOOPTRAM22 = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/filtering.html#get-all-records-with-22property3d022-and-22equipmentclass3dscooptram22';
export const FILTERING__GET_ALL_RECORDS_WITH_EQUIPMENTCLASS_BOOMER_AND_PROPERTY_8_OR_9 = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/filtering.html#get-all-records-with-equipmentclass-boomer-and-property-8-or-9';
export const GROUPED_AND_TABULAR_DATASOURCE = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/grouped-and-tabular-datasource.html';
export const GROUPED_AND_TABULAR_DATASOURCE__GROUPED_DATASOURCE = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/grouped-and-tabular-datasource.html#grouped-datasource';
export const GROUPED_AND_TABULAR_DATASOURCE__EXAMPLE = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/grouped-and-tabular-datasource.html#example';
export const GROUPED_AND_TABULAR_DATASOURCE__CONFIGURING_THE_GROUPED_DATASOURCE = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/grouped-and-tabular-datasource.html#configuring-the-grouped-datasource';
export const GROUPED_AND_TABULAR_DATASOURCE__HOW_GROUPING_IS_PERFORMED = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/grouped-and-tabular-datasource.html#how-grouping-is-performed';
export const GROUPED_AND_TABULAR_DATASOURCE__TWO_DIMENSIONAL_GROUPING = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/grouped-and-tabular-datasource.html#two-dimensional-grouping';
export const GROUPED_AND_TABULAR_DATASOURCE__TABULAR_DATA_SOURCE = 'assets/ui-core/documentation/user-guide/in-depth/grouped-and-tabular-datasource/grouped-and-tabular-datasource.html#tabular-data-source';
export const HEATMAP_CHART = 'assets/ui-core/documentation/user-guide/in-depth/heatmap-chart/heatmap-chart.html';
export const HEATMAP_CHART__HEATMAP_CHART = 'assets/ui-core/documentation/user-guide/in-depth/heatmap-chart/heatmap-chart.html#heatmap-chart';
export const HISTORY_VIEW = 'assets/ui-core/documentation/user-guide/in-depth/history-view/history-view.html';
export const HISTORY_VIEW__HISTORY_VIEW = 'assets/ui-core/documentation/user-guide/in-depth/history-view/history-view.html#history-view';
export const METADATA = 'assets/ui-core/documentation/user-guide/in-depth/metadata/metadata.html';
export const METADATA__METADATA = 'assets/ui-core/documentation/user-guide/in-depth/metadata/metadata.html#metadata';
export const METADATA__LIMITS = 'assets/ui-core/documentation/user-guide/in-depth/metadata/metadata.html#limits';
export const METADATA__TARGET_AND_ROLES = 'assets/ui-core/documentation/user-guide/in-depth/metadata/metadata.html#target-and-roles';
export const METADATA__EXAMPLE_1 = 'assets/ui-core/documentation/user-guide/in-depth/metadata/metadata.html#example-1';
export const METADATA__MIN_AND_MAX = 'assets/ui-core/documentation/user-guide/in-depth/metadata/metadata.html#min-and-max';
export const NAVIGATION_BAR = 'assets/ui-core/documentation/user-guide/in-depth/navigation-bar/navigation-bar.html';
export const NAVIGATION_BAR__NAVIGATION_BAR = 'assets/ui-core/documentation/user-guide/in-depth/navigation-bar/navigation-bar.html#navigation-bar';
export const NAVIGATION_BAR__EXAMPLE = 'assets/ui-core/documentation/user-guide/in-depth/navigation-bar/navigation-bar.html#example';
export const PROPERTY_INTERPOLATION = 'assets/ui-core/documentation/user-guide/in-depth/property-interpolation.html';
export const PROPERTY_INTERPOLATION__PROPERTY_INTERPOLATION = 'assets/ui-core/documentation/user-guide/in-depth/property-interpolation.html#property-interpolation';
export const PROPERTY_INTERPOLATION__USAGE = 'assets/ui-core/documentation/user-guide/in-depth/property-interpolation.html#usage';
export const PROPERTY_INTERPOLATION__SOURCE = 'assets/ui-core/documentation/user-guide/in-depth/property-interpolation.html#source';
export const PROPERTY_INTERPOLATION__AVAILABLE_PROPERTIES___WIDGET_LEVEL = 'assets/ui-core/documentation/user-guide/in-depth/property-interpolation.html#available-properties---widget-level';
export const PROPERTY_INTERPOLATION__AVAILABLE_PROPERTIES___CONNECTOR_LEVEL = 'assets/ui-core/documentation/user-guide/in-depth/property-interpolation.html#available-properties---connector-level';
export const PROPERTY_INTERPOLATION__AVAILABLE_PROPERTIES___FILTER_CONFIGURATION = 'assets/ui-core/documentation/user-guide/in-depth/property-interpolation.html#available-properties---filter-configuration';
export const PROPERTY_INTERPOLATION__AVAILABLE_PROPERTIES___DATA_POINT_LEVEL = 'assets/ui-core/documentation/user-guide/in-depth/property-interpolation.html#available-properties---data-point-level';
export const SCATTER_CHART = 'assets/ui-core/documentation/user-guide/in-depth/scatter-chart/scatter-chart.html';
export const SCATTER_CHART__SCATTER_CHART = 'assets/ui-core/documentation/user-guide/in-depth/scatter-chart/scatter-chart.html#scatter-chart';
export const SCATTER_CHART__EXAMPLE = 'assets/ui-core/documentation/user-guide/in-depth/scatter-chart/scatter-chart.html#example';
export const SCATTER_CHART__MULTIPLE_SERIES = 'assets/ui-core/documentation/user-guide/in-depth/scatter-chart/scatter-chart.html#multiple-series';
export const SCATTER_CHART__SHAPES = 'assets/ui-core/documentation/user-guide/in-depth/scatter-chart/scatter-chart.html#shapes';
export const SPIDER_CHART = 'assets/ui-core/documentation/user-guide/in-depth/spider-chart/spider-chart.html';
export const SPIDER_CHART__SPIDER_CHART = 'assets/ui-core/documentation/user-guide/in-depth/spider-chart/spider-chart.html#spider-chart';
export const SPIDER_CHART__EXAMPLE = 'assets/ui-core/documentation/user-guide/in-depth/spider-chart/spider-chart.html#example';
export const SPIDER_CHART__MULTIPLE_SERIES = 'assets/ui-core/documentation/user-guide/in-depth/spider-chart/spider-chart.html#multiple-series';
export const STATUS_INDICATOR = 'assets/ui-core/documentation/user-guide/in-depth/status-indicator/status-indicator.html';
export const STATUS_INDICATOR__STATUS_INDICATOR = 'assets/ui-core/documentation/user-guide/in-depth/status-indicator/status-indicator.html#status-indicator';
export const STATUS_INDICATOR__EXAMPLE = 'assets/ui-core/documentation/user-guide/in-depth/status-indicator/status-indicator.html#example';
export const TREND_LIKE_WIDGETS = 'assets/ui-core/documentation/user-guide/in-depth/Trend-like-widgets/trend-like-widgets.html';
export const TREND_LIKE_WIDGETS__TREND_WIDGETS = 'assets/ui-core/documentation/user-guide/in-depth/Trend-like-widgets/trend-like-widgets.html#trend-widgets';
export const TREND_LIKE_WIDGETS__MULTIPLE_Y_AXES = 'assets/ui-core/documentation/user-guide/in-depth/Trend-like-widgets/trend-like-widgets.html#multiple-y-axes';
export const TREND_LIKE_WIDGETS__EXAMPLE = 'assets/ui-core/documentation/user-guide/in-depth/Trend-like-widgets/trend-like-widgets.html#example';
export const TREND_LIKE_WIDGETS__SPIDER_CHART3A = 'assets/ui-core/documentation/user-guide/in-depth/Trend-like-widgets/trend-like-widgets.html#spider-chart3a';
export const TREND_LIKE_WIDGETS__PLOT_BANDS = 'assets/ui-core/documentation/user-guide/in-depth/Trend-like-widgets/trend-like-widgets.html#plot-bands';
export const TREND_LIKE_WIDGETS__REFERENCE_LINES = 'assets/ui-core/documentation/user-guide/in-depth/Trend-like-widgets/trend-like-widgets.html#reference-lines';
export const TREND_LIKE_WIDGETS__FIXED_REFERENCE_LINE = 'assets/ui-core/documentation/user-guide/in-depth/Trend-like-widgets/trend-like-widgets.html#fixed-reference-line';
export const VALUE_DATASOURCE = 'assets/ui-core/documentation/user-guide/in-depth/value-datasource/value-datasource.html';
export const VALUE_DATASOURCE__VALUE_DATASOURCE = 'assets/ui-core/documentation/user-guide/in-depth/value-datasource/value-datasource.html#value-datasource';
export const VALUE_DATASOURCE__EXAMPLE = 'assets/ui-core/documentation/user-guide/in-depth/value-datasource/value-datasource.html#example';
export const OVERVIEW = 'assets/ui-core/documentation/user-guide/overview/overview.html';
export const OVERVIEW__DASHBOARD_OVERVIEW = 'assets/ui-core/documentation/user-guide/overview/overview.html#dashboard-overview';
export const OVERVIEW__KPI_OVERVIEW = 'assets/ui-core/documentation/user-guide/overview/overview.html#kpi-overview';
export const OVERVIEW__MIMICS = 'assets/ui-core/documentation/user-guide/overview/overview.html#mimics';
export const OVERVIEW__DATA_ANALYSIS = 'assets/ui-core/documentation/user-guide/overview/overview.html#data-analysis';
export const OVERVIEW__LIMITATIONS = 'assets/ui-core/documentation/user-guide/overview/overview.html#limitations';
export const OVERVIEW_1 = 'assets/ui-core/documentation/user-guide/overview.html';
export const OVERVIEW__REPORT = 'assets/ui-core/documentation/user-guide/overview.html#report';
export const OVERVIEW__EDITING_THE_DASHBOARD = 'assets/ui-core/documentation/user-guide/overview.html#editing-the-dashboard';
export const OVERVIEW__WIDGET_GROUPS = 'assets/ui-core/documentation/user-guide/overview.html#widget-groups';
export const WIDGET_LIST = 'assets/ui-core/documentation/user-guide/widget-overview/widget-list.html';
export const WIDGET_LIST__WIDGET_LIST = 'assets/ui-core/documentation/user-guide/widget-overview/widget-list.html#widget-list';
export const WIDGET_PROPERTIES = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html';
export const WIDGET_PROPERTIES__COMMON_WIDGET_DISPLAY_PROPERTIES = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#common-widget-display-properties';
export const WIDGET_PROPERTIES__DISPLAY = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#display';
export const WIDGET_PROPERTIES__TITLE = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#title';
export const WIDGET_PROPERTIES__LONG_TITLE_DISPLAY = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#long-title-display';
export const WIDGET_PROPERTIES__UNIT = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#unit';
export const WIDGET_PROPERTIES__DISPLAY_FORMAT = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#display-format';
export const WIDGET_PROPERTIES__EXAMPLES = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#examples';
export const WIDGET_PROPERTIES__AXIS_TITLE = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#axis-title';
export const WIDGET_PROPERTIES__MIN_MAX = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#min-max';
export const WIDGET_PROPERTIES__LEGEND = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#legend';
export const WIDGET_PROPERTIES__STACK = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#stack';
export const WIDGET_PROPERTIES__TREND_LINE = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#trend-line';
export const WIDGET_PROPERTIES__BACKGROUND_IMAGE_AND_COLOR = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#background-image-and-color';
export const WIDGET_PROPERTIES__POSITION_AND_SIZE = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#position-and-size';
export const WIDGET_PROPERTIES__STYLE = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#style';
export const WIDGET_PROPERTIES__TARGET2C_LIMITS = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#target2c-limits';
export const WIDGET_PROPERTIES__COMMON_DATACONNECTOR_DISPLAY_PROPERTIES = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#common-dataconnector-display-properties';
export const WIDGET_PROPERTIES__TITLE_1 = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#title-1';
export const WIDGET_PROPERTIES__COLOR = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#color';
export const WIDGET_PROPERTIES__DATA_AND_INTERACTION_PROPERTIES = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#data-and-interaction-properties';
export const WIDGET_PROPERTIES__DATA_PROPERTIES = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#data-properties';
export const WIDGET_PROPERTIES__INTERACTION_PROPERTIES = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#interaction-properties';
export const WIDGET_PROPERTIES__NAVIGATION_LINK = 'assets/ui-core/documentation/user-guide/widget-overview/widget-properties.html#navigation-link';
