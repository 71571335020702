<div class="equipment-data-explorer__container">
  <div class="equipment-data-explorer__class">
    <span>{{ localizer.generalSettings.RootClass | translate }}</span>
    <div class="equipment-data-explorer__class-selector">
      <selector
        class="equipment-data-explorer__selector"
        [items]="equipmentClasses"
        [selectedItem]="rootClass"
        (onChangeValue)="rootClassChanged$.next($event)"
        (keydown)="openHelp($event)"
      ></selector>
    </div>
  </div>
  <div class="root-path-button" (click)="editRootPath()">
    <input
      type="button"
      class="root-path-button__text"
      [value]="getRootPathButtonValue()"
    />
  </div>
  <equipment-browser
    *ngIf="!isRootPathInvalid"
    [rootPath]="rootPath"
    (selectedEquipmentChanged)="selectedEquipmentChanged($event)"
    (collapseSidebar)="collapseSidebarOnDragStart()"
    (expandSidebar)="expandSidebarOnDragEnd()"
    [showTitle]="false"
    [openFullTree]="true"
  ></equipment-browser>
</div>
