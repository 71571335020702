import { Injectable } from "@angular/core";
import { DisplayFormatDto } from "../../elements/models/display-format";
import { DateFormatterService } from "../../environment/services/date-formatter.service";
import { ValidationContext } from "../../meta/models/validation-context";
import { isDate, isDefined, isNumber, isString, isWhiteSpace, Maybe } from "../../ts-utils";
import {
  formatNumberByFormatString,
  formatNumberDefault,
  isValidNumberFormatString
} from "./number-formatter";

const HTML_REGEX = new RegExp(/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/);

@Injectable({
  providedIn: "root"
})
export class ValueFormatterService {
  constructor(protected dateFormatter: DateFormatterService) {}

  public formatValue(
    value: number | Date | string,
    displayFormat: Maybe<DisplayFormatDto>
  ): string {
    if (isNumber(value)) {
      return formatNumberByStringFormat(Number(value), displayFormat?.numberFormat);
    } else if (isDate(value) || this.canBeFormattedAsDate(value, displayFormat?.dateFormat)) {
      return this.dateFormatter.formatDate(value, displayFormat?.dateFormat);
    } else {
      return value;
    }
  }

  private canBeFormattedAsDate(value: string, dateFormat?: string): boolean {
    return !this.dateFormatter.isDateInvalid(value, dateFormat) && isNaN(Number(value));
  }
}

export function formatNumberByStringFormat(value: number, format: Maybe<string>): string {
  if (isDefined(format) && !isWhiteSpace(format)) {
    return formatNumberByFormatString(value, format);
  }
  const res = formatNumberDefault(value);
  return res;
}

export function validateNumberFormat(
  format: unknown,
  _validationContext?: ValidationContext
): boolean {
  if (isString(format)) {
    return isValidNumberFormatString(format);
  }
  return false;
}

export function isValueHTML(value: string): boolean {
  return HTML_REGEX.test(value);
}
